import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { AppPage } from 'app/shared/components';
import * as accessRequestsService from '../shared/services/access-requests/access-requests';

import useStyles from './create-access-style';
import { useTranslation } from 'react-i18next';
import { fetchUsersByKey } from '../shared/services/hunting-area/hunting-area';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useOrganizations from 'app/shared/hooks/useOrganizations';
import useHuntingAreas from 'app/shared/hooks/useHuntingAreas';
import huntingArea from '../shared/state/hunting-area';

const CreateAccess = props => {
  /**
   * Handle the form value change
   */
  const { t } = useTranslation();
  const onChange = ({ name, value }) => {
    formData.value = {
      ...formData.value,
      [name]: value
    };
    setFormData({ ...formData });
  };

  const onAreaChange = ({ name, id }) => {
    let value = huntingAreas.find(o => o.id == id)
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value
      }
    };
    setFormData(updatedFormData);
  };

  const onRoleChange = ({ name, id }) => {
    let value = huntingRoles.find(o => o.id == id)
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value
      }
    };
    setFormData(updatedFormData);
  };

  /**
   * Submit the data for the create request
   */
  const submitData = async () => {
    setLoading(true);
    const { response, error } = await accessRequestsService
      .createAccess({ ...formData.value, requestId: accessRequest.id })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
    if (response) {
      setSnackBar(true);
      resetForm();
      if (isRequestApproval) {
        onBack();
      }
    }

    setLoading(false);
    if (error) {
      console.log(error);
    }
  };

  const inputLabel = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const inputLabel3 = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [labelWidth2, setLabelWidth2] = React.useState(0);
  const [labelWidth3, setLabelWidth3] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    setLabelWidth2(inputLabel2.current.offsetWidth);
    setLabelWidth3(inputLabel3.current.offsetWidth);
  }, []);

  const fetchAccessRequestData = async () => {
    const { location, match } = props;
    let data = location.state;
    if (!data) {
      const { response } = await accessRequestsService.fetchAccessRequestsById(
        match.params.id
      );
      data = response;
    }
    handleAccessRequestData(data);
  };

  const handleAccessRequestData = data => {
    if (data) {
      setAccessRequest(data);
      setFormData({
        ...formData,
        value: {
          ...formData.value,
          hunterEmailId: data.user.email,
          orgUnit: data.organisationUnitId,
          forestDistrictCode: data.forestDistrictCode,
        }
      });
    }
  };

  const getHuntingRoles = async () => {
    const { response } = await accessRequestsService.fetchAccessRequestRoles();
    if (response) {
      setHuntingRoles(response);
    }
  };

  function resetForm() {

    setFormData({
      value: {
        hunterEmailId: null,
        organisationId: null,
        huntingAreaId: null,
        accessRole: null,
        accessStartDate: new Date(),
        accessEndDate: new Date()
      },
      errors: null
    });

    setIsNewUser(false);
    setInValidEmailId(false);
  }

  const onBack = () => {
    props.history.push('/hunting-area/area-access-requests');
  };

  const classes = useStyles();
  const [formData, setFormData] = useState({
    value: {
      hunterEmailId: '',
      huntingAreaId: null,
      accessRole: null,
      accessStartDate: new Date(),
      accessEndDate: new Date()
    },
    errors: null
  });

  const { organizations } = useOrganizations();
  const [huntingRoles, setHuntingRoles] = useState([]);
  const [accessRequest, setAccessRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [snackBarOpen, setSnackBar] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState(null);
  const [emailData, setEmailData] = useState([]);
  const [inValidEmailId, setInValidEmailId] = useState(false);

  const { specialistAreas: huntingAreas } = useHuntingAreas('id,organisationUnit,forestDistrict,specialist,name,isHunter');
  useEffect(() => setLoading(!huntingAreas), [huntingAreas]);

  const filteredOrganizations = organizations && huntingAreas && organizations
    .filter(o => !!huntingAreas.find(h => parseInt(h.organisationUnit) == o.code))
    .map(o => ({
      ...o, forestDistricts: o.forestDistricts.filter(f => accessRequest.forestDistrict === f.code
        || !!huntingAreas.find(h => parseInt(h.forestDistrict) === f.code && parseInt(h.organisationUnit) === o.code))
    }));

  const forestDistricts = filteredOrganizations && filteredOrganizations.map(o => o.forestDistricts.map(f => ({ ...f, orgUnit: o.code }))).flat();
  const filteredHuntingAreas = huntingAreas && huntingAreas.filter(h =>
    (!formData.value.forestDistrictCode || parseInt(h.forestDistrict) === formData.value.forestDistrictCode) &&
    (!formData.value.orgUnit || parseInt(h.organisationUnit) === formData.value.orgUnit));

  /** Check if this is screen is opened from the requests */
  const isRequestApproval = !!props.match.params.id;

  const isFormValid = () => {
    const {
      hunterEmailId,
      huntingAreaId,
      accessRole,
      accessStartDate,
      accessEndDate
    } = formData.value;

    return (
      hunterEmailId &&
      huntingAreaId &&
      accessRole &&
      accessStartDate &&
      accessEndDate &&
      !inValidEmailId
    );
  };

  useEffect(() => {
    if (isRequestApproval) {
      fetchAccessRequestData();
    }
    getHuntingRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestApproval]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  };

  const defaultProps = {
    getOptionLabel: option => option.name
  };

  const onChangeUserId = async (data) => {

    if (data.value && data.value.trim().length == 1) {

      setLoadingUsers(true);
      const { response } = await fetchUsersByKey(data.value);

      if (response) {
        setUserIds(response);
        setEmailData(response);
      }

      setLoadingUsers(false);
    }
    else if (!data.value || data.value.length <= 0) {

      setUserIds([]);
      setEmailData([]);
    }
    else if (userIds && data.value && data.value.trim().length > 1) {
      let _emailData = userIds.filter((item) => {
        return item.trim().toLowerCase().includes(data.value.trim().toLowerCase())
      });
      setEmailData(_emailData);
    }

    onChange(data);
    checExistuser(data.value);
    setInValidEmailId(false);
  };

  const onBlurHunterId = (e) => {
    e.preventDefault();
    let _value = formData.value.hunterEmailId;

    if (_value && validateEmail(_value)) {
      checExistuser(_value);
    }
    else {
      setIsNewUser(false);
      _value && setInValidEmailId(true);
    }
  }

  const checExistuser = (value) => {
    let flag = false;

    if (userIds && userIds.length > 0 && value) {

      let userId = userIds.filter((item) => {
        return item.trim().toLowerCase() == value.toLowerCase()
      });

      if (userId && userId.length <= 0) {
        flag = true;
      }
    }
    setIsNewUser(flag);
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  return (
    <AppPage
      headerTitle={t('headerTitle.permissions')}
      headerLeft={
        isRequestApproval ? (
          <IconButton
            color='inherit'
            aria-label='back'
            onClick={onBack}
            edge='start'
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          false
        )
      }
      loading={loading}
    >
      <Box p={2} className="item-mobile-view" pt={4}>
        <Grid container>

          <Grid item xs={12} className={classes.formControl}>

            <Autocomplete
              id="auto-userid"
              freeSolo
              disableOpenOnFocus
              options={emailData}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event, newValue) => {
                onChangeUserId({ name: 'hunterEmailId', value: newValue })
              }}
              value={formData.value.hunterEmailId}
              searchText={formData.value.hunterEmailId}
              onBlur={onBlurHunterId}
              loading={loadingUsers && open}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('textBoxLabel.hunterEmail')}
                  variant='outlined'
                  fullWidth
                  required
                  type='email'
                  onChange={e =>
                    onChangeUserId({ name: 'hunterEmailId', value: e.target.value })
                  }
                  disabled={isRequestApproval}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingUsers && open ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            // renderOption={ (option, { inputValue }) => {
            //   const matches = match(option, inputValue);
            //   const parts = parse(option, matches);

            //   return (
            //     <div>
            //       { parts.map((part, index) => (
            //         <span key={ index } style={ { fontWeight: part.highlight ? 700 : 400 } }>
            //           { part.text }
            //         </span>
            //       )) }
            //     </div>
            //   );
            // } }
            />
            {isNewUser &&
              <Typography
                variant='body2'
                className={classes.error}
                component='p'
              >
                {t('huntingArea.newUserIdMessage')}
              </Typography>}
            {inValidEmailId &&
              <Typography
                variant='body2'
                className={classes.error}
                component='p'
              >
                {t('huntingArea.inValidEmailIdMessage')}
              </Typography>}

          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ marginTop: "8px" }} variant="outlined" fullWidth>
              <InputLabel ref={inputLabel3} htmlFor="outlined-fdist-native-simple">
                {t('textBoxLabel.forestDistrict')}
              </InputLabel>
              <Select
                native
                value={formData.value.forestDistrictCode != null ? `${formData.value.orgUnit}-${formData.value.forestDistrictCode}` : 'all'}
                onChange={event => {
                  const [orgUnit, forestDistrictCode] = event.target.value.split('-');
                  onChange({ name: 'orgUnit', value: parseInt(orgUnit) });
                  onChange({ name: 'forestDistrictCode', value: parseInt(forestDistrictCode) });
                }}

                labelWidth={labelWidth3}
                inputProps={{
                  name: "forestDistrictCode",
                  id: 'outlined-fdist-native-simple',
                }}
              >
                <option value='all'>{t('buttonText.all')}</option>
                {forestDistricts && forestDistricts.map((option, i) =>
                  <option key={`fdist-${option.orgUnit}-${option.code}`} value={`${option.orgUnit}-${option.code}`}>{`${option.name} (${option.orgUnit} - ${option.code < 10 ? '0' : ''}${option.code})`}</option>
                )}

              </Select>
            </FormControl>


          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ marginTop: "8px" }} variant="outlined" fullWidth>

              <InputLabel ref={inputLabel} htmlFor="outlined-hunt-native-simple">
                {t('textBoxLabel.huntingArea')}
              </InputLabel>
              <Select
                native
                value={formData.value.huntingAreaId && formData.value.huntingAreaId.id ? formData.value.huntingAreaId.id : ''}
                onChange={(event, newValue) => {
                  newValue = event.target.value;
                  if (newValue) {
                    newValue = parseInt(newValue);
                    onAreaChange({ name: 'huntingAreaId', id: newValue });
                  }

                }}

                labelWidth={labelWidth}
                inputProps={{
                  name: "huntingAreaName",
                  id: 'outlined-hunt-native-simple',
                }}
              >
                <option key="haId"></option>
                {filteredHuntingAreas && filteredHuntingAreas.map((option, i) =>
                  <option key={"haId" + i} value={option.id}>{`${option.name} (${option.organisationUnit} - ${option.forestDistrict})`}</option>
                )}

              </Select>
              {accessRequest.huntingAreaName && (
                <FormHelperText id='my-helper-text'>
                  {t('textBoxLabel.userHuntingArea')}{' '}
                  {accessRequest.huntingAreaName}
                </FormHelperText>
              )}
            </FormControl>


          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ marginTop: "16px" }} variant="outlined" fullWidth>

              <InputLabel ref={inputLabel2} htmlFor="outlined-role-native-simple">
                {t('textBoxLabel.role')}
              </InputLabel>
              <Select
                native
                value={formData.value.accessRole && formData.value.accessRole.id ? formData.value.accessRole.id : ''}
                onChange={(event, newValue) => {
                  newValue = event.target.value;
                  if (newValue) {
                    newValue = parseInt(newValue);
                    onRoleChange({ name: 'accessRole', id: newValue });
                  }

                }}

                labelWidth={labelWidth2}
                inputProps={{
                  name: "RoleName",
                  id: 'outlined-role-native-simple',
                }}
              >
                <option key="role2"></option>
                {huntingRoles.map((option, i) =>
                  <option key={"roleId" + i} value={option.id}>{option.name}</option>
                )}

              </Select>

            </FormControl>

          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              fullWidth
              inputVariant='outlined'
              margin='normal'
              id='date-picker-dialog'
              label={t('textBoxLabel.from')}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              okLabel={t("buttonText.ok")}
              cancelLabel={t("buttonText.cancel")}
              onChange={value => onChange({ name: 'accessStartDate', value })}
              value={formData.value.accessStartDate}
              maxDate={formData.value.accessEndDate}
              format='dd/MM/yyyy'
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <KeyboardDatePicker
              fullWidth
              inputVariant='outlined'
              margin='normal'
              id='date-picker-dialog'
              label={t('textBoxLabel.to')}
              format='dd/MM/yyyy'
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              okLabel={t("buttonText.ok")}
              cancelLabel={t("buttonText.cancel")}
              onChange={value => onChange({ name: 'accessEndDate', value })}
              value={formData.value.accessEndDate}
              minDate={formData.value.accessStartDate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.actionButtons}>
          <Grid item>
            <Button
              data-testid="submitDataBtn"
              onClick={submitData}
              size='large'
              variant='contained'
              color='primary'
              disabled={!isFormValid()}
            >
              {t('buttonText.submit')}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={resetForm} size='large' variant='outlined'>
              {t('buttonText.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={
          <span id='message-id'>{t('general.permissionsUpdated')}</span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleSnackBarClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </AppPage>
  );
};

export default CreateAccess;
