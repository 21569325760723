import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchAppPermissions } from '../../services/app-permissions/app-permissions';

import {
  requestAppPermissionsAction,
  receivedAppPermissionsAction,
  errorAppPermissionsAction,
  actionTypes
} from './actions';

function* getAppPermissions(params) {
  yield put(requestAppPermissionsAction());
  const { response, error } = yield call(fetchAppPermissions, params);
  if (response) {
    yield put(receivedAppPermissionsAction(response));
  } else {
    yield put(errorAppPermissionsAction(error));
  }
}

export default function* getAppPermissionsSaga() {
  yield takeEvery(actionTypes.FETCH_APP_PERMISSIONS_DATA, getAppPermissions);
}
