import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    weatherInfoItems: {
      display: 'flex'
    },
    weatherInfoItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    separator: {
      borderRight: 'solid 1px #d2d2d2',
      borderRadius: 'unset',
      height: 25
    }
  })
);

export default useStyles;
