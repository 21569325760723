import { combineReducers } from 'redux';
import { appPermissionsData } from './app-permissions';
import authDetails from './auth-details';
import offlineRequests from './offline-requests';
import formState from './form-state';

export default combineReducers({
  permissions: appPermissionsData,
  authDetails,
  offlineRequests,
  formState,
});
