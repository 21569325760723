import React, { useEffect, useRef, useState } from 'react';
import useHuntingArea from 'app/shared/hooks/useHuntingArea.js';
import { useTranslation } from 'react-i18next';
import useStyles from './reservations-list.style';
import AppPage from '../../shared/components/app-page/app-page';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router';
import { baseBlobUrl } from 'environement/environment';
import {
    Typography,
    IconButton,
} from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { fetchReservableTypes } from '../shared/state/markers';
import { useDispatch } from 'react-redux';
import useQuery, { useQuerySingle } from 'app/shared/hooks/useQuery';
import useOnline from 'app/shared/hooks/useOnline';
import { fetchReservations, requestCancelReservation, requestEditReservation, requestMakeReservation } from '../shared/state/markers';
import { toDateString, toTimeString, fromDateString } from './../../shared/dateMethods';
import {
    can,
    permissions as permissionsType
} from 'app/shared/services/app-permissions/app-permissions';
import { AppDialog } from 'app/shared/components/';
import ReservationDialog from './reservation-dialog';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import useUsername from 'app/shared/hooks/useUsername';
import { useValidFilterDefaults } from 'app/shared/hooks/useHuntingPlan';

const ReservationsList = props => {
    const { match, history } = props;
    const { t } = useTranslation();
    const [query] = useQuery();
    const classes = useStyles();
    const dispatch = useDispatch();
    const username = useUsername();
    const { validFrom, validTo } = useValidFilterDefaults();
    const { huntingArea, markersByKey: markers } = useHuntingArea(match.params.id);
    const huntingPlan = huntingArea && huntingArea.huntingPlans && huntingArea.huntingPlans[huntingArea.defaultHuntingPlan];
    const [expandedMarker, setExpandedMarker] = useQuerySingle('expanded');
    const [deleteAlert, setDeleteAlert] = useState({ open: false });
    const [reservationDialog, setReservationDialog] = useState({ isOpen: false });
    const marker = expandedMarker && markers.data[parseInt(expandedMarker)];
    const userId = huntingArea && huntingArea.userId;
    const permissions = huntingArea && huntingArea.permissions;
    const isOnline = useOnline();
    const canModifyOwnReservations = can(permissionsType.huntingArea.modifyOwnReservations, permissions);
    const canModifyAllReservations = can(permissionsType.huntingArea.modifyAllReservations, permissions);
    const expandedElementRef = useRef(null);
    const datedReservations = marker && marker.reservations && marker.reservations
        .map(r => ({ ...r, isOwn: r.userId === userId, timeFrom: new Date(Date.parse(r.timeFrom)), timeTo: new Date(Date.parse(r.timeTo)) }))
        .sort((a, b) => a.timeFrom - b.timeFrom);

    useEffect(() => {
        if (expandedMarker) {
            dispatch(fetchReservations({ huntingAreaId: parseInt(match.params.id), featureId: parseInt(expandedMarker) }));
        }
    }, [expandedMarker, match.params.id])

    useEffect(() => {
        if (expandedMarker) {
            setTimeout(() => {
                if (expandedElementRef && expandedElementRef.current) {
                    expandedElementRef.current.scrollIntoView(true);
                    window.scrollBy(0, -64);
                }
            }, 0);
        }
    }, [])

    useEffect(() => {
        dispatch(fetchReservableTypes());
    }, []);

    const onBack = () => {
        if (query.backTo === 'map') history.replace(`/hunting-area/area-map/${match.params.id}`);
        else history.replace('/hunting-area/overview');
    }

    const clickedReservation = id => {
        if (expandedMarker == id) setExpandedMarker(undefined);
        else setExpandedMarker(id);
    }

    const onCancelReservation = (featureId, id) => {
        dispatch(requestCancelReservation({ id, featureId }));
    }

    const openDialog = (featureId, editedId) => {
        const editedReservation = datedReservations.find(r => r.id === editedId);
        setReservationDialog({
            isOpen: true,
            minDate: new Date(validFrom[2], validFrom[1] - 1, validFrom[0]),
            maxDate: new Date(validTo[2], validTo[1] - 1, validTo[0]),
            featureId,
            reservations: datedReservations.filter(r => r.id !== editedId),
            editedId,
            lastTimeFrom: editedReservation && editedReservation.timeFrom,
            lastTimeTo: editedReservation && editedReservation.timeTo,
        });
    }

    const [snackBarOpen, setSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };

    const onCloseDialog = async reservation => {
        setReservationDialog({ isOpen: false });
        if (!reservation) return;
        const res = await (reservation.editedId
            ? dispatch(requestEditReservation({
                id: reservation.editedId,
                huntingAreaFeatureId: reservation.featureId,
                timeFrom: reservation.timeFrom,
                timeTo: reservation.timeTo,
            }))
            : dispatch(requestMakeReservation({
                huntingAreaFeatureId: reservation.featureId,
                timeFrom: reservation.timeFrom,
                timeTo: reservation.timeTo,
                userId,
            })));
        if (res.type.endsWith('rejected')) {
            setSnackBar(true);
            setSnackBarMessage(t("reservation.reservationUnsuccessful"));
        } else {
            setSnackBar(true);
            setSnackBarMessage(t("reservation.reservationSuccessful"));
        }
    }

    const dynamicMarkers = t('markerTypes', { returnObjects: true });
    const markerImageOptions = {};
    const baseMarkerUrl = baseBlobUrl + "$web/markers/";
    for (const item in dynamicMarkers) {
        markerImageOptions[item] = baseMarkerUrl + item + '.png';
    }

    return <AppPage className="item-mobile-view" headerTitle={t('reservation.reservations')}
        headerSubTitle={huntingArea ? huntingArea.name : ''}
        loading={false}
        headerLeft={
            <IconButton
                data-testid='iconbtn'
                color='inherit'
                aria-label='back'
                onClick={onBack}
                edge='start'
            >
                <ArrowBackIcon />
            </IconButton>
        }
    >
        <div className="item-mobile-view pad-left-null mrgin-tp-10">
            <TableContainer>
                <Table>
                    <TableBody>
                        {Object.values(markers.data).filter(m => m.reservable).sort((a, b) => a.name.localeCompare(b.name)).map(m =>
                            <React.Fragment>
                                <TableRow ref={(elem) => {
                                    if (expandedMarker == m.id) {
                                        expandedElementRef.current = elem;
                                    }
                                }} hover style={{ cursor: 'pointer' }} onClick={() => clickedReservation(m.id)}>
                                    <TableCell style={{ width: '40px', position: 'relative', borderBottom: m.id == expandedMarker ? 'none' : undefined }}>
                                        <CardMedia
                                            style={{ width: '40px', height: '40px', position: m.nextReservation ? 'absolute' : undefined }}
                                            image={markerImageOptions[m.subType]}
                                            title={t(`markerTypes.${m.subType}`)}
                                        />
                                        {m.nextReservation && <div className={classes.marker}>
                                            <div class="top"><div class="outline"></div></div>
                                        </div>}
                                    </TableCell>
                                    <TableCell style={{ borderBottom: m.id == expandedMarker ? 'none' : undefined }}>
                                        <Typography variant="h6" color="textSecondary">
                                            {m.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: m.id == expandedMarker ? 'none' : undefined }} align="right">
                                        {expandedMarker != m.id && <ExpandMoreIcon color='primary' />}
                                        {expandedMarker == m.id && <ExpandLessIcon color='primary' />}
                                    </TableCell>
                                </TableRow>
                                {expandedMarker == m.id && <TableRow>
                                    <TableCell colSpan="3">
                                        <IconButton color="primary" onClick={() => openDialog(m.id)}>
                                            <AddIcon fontSize="large"></AddIcon>
                                        </IconButton>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {datedReservations && datedReservations.map(r => <TableRow key={r.id}>
                                                        <TableCell style={{ borderBottom: 'none' }}>
                                                            <Typography variant="body2">{r.isOwn ? username : r.userName || '******'}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: 'none' }}>
                                                            <Typography variant="body2">{toDateString(r.timeFrom)} {`${toTimeString(r.timeFrom)}-${toTimeString(r.timeTo)}`}</Typography>
                                                        </TableCell>
                                                        <TableCell align="right" style={{ borderBottom: 'none', display: 'flex' }}>
                                                            {((canModifyOwnReservations && r.isOwn) || canModifyAllReservations) &&
                                                                <React.Fragment>
                                                                    <IconButton disabled={!isOnline} onClick={() => setDeleteAlert({ open: true, featureId: m.id, id: r.id })}>
                                                                        <DeleteIcon color="inherit" fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton disabled={!isOnline} onClick={() => openDialog(m.id, r.id)}>
                                                                        <EditIcon color="inherit" fontSize="small" />
                                                                    </IconButton>
                                                                </React.Fragment>
                                                            }
                                                        </TableCell>
                                                    </TableRow>)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TableCell>
                                </TableRow>}
                            </React.Fragment>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppDialog
                {...deleteAlert}
                text={t('reservation.cancelReservationConfirm')}
                ok={t("buttonText.yes")}
                cancel={t("buttonText.cancel")}
                handleClose={() => { setDeleteAlert({ open: false }) }}
                handleAlertOk={() => { onCancelReservation(deleteAlert.featureId, deleteAlert.id); setDeleteAlert({ open: false }) }}
            ></AppDialog>
            <ReservationDialog {...reservationDialog} onClose={onCloseDialog}></ReservationDialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={snackBarOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                message={
                    <span id='message-id'>{snackBarMessage}</span>
                }
                action={[
                    <IconButton
                        key='close'
                        aria-label='close'
                        color='inherit'
                        onClick={handleSnackBarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </div>
    </AppPage >
}

export default withRouter(ReservationsList);