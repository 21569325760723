import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useStyle from './user-info-style';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const UserDeleteDialog = props => {

    const styles = useStyle();
    const { t } = useTranslation();
    const [password, setPassword] = useState("");

    function onDelete() {
        if (password || props.isSocialAccount) {
            props.onDelete(password);
        }
    }

    function onCancel() {
        props.onCancel();
    }

    function onChange(e) {
        setPassword(e.target.value);
        props.setShowError(false);
    }

    return (
        <>
            <Dialog open={ props.openDialog } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{ t("profile.deleteAccountHeader") }</DialogTitle>
                <DialogContent style={ { padding: 0 } }>
                    <Box p={ 2 } pt={ 4 } style={ { paddingTop: "0px" } }>
                        <Grid container>
                            <Grid item xs={ 12 } className={ styles.formControl }>
                                <Typography variant="subtitle1" gutterBottom>
                                    { t("profile.accountDeleteWarning") }
                                </Typography>
                            </Grid>
                            {
                                !props.isSocialAccount &&
                                <Grid item xs={ 12 } className={ styles.formControl }>
                                    <Typography variant="subtitle1" gutterBottom>
                                        { t("profile.passwordConfirmMessage") }
                                    </Typography>
                                </Grid>
                            }

                            <Grid item xs={ 12 } className={ styles.formControl }>
                                { !props.isSocialAccount && <TextField
                                    onChange={ onChange }
                                    value={ password }
                                    type="password"
                                    label={ t("profile.password") }
                                    variant='outlined'
                                    fullWidth
                                />
                                }
                                { props.showError &&
                                    <Typography style={ { color: 'red' } } variant="subtitle1" gutterBottom>
                                        { t(props.errorMessageKey) }
                                    </Typography>
                                }
                            </Grid>

                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button data-testid="cancelbtn" onClick={ onCancel } size='large' variant='outlined'>
                        { t("markerActions.cancel") }
                    </Button>
                    <Button
                        onClick={ onDelete }
                        size='large'
                        variant='contained'
                        color='primary'
                        disabled={ !password && !props.isSocialAccount }
                        className={ styles.noWrap }
                    >
                        { t("profile.deleteAccount") }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserDeleteDialog;
