import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppPage } from 'app/shared/components';
import useStyles from '../hunt-statistics/hunting-plan-style';
import useHSStyles from '../hunt-statistics/hunt-statistics-style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { FilterIcon } from 'app/shared/components/';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import useHuntingArea from 'app/shared/hooks/useHuntingArea';
import FilterPopup, { recordFilterOptions } from '../area-map/filter-popup';
import CardMedia from '@material-ui/core/CardMedia';
import SyncIcon from '@material-ui/icons/Sync';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import SyncDisabled from '@material-ui/icons/SyncDisabled';
import PublicIcon from '@material-ui/icons/Public';
import { baseBlobUrl } from 'environement/environment';
import useQuery, { pickQueryOptions, additionalQuery } from './../../shared/hooks/useQuery';
import useRecordFilters from 'app/shared/hooks/useRecordFilters';
import { can, permissions } from '../../shared/services/app-permissions/app-permissions';

const ShootingList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hsClasses = useHSStyles();
  const { match, history } = props;
  const { params } = match;
  const [query, setQuery] = useQuery();
  const shootQueryBack = pickQueryOptions(query, recordFilterOptions);
  const { huntingArea, huntingRecords, huntingStatistics } = useHuntingArea(params.id);
  const filteredRecords = useRecordFilters(huntingRecords, huntingArea);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const canEdit = huntingArea && can(permissions.huntingArea.updateHuntRecord, huntingArea.permissions);
  const canModifyAll = huntingArea && can(permissions.huntingArea.modifyAllHuntRecords, huntingArea.permissions);
  const isHunterView = localStorage.getItem('isHunterView') === 'true';
  const iconPicUrl = baseBlobUrl + '$web/animal-images/';
  const sortBy = query.sortBy || 'huntingDate';
  const sortDir = query.sortDir || 'desc';
  const isOOE = huntingArea.county === 'OÖ';

  const onSortClick = (by) => {
    if (by == sortBy) {
      setQuery({ ...query, sortDir: sortDir == 'asc' ? 'desc' : 'asc' });
    } else {
      setQuery({ ...query, sortBy: by, sortDir: 'asc' });
    }
  };

  const records = filteredRecords.map((r) => ({
    id: r.id,
    hunterName: r.hunterName,
    animalTypeName: r.animalTypeName,
    animalFallen: r.animalFallen,
    icon: r.icon,
    animalClassName: r.animalClassName,
    huntingDate: r.huntingDate,
    count: r.animalCount,
    hasCoordinates: !!r.coordinates,
    isSynced: r.isSynced,
    isOwnRecord: r.userId === (huntingArea && huntingArea.userId),
  }));

  const onBack = () => {
    history.replace('/hunting-area/overview');
  };

  const showAll = () => {
    setQuery(
      recordFilterOptions.reduce((acc, k) => {
        acc[k] = undefined;
        return acc;
      }, {})
    );
  };

  const navigateToHuntRecordForm = (recordId) => {
    history.push(
      `/hunting-area/${params.id}/records/${recordId}?backTo=shooting-list${additionalQuery(shootQueryBack)}`
    );
  };

  const navigateToMap = (recordId) => {
    history.push(
      `/hunting-area/area-map/${params.id}?feature=${recordId}&backTo=shooting-list${additionalQuery(shootQueryBack)}`
    );
  };
  return (
    <AppPage
      className='item-mobile-view'
      headerTitle={t('headerTitle.shootingList')}
      headerSubTitle={huntingArea ? huntingArea.name : ''}
      loading={false}
      headerLeft={
        <IconButton data-testid='iconbtn' color='inherit' aria-label='back' onClick={onBack} edge='start'>
          <ArrowBackIcon />
        </IconButton>
      }
    >
      {records && (
        <div className='item-mobile-view filter-box'>
          <Grid
            container
            spacing={2}
            style={{ borderBottom: '2px solid #cdc6c6', marginBottom: '6px', paddingBottom: '6px' }}
            className={classes.actionButtons}
          >
            <div
              style={{
                width: '15%',
                margin: '3px',
              }}
            >
              <Button
                data-testid='submitbtn'
                onClick={showAll}
                size='small'
                variant='contained'
                color='primary'
                style={{
                  minWidth: '36%',
                  marginLeft: '12px',
                  height: '33px',
                  textTransform: 'none',
                  paddingTop: '6px',
                }}
                disabled={Object.keys(shootQueryBack).length === 0}
              >
                {t('buttonText.all')}
              </Button>
            </div>
            <div className='filter-icon-hp'>
              <Button
                fullWidth
                variant='text'
                color='primary'
                size='large'
                onClick={() => setOpenFilterPopup(true)}
                startIcon={<FilterIcon />}
                children=''
              ></Button>
            </div>
          </Grid>
        </div>
      )}
      <div className='item-mobile-view header-box'>
        <Grid item xs={12} style={{ padding: '6px 0' }} className={classes.header}>
          <Grid container style={{ alignItems: 'center' }}>
            <div className={hsClasses.media + ' shooting-list-whitespace'}></div>
            <Typography
              className={'record-item-big shooting-list-header-1'}
              variant='body2'
              onClick={() => onSortClick('huntingDate')}
              style={{ paddingLeft: '15px' }}
            >
              {t('huntRecord.huntDate')}
              {sortBy == 'huntingDate' && (sortDir == 'desc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
            </Typography>
            <Typography
              className='record-item-big shooting-list-header-2'
              variant='body2'
              onClick={() => onSortClick('animalType')}
            >
              {t('huntingPlan.animalType')}
              {sortBy == 'animalType' && (sortDir == 'desc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
            </Typography>
            <Typography className='record-item-big' variant='body2' onClick={() => onSortClick('animalClass')}>
              {t('huntingPlan.animalClass')}
              {sortBy == 'animalClass' && (sortDir == 'desc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className='item-mobile-view pad-left-null mrgin-tp-10'>
        {records.map((record) => (
          <div key={record.id} className={hsClasses.labelRoot}>
            <Grid
              item
              xs={12}
              style={{ borderBottom: '2px solid #dbdbdb', padding: '6px 0' }}
              className={hsClasses.recordStyle}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <CardMedia className={hsClasses.media} image={iconPicUrl + record.icon} title={record.icon} />
                <Typography className='record-item-big' variant='body2' style={{ paddingLeft: '15px' }}>
                  {record.huntingDate}
                </Typography>
                <Typography className='record-item-big' variant='body2'>
                  {record.animalTypeName}
                </Typography>
                <Typography className='record-item-big' variant='body2'>
                  {record.animalClassName}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: '120px',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    gap: '15px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                >
                  {record.hasCoordinates && (
                    <Typography
                      style={{
                        cursor: 'pointer',
                        marginBottom: '3px',
                        marginTop: '3px',
                        fontSize: '15px',
                        color: 'rgb(128, 128, 128, .8)',
                        height: 'fit-content',
                      }}
                      onClick={() => navigateToMap(record.id)}
                    >
                      <PublicIcon />
                    </Typography>
                  )}

                  <Typography
                    variant='caption'
                    className='record-item'
                    style={{ color: 'rgb(128, 128, 128)', height: 'fit-content' }}
                  >
                    {canModifyAll &&
                      (record.isSynced ? <SyncIcon fontSize='small' /> : <SyncDisabled fontSize='small' />)}
                  </Typography>

                  <Typography
                    style={{ cursor: 'pointer', color: 'rgb(128, 128, 128)', height: 'fit-content' }}
                    onClick={() =>
                      navigateToHuntRecordForm(record.id, (!record.isSynced && record.isOwnRecord) || !isHunterView)
                    }
                    className='record-item'
                    variant='caption'
                  >
                    {((!record.isSynced && record.isOwnRecord) || canModifyAll) && canEdit ? (
                      <EditIcon color='inherit' fontSize='small' />
                    ) : (
                      <VisibilityIcon disabled color='inherit' fontSize='small' />
                    )}
                  </Typography>

                  {(isOOE
                    ? ['mähverlust', 'roadRail', 'miscellaneous'].includes(record.animalFallen)
                    : !!record.animalFallen) && (
                    <span
                      style={{
                        fontSize: '16px',
                        height: '100%',
                        display: 'block',
                        marginLeft: 'auto',
                        paddingRight: '2px',
                        paddingLeft: '2px',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        border: 'solid',
                        borderWidth: '1px',
                        borderRadius: '6px',
                        borderColor: 'rgb(128,128,128,0.4)',
                        background: 'rgb(230,230,230,0.4)',
                      }}
                    >
                      FW
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
      <div className='item-mobile-view pad-left-null mrgin-tp-10'>
        <Typography style={{ marginBottom: '80px' }}>
          {t('general.countOfShots')}:{' '}
          {
            records.filter((rec) =>
              isOOE ? ['mähverlust', 'roadRail', 'miscellaneous'].includes(rec.animalFallen) : true
            ).length
          }
          {' / '}
          {huntingStatistics ? huntingStatistics.data.reduce((sum, entry) => sum + entry.target, 0) : 0}
        </Typography>
      </div>
      {openFilterPopup && huntingArea && (
        <FilterPopup
          isOpen={openFilterPopup}
          huntingAreaId={huntingArea.id}
          onClose={() => setOpenFilterPopup(false)}
        />
      )}
    </AppPage>
  );
};

export default withRouter(ShootingList);
