import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppPage } from '../';
const LockedUser = () => {
   
    const { t } = useTranslation();
  

  return (
    <AppPage headerTitle={t('profile.profileHeader')}>
     <h2 className="textStyle">
    { t("general.lockedUser")}
     </h2>
   </AppPage>
  );
};

export default LockedUser;
