import React, { useState } from 'react';
import useStyle from './image-gallery-style';
import FbImageLibrary from './react-fb-image-grid';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Fab from '@material-ui/core/Fab';
import { Grid } from '@material-ui/core';
import Compress from "compress.js";
import { useTranslation } from 'react-i18next';
import { API_CONSTANTS } from 'app/shared/config/api-constants';

const ImageGallery = props => {

    const { huntingImages, setHuntingImages, setAlert, isViewMode } = props;
    const classes = useStyle();
    const { t } = useTranslation();
    const [uploadPic, setuploadPic] = useState(null);
    const [changeImageIndex, setChangeImageIndex] = useState(null);

    const handleAddPhotoClick = (e) => {
        setChangeImageIndex(null);
        setTimeout(() => {
            uploadPic.click();
        }, 1);
    }

    const removeImage = (val, e) => {
        e.stopPropagation();
        let _images = [...huntingImages];
        _images.splice(val, 1);
        setHuntingImages([
            ..._images
        ]);
    }

    const changeImage = (val, e) => {
        e.stopPropagation();
        setChangeImageIndex(val);
        setTimeout(() => {
            uploadPic.click();
        }, 1);
    }

    const handleFileUpload = (e) => {
        e.preventDefault();
        let _files = e.target.files;

        if (_files && _files.length > 0) {

            let imgCount = (huntingImages && huntingImages.length || 0) + _files.length;

            if (imgCount <= 3 || changeImageIndex != null) {
                compress([..._files]);
            }
            else {
                setAlert({
                    open: true,
                    cancel: t("buttonText.close"),
                    title: t("huntRecord.alert"),
                    text: t("huntRecord.maxImageWarning"),
                });
                setChangeImageIndex(null);
            }
        }
    }

    function compress(files) {

        const compress = new Compress();

        compress.compress(files, {
            size: 4,
            quality: 0.75,
            maxWidth: 800,
            maxHeight: 800,
            resize: true
        }).then(modFiles => {

            let imgList = [];
            for (var i = 0; i <= modFiles.length - 1; i++) {
                imgList.push('data:image/png;base64,' + modFiles[i].data);
            }

            if (changeImageIndex != null) {

                let _images = [...huntingImages];
                _images[changeImageIndex] = imgList[0];
                setHuntingImages([
                    ..._images
                ]);
            }
            else {
                setHuntingImages([
                    ...huntingImages,
                    ...imgList
                ]);
            }
        });
    }

    return (
        <>
            {(!huntingImages || huntingImages && huntingImages.length < 3) && !isViewMode &&
                <>
                    <Grid item xs={10}
                        className={classes.imageLabel}>
                        {t("huntRecord.addImageLabel")}
                    </Grid>
                    <Grid item xs={2} className={classes.camIcon}>
                        <Fab
                            variant="extended"
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={handleAddPhotoClick}
                            className={classes.fabIcon}>
                            <CameraAltIcon />
                        </Fab>
                    </Grid>
                </>
            }
            <input
                id="hunterAreaPic"
                type="file"
                onChange={handleFileUpload}
                onClick={(event) => {
                    event.target.value = null
                }}
                name='hunterAreaPic'
                accept="image/*"
                ref={(ref) => setuploadPic(ref)}
                style={{ display: 'none' }}
                multiple={changeImageIndex == null} />

            {huntingImages && huntingImages.length > 0 &&
                <Grid item xs={12}>
                    <div className={classes.mediaSection} >
                        <FbImageLibrary
                            images={huntingImages}
                            removeImage={removeImage}
                            changeImage={changeImage}
                            isViewMode={isViewMode}
                            headerTitle={t("huntRecord.imageGallery")} />
                    </div>
                </Grid>
            }
        </>
    );
};

export default ImageGallery;
