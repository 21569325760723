import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';


export const makeReservation = data => {
    const url = API_CONSTANTS.reservations;
    return httpClient.post(url, data).then(res => res.data);
};

export const editReservation = data => {
    const url = API_CONSTANTS.reservations + `/${data.id}`;
    return httpClient.put(url, { huntingAreaFeatureId: data.huntingAreaFeatureId, timeFrom: data.timeFrom, timeTo: data.timeTo }).then(res => res.data);
};

export const cancelReservation = id => {
    const url = API_CONSTANTS.reservations + `/${id}`;
    return httpClient.delete(url);
};

export const fetchReservations = (huntingAreaId, featureId) => {
    const url = API_CONSTANTS.reservationsHAFeature.replace('{id}', huntingAreaId).replace('{featId}', featureId);
    return httpClient.get(url).then(res => res.data).then(res => res.map(i => ({ ...i, userName: (i.userFirstName && i.userLastName) ? (i.userFirstName + ' ' + i.userLastName) : null })));
};

export const fetchReservableTypes = () => {
    return httpClient.get(API_CONSTANTS.reservableTypes).then(res => res.data);
}