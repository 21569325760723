import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  FormControl,
  Select,
  InputLabel
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { AppPage } from 'app/shared/components';
import Snackbar from '@material-ui/core/Snackbar';
import * as accessRequestsService from '../shared/services/access-requests/access-requests';
import useStyles from './request-access-style';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import useOrganizations from './../../shared/hooks/useOrganizations';

const RequestAccess = props => {
  const { t } = useTranslation();
  const onChange = ({ name, value }) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value
      }
    };
    setFormData(updatedFormData);
  };


  const classes = useStyles();
  const initialData = {
    value: {
      organisationUnitId: null,
      forestDistrictCode: null,
      huntingAreaName: '',
      notes: ''
    },
    errors: null
  };
  const [formData, setFormData] = useState(initialData);
  const { organizations, loading } = useOrganizations();
  const [submitting, setSubmitting] = useState(false);
  const [snackBarOpen, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const inputLabel = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [labelWidth2, setLabelWidth2] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  React.useEffect(() => {
    setLabelWidth2(inputLabel2.current.offsetWidth);
  }, []);
  const isFormValid = () => {
    return formData.value.organisationUnitId && formData.value.forestDistrictCode;
  };

  const forestDistrictOptions = formData.value.organisationUnitId && organizations
    ? organizations.find(o => o.code == formData.value.organisationUnitId).forestDistricts
    : [];

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  };
  function resetForm() {
    setFormData(initialData);
  }
  const submitData = async () => {
    if (formData.value.forestDistrictCode == 'all') formData.value.forestDistrictCode = null;
    setSubmitting(true);
    const { response, error } = await accessRequestsService
      .postHuntingRequest({ ...formData.value })
      .catch(e => {
        setSubmitting(false);
      });

    if (response > 0) {
      setSnackBar(true);
      setSnackBarMsg(t('general.requestSubmitted'));
      resetForm();

    }
    else if (response === 0) {
      setSnackBar(true);
      setSnackBarMsg(t('general.requestNotSubmitted'));
      resetForm();
    }

    setSubmitting(false);
    if (error) {
      console.log(error);
    }
  };

  return (
    <AppPage headerTitle={t('headerTitle.requestAccess')}
      loading={loading || submitting}
    >
      <Box className="item-mobile-view" p={2} pt={4}>
        <Grid item xs={12}>
          <FormControl style={{ marginBottom: "8px" }} variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} htmlFor="outlined-org-native-simple">
              {t("textBoxLabel.organization")}
            </InputLabel>
            <Select
              native
              value={formData.value.organisationUnitId == null ? '' : formData.value.organisationUnitId}
              onChange={event => {
                formData.value.organisationUnitId = event.target.value;
                setFormData({ ...formData });
              }}

              labelWidth={labelWidth}
              inputProps={{
                name: "organisationUnitId",
                id: 'outlined-org-native-simple',
              }}
            >
              <option value=''></option>
              {organizations && organizations.map((option) =>
                <option key={"org" + option.id} value={option.code}>{option.name}</option>
              )}

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ marginBottom: "8px" }} variant="outlined" fullWidth>
            <InputLabel ref={inputLabel2} htmlFor="outlined-fd-native-simple">
              {t("textBoxLabel.forestDistrict")}
            </InputLabel>
            <Select
              native
              value={formData.value.forestDistrictCode == null ? '' : formData.value.forestDistrictCode}
              onChange={event => {
                formData.value.forestDistrictCode = event.target.value;
                setFormData({ ...formData });
              }}

              labelWidth={labelWidth2}
              inputProps={{
                name: "forestDistrictCode",
                id: 'outlined-fd-native-simple',
              }}
            >
              <option value=''></option>
              {forestDistrictOptions && forestDistrictOptions.map((option) =>
                <option key={"fDist" + option.id} value={option.code}>{option.name}</option>
              )}
              <option value='all'>{t('buttonText.all')}</option>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className="item-mobile-view" className={classes.formControl}>
          <TextField
            value={formData.value.huntingAreaName}
            label={t('textBoxLabel.huntingArea')}
            variant='outlined'
            fullWidth
            onChange={e =>
              onChange({ name: 'huntingAreaName', value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formControl}>
          <TextField
            value={formData.value.notes}
            label={t('textBoxLabel.notes')}
            variant='outlined'
            multiline
            rows="4"
            fullWidth
            onChange={e =>
              onChange({ name: 'notes', value: e.target.value })
            }
          />
        </Grid>
        <Grid container spacing={2} className={classes.actionButtons}>
          <Grid item>
            <Button
              data-testid="submitbtn"
              onClick={submitData}
              size='large'
              variant='contained'
              color='primary'
              disabled={!isFormValid()}
            >
              {t('buttonText.submit')}
            </Button>
          </Grid>
          {/* <Grid item>
            <Button onClick={submitData} size='large' variant='outlined'>
              Cancel
            </Button>
          </Grid> */}
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={
          <span id='message-id'>{snackBarMsg}</span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleSnackBarClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </AppPage>
  );
};
export default RequestAccess;