import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 112px)'
  },
  formControl: {
    marginBottom: theme.spacing(1)
  },
  cardActionBox: {
    width: '100%'
  },
  actionButtons: {
    marginTop: theme.spacing(1),
    // 'border-bottom': '1px',
    // 'border-bottom-style': 'solid',
    // 'border-bottom-color': 'grey'
  },
  expandMore: {
    position: 'absolute',
    right: 0,
    'margin-right': '-25px'
  },
  header: {
    background: theme.palette.primary.main,
    color: 'white',
    '& .record-item-big': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      '& .MuiSvgIcon-root': {
        marginLeft: '4px',
      }
    }
  }
}));

export default useStyles;
