import React from 'react';

import useStyle from './no-data-style';
import { useTranslation } from 'react-i18next';

const NoData = props => {
  const { t } = useTranslation();
  const { loading, message = t('general.noData') } = props;
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {loading && t('general.pleaseWait')}
      {!loading && message}
    </div>
  );
};

export default NoData;
