import { actionTypes } from './actions';

/** Initial state */
const initialState = {
  loading: false,
  data: {},
  error: null
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.APP_PERMISSIONS_DATA_REQUEST:
      return Object.assign(state, { loading: true, error: null });

    case actionTypes.APP_PERMISSIONS_DATA_SUCCESS:
      return { ...state, loading: false, data: payload };

    case actionTypes.APP_PERMISSIONS_DATA_ERROR:
      return Object.assign(state, {
        loading: false,
        data: null,
        error: payload || true
      });

    case actionTypes.APP_PERMISSIONS_DATA_RESET:
      return Object.assign(state, ...initialState);

    default:
      return state;
  }
};
