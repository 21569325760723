

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAccessRequests } from './../../hunting-area/shared/state/access-requests';

export default function useAccessRequests() {
    const dispatch = useDispatch();
    const accessRequests = useSelector(
        ({ huntingArea }) => huntingArea.accessRequestsData
    );
    useEffect(() => {
        dispatch(fetchAllAccessRequests());
    }, []);
    return {
        accessRequests: accessRequests && accessRequests.order && accessRequests.order.map(o => accessRequests.data[o]),
        loading: accessRequests.loading,
    };
}