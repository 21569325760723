import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PartlyCloudyDay(props) {
  return (
    <SvgIcon { ...props }>
      <g id="SketchAppSources.com" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-541.000000, -977.000000)">
          <g id="Group-3" transform="translate(539.000000, 952.000000)">
            <g id="icon" transform="translate(2.000000, 25.000000)">
              <g id="sun-/-normal">
                <circle id="Oval-1-Copy-2" strokeOpacity="0.195175918" stroke="#F0C419" strokeWidth="2.27999997" cx="39.8999995" cy="39.8999995" r="38.7599995"></circle>
                <circle id="Oval-1-Copy" strokeOpacity="0.604048295" stroke="#F0C419" strokeWidth="3.03999996" cx="39.8999995" cy="39.8999995" r="33.8199996"></circle>
                <circle id="Oval-1" fill="#F9A949" fillRule="evenodd" cx="39.8999995" cy="39.8999995" r="30.0199996"></circle>
              </g>
              <g id="cloud-/-normal" transform="translate(17.480000, 28.120000)" fill="#d0caca">
                <path d="M77.0600433,78.5333323 L27.5927924,78.5333323 C12.353714,78.5333323 0,66.1796184 0,50.9405399 C0,35.7014614 12.353714,23.3477475 27.5927924,23.3477475 C32.1914649,23.3477475 36.5273841,24.4727282 40.3403569,26.4624967 C45.6496259,11.0628797 60.2689115,0 77.4720711,0 C99.158452,0 116.738737,17.5802853 116.738737,39.2666662 C116.738737,39.7111314 116.731353,40.1538718 116.716699,40.5947715 C123.047192,43.9957979 127.35135,50.6799383 127.35135,58.3693686 C127.35135,69.5056183 118.323636,78.5333323 107.187386,78.5333323 L77.4720711,78.5333323 C77.4100851,78.5333208 77.0600433,78.5333323 77.0600433,78.5333323 L77.4720711,78.5333323 L77.0600433,78.5333323 Z" id="Oval-4"></path>
              </g>
            </g>
            <rect id="Rectangle-Copy" x="0" y="0" width="150" height="150"></rect>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default PartlyCloudyDay;
