import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ClearDay(props) {
  return (
    <SvgIcon { ...props }>
    <g id="SketchAppSources.com" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-331.000000, -952.000000)">
            <g id="Group-2" transform="translate(331.000000, 952.000000)">
                <g id="icon">
                    <g id="sun-/-big">
                        <circle id="Oval-1-Copy-2" strokeOpacity="0.195175918" stroke="#F0C419" strokeWidth="3" cx="75" cy="75" r="73.5"></circle>
                        <circle id="Oval-1-Copy" strokeOpacity="0.604048295" stroke="#F0C419" strokeWidth="4" cx="74.5833333" cy="74.5833333" r="64.25"></circle>
                        <circle id="Oval-1" fill="#F9A949" fillRule="evenodd" cx="74.5833333" cy="74.5833333" r="56.25"></circle>
                    </g>
                </g>
                <rect id="Rectangle" x="0" y="0" width="150" height="150"></rect>
            </g>
        </g>
    </g>
    </SvgIcon>
  );
}

export default ClearDay;
