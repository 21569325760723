import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AreaMap from './area-map/area-map';
import AreaAccessRequests from './area-access-requests/area-access-requests';
import CreateAccess from './create-access/create-access';
import ActiveUsers from './active-users/active-users';
import RequestAccess from './request-access/request-access';
import AreasOverview from './areas-overview/areas-overview';
import HuntRecord from './area-hunt-record/area-hunt-record';
import HuntingPlan from './hunt-statistics/hunting-plan';

import {
  can,
  permissions
} from 'app/shared/services/app-permissions/app-permissions';
import LockedUser from 'app/shared/components/locked-user';
import ShootingList from './shooting-list/shooting-list';
import ReservationsList from './reservations/reservations-list';

const HuntingAreaRoutes = props => (
  <>
    <Switch>
      <Route path={props.match.url + '/area-map/:id'} component={AreaMap} />
      <Route path={props.match.url + '/:huntingAreaId/records/new'} component={HuntRecord} />
      <Route path={props.match.url + '/:huntingAreaId/records/:id'} component={HuntRecord} />
      <Route
        path={props.match.url + '/:id/active-users'}
        component={ActiveUsers}
      />
      <Route
        path={props.match.url + '/locked'}
        component={LockedUser}
      />
      <Route
        path={props.match.url + '/:id/plan'}
        component={HuntingPlan}
      />
      {<Route
        path={props.match.url + '/:id/shooting-list'}
        component={ShootingList}
      />}
      {can(permissions.huntingArea.viewReservations) && <Route
        path={props.match.url + '/:id/reservations'}
        component={ReservationsList}
      />}
      {!can(permissions.viewRequests) && (
        <Route
          path={props.match.url + '/request-hunting-access'}
          component={RequestAccess}
        />
      )}
      {can(permissions.viewRequests) && (
        <Route
          path={props.match.url + '/area-access-requests'}
          component={AreaAccessRequests}
        />
      )}
      {can(permissions.grantPermissions) && (
        <Route
          path={props.match.url + '/create-access/:id?'}
          component={CreateAccess}
        />
      )}
      {can(permissions.huntingAreaView) && (
        <>
          <Route
            path={props.match.url + '/overview'}
            component={AreasOverview}
          />
          <Redirect from={props.match.url} to={props.match.url + '/overview'} />
        </>
      )}
      <Redirect from={props.match.url} to='/profile' />
    </Switch>
  </>
);

export default HuntingAreaRoutes;
