import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';


const fetchHuntingAreaMap = (id) => {
  const url = API_CONSTANTS.huntingmap + `${id}/map`;
  return httpClient
    .get(url)
    .then(res => processHAFeatures(res.data))
    .catch(error => { throw error });
};

const AddMapMarker = (areaId, data) => {
  const url = API_CONSTANTS.huntingmap + `${areaId}/geofeature`;
  return httpClient
    .post(url, data)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

const DeleteMarker = (id) => {
  ;
  const url = API_CONSTANTS.huntingmap + `geofeature/${id}`;
  return httpClient
    .delete(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}


function processHAFeatures(huntingAreaData) {
  let _huntingArea = { features: [] };
  let features = [];
  if (huntingAreaData) {
    if (huntingAreaData.features.length) {
      features = huntingAreaData.features;
      features.forEach(item => {
        item.geometry = JSON.parse(item.geometry); //.toString().replace(/'/g, '\"'));;
      });
    }
    if (huntingAreaData.geoJsonBorder) {
      _huntingArea = {
        ...huntingAreaData,
        features,
        geoJsonBorder: JSON.parse(
          huntingAreaData.geoJsonBorder.toString().replace(/'/g, '"')
        )
      };
    }
    if (huntingAreaData.geoJsonTrails) {
      _huntingArea = {
        ..._huntingArea,
        geoJsonTrails: JSON.parse(
          huntingAreaData.geoJsonTrails.toString().replace(/'/g, '"')
        )
      };
    }
  }

  return _huntingArea;
};

const ModifyMapLayerChoice = (request) => {

  // const url = API_CONSTANTS.huntingmap+ `${id}/geofeature`;
  return httpClient
    .post(request.url, request.requestData)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

export { fetchHuntingAreaMap, AddMapMarker, DeleteMarker, ModifyMapLayerChoice };
