import React, { useEffect, useState } from 'react';
import useStyles from './app-loader-style';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch } from 'react-redux';

const AppLoader = () => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.loaderContainer}>
        <img
          className={classes.appLogo}
          src='/assets/app-logo-light.jpg'
          alt='app logo'
        />
        <LinearProgress variant='determinate' value={completed} />
      </div>
    </div>
  );
};

export default AppLoader;
