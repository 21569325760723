import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';

export default function AppTitle(){
    const { t } = useTranslation();
    return (
      <>
        <Helmet>
          <title>{t('general.applicationTitle')}</title>
        </Helmet>
      </>
    )
}