
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAccessRequests } from '../services/access-requests/access-requests';

const initialState = {
    loading: false,
    data: {},
    order: [],
    error: null
};

export const fetchAllAccessRequests = createAsyncThunk(
    'accessRequests/fetchAll',
    async () => {
        return await fetchAccessRequests().then(res => res.response);
    }
)

const accessRequestSlice = createSlice({
    name: 'accessRequests',
    initialState,
    extraReducers: {
        [fetchAllAccessRequests.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchAllAccessRequests.fulfilled]: (state, action) => {
            state.loading = false;
            if (!action.payload) return;
            state.data = action.payload.reduce((acc, r) => {
                acc[r.id] = r;
                return acc;
            }, {});
            state.order = action.payload.map(r => r.id);
        },
        [fetchAllAccessRequests.rejected]: (state, action) => {
            state.loading = false;
            state.data = {}
            state.order = []
            if (!action.payload) return;
            state.error = action.payload;
        },
    }
})

export default accessRequestSlice.reducer