import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import { NoData, AppPage } from 'app/shared/components';
import AreaItem from '../area-item/area-item';

import useStyles from './areas-overview-style';
import useHuntingAreas from 'app/shared/hooks/useHuntingAreas';
import useOfflineRequests from 'app/shared/hooks/useOfflineRequests';
import useOnline from 'app/shared/hooks/useOnline';

export const AreasOverview = (props) => {
  const [search, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [currentView, setNewView] = useState('specialistView');
  const { history } = props;
  const { huntingAreas, specialistAreas, hunterAreas } = useHuntingAreas();
  const { offlineRequests } = useOfflineRequests();
  const searchArea = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const isOnline = useOnline();
  const [permissions, setPermissions] = useState([]);
  const [backUrl, setBackUrl] = useState(null);

  let defaultWeatherProps = {
    showMoreWeather: false,
    latitude: null,
    longitude: null,
  };
  const [weatherProps, setWeatherProps] = useState(defaultWeatherProps);

  function viewBasedHuntingAreas() {
    const isSpecialistView = specialistAreas.length > 0;
    const isHunterView = hunterAreas.length > 0;
    const isMultiView = isSpecialistView && isHunterView;
    return {
      isSpecialistView,
      isHunterView,
      isMultiView,
    };
  }

  function getDisplayedAreas() {
    const { isMultiView } = viewBasedHuntingAreas();

    let areas = [];
    let displayedAreas = [];

    switch (true) {
      case isMultiView && currentView === 'specialistView':
        areas = specialistAreas;
        break;
      case isMultiView && currentView === 'hunterView':
        areas = hunterAreas;
        break;
      default:
        areas = specialistAreas.length > 0 ? specialistAreas : hunterAreas;
    }

    if (search) {
      displayedAreas = areas.filter(({ organisationUnit, name, forestDistrict }) => {
        const areaName = `${name} ${organisationUnit} - ${forestDistrict}`.toLowerCase();
        return areaName.indexOf(search.toLowerCase()) > -1;
      });
    } else {
      displayedAreas = areas;
    }

    return { areas, displayedAreas };
  }

  function changeView(event, newValue) {
    setSearchValue('');
    setNewView(newValue);
  }

  const [lang, seLang] = useState('de');

  useEffect(() => {
    if (i18n.language) {
      seLang(i18n.language.substr(0, 2).toLowerCase());
    }
  }, []);

  function hasOfflineRecords(areaId) {
    return (
      offlineRequests.filter((r) => r.type.startsWith('huntingRecord') && r.payload.huntingAreaId === areaId).length > 0
    );
  }

  const callDarkSky = ({ showMoreWeather, latitude, longitude, huntingAreaName }) => {
    removeElement('darksky_widget_11');
    var newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.id = 'darksky_widget_11';
    newScript.src = `https://darksky.net/widget/default/${latitude},${longitude}/ca12/${lang}.js?height=600&title=Full Forecast&textColor=333333&bgColor=FFFFFF&skyColor=333&fontFamily=Default&units=us&htColor=333333&ltColor=C7C7C7&displaySum=yes&displayHeader=yes`;
    document.getElementsByTagName('head')[0].appendChild(newScript);
    setWeatherProps({
      showMoreWeather,
      latitude,
      longitude,
      huntingAreaName,
    });
  };

  function removeElement(id) {
    var elem = document.getElementById(id);
    if (elem) return elem.parentNode.removeChild(elem);
  }

  const { isMultiView, isHunterView } = viewBasedHuntingAreas();

  const { areas, displayedAreas } = getDisplayedAreas();

  if (huntingAreas && huntingAreas.isLocked) {
    history.push('/locked');
  }

  return (
    <AppPage
      headerTitle={
        weatherProps.showMoreWeather
          ? `${weatherProps.huntingAreaName + ' ' + t('huntingArea.weather')}`
          : t('huntingArea.huntingAreas')
      }
      headerLeft={
        weatherProps.showMoreWeather ? (
          <IconButton
            color='inherit'
            aria-label='back'
            onClick={() => setWeatherProps(defaultWeatherProps)}
            edge='start'
          >
            <ArrowBackIcon />
          </IconButton>
        ) : null
      }
      loading={huntingAreas.loading || loading}
    >
      <div
        key='customize-script-container'
        style={weatherProps.showMoreWeather ? { display: 'block' } : { display: 'none' }}
        id='customize-script-container'
      ></div>
      {weatherProps.showMoreWeather ? null : (
        <Box
          style={{
            paddingBottom: '50px',
            display: 'block',
          }}
          className='item-mobile-view'
          p={2}
        >
          {isMultiView && (
            <Tabs
              value={currentView}
              onChange={changeView}
              indicatorColor='primary'
              textColor='primary'
              centered
              variant='fullWidth'
            >
              <Tab selected label={t('labelInfo.specialistView')} value='specialistView' />
              <Tab fullWidth label={t('labelInfo.hunterView')} value='hunterView' />
            </Tabs>
          )}
          <Grid container spacing={3}>
            {areas.length >= 3 && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  className={classes.searchField}
                  label={t('huntingArea.searchHuntingArea')}
                  margin='normal'
                  value={search}
                  onChange={searchArea}
                  placeholder={t('huntingArea.searchHuntingAreaHint')}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {displayedAreas.map((area) => (
                <AreaItem
                  lang={lang}
                  callDarkSky={callDarkSky}
                  setWeatherProps={setWeatherProps}
                  isLoading={huntingAreas.loading || loading}
                  key={area.id + area.name}
                  area={area}
                  skipFWCalculation={areas.length >= 5}
                  setLoading={setLoading}
                  hasOfflineRecords={hasOfflineRecords(area.id)}
                />
              ))}
              {displayedAreas.length <= 0 && <NoData loading={huntingAreas.loading} />}
            </Grid>
          </Grid>
        </Box>
      )}
    </AppPage>
  );
};

export default AreasOverview;
