let deferredPrompt;

function initialiseAddToHomeService() {
  window.addEventListener('beforeinstallprompt', function (event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = event;
  });
}

function getDeferredPrompt() {
  return deferredPrompt;
}

async function getUserChoice() {
  let choice = false;

  if (deferredPrompt) {
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        choice = true;
      }
      deferredPrompt = null;
    });
  } else {
    choice = true;
  }

  return choice;
}

function setDeferredPrompt(value) {
  deferredPrompt = value;
}

export { getDeferredPrompt, initialiseAddToHomeService, getUserChoice, setDeferredPrompt };
