import { httpClient } from 'app/shared/services';
import { apiBaseUrl } from 'environement/environment';
import { API_CONSTANTS } from 'app/shared/config/api-constants';

const fetchHuntingAreas = async (columns) => {
  const url = apiBaseUrl + API_CONSTANTS.huntingareas + (columns ? `?columns=${columns}` : '');

  return httpClient
    .get(url)
    .then(res => parseHAsResponse(res.data))
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const fetchHuntingArea = async (id) => {
  const url = apiBaseUrl + API_CONSTANTS.huntingarea.replace('{id}', id);

  return httpClient
    .get(url)
    .then(res => parseHAResponse(res.data))
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const fetchActiveUsers = huntingAreaId => {
  const url = API_CONSTANTS.activeUsers.replace('{id}', huntingAreaId);

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

async function changePictureRequest(data) {
  return httpClient
    .post(API_CONSTANTS.huntingAreaPictureUpdate, data)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

function parseHAResponse(item) {
  return {
    ...item,
    id: item.id,
    name: item.name,
    picture: item.picture,
    firstName: '',
    lastName: '',
    photo: '',
    weather: item.weather,
    specialist: item.specialist ? item.specialist : null
  };
}

function parseHAsResponse(res) {
  return res.map(item => parseHAResponse(item));
}

const fetchUsersByKey = key => {
  const url = API_CONSTANTS.usersByKey.replace('{key}', key);

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

async function revokeHAAccessForUser(userId, huntingAreaId) {
  const url = API_CONSTANTS.revokeUserAccessHA
    .replace('{userId}', userId)
    .replace('{huntingAreaId}', huntingAreaId);

  return httpClient
    .delete(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

export {
  fetchHuntingAreas,
  fetchHuntingArea,
  fetchActiveUsers,
  changePictureRequest,
  revokeHAAccessForUser,
  fetchUsersByKey
};