import { combineReducers } from 'redux';
import huntingAreaData from './shared/state/hunting-area';
import huntingRecords from './shared/state/hunting-record';
import markers from './shared/state/markers';
import hunters from './shared/state/hunter';
import accessRequestsData from './shared/state/access-requests';
export default combineReducers({
  huntingAreaData,
  huntingRecords,
  markers,
  hunters,
  accessRequestsData
});