import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    background: fade(theme.palette.common.white, 0.8)
  }
}));
