import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import appInfo from 'app/app-version.json';

export const initializeI18n = () =>
  i18n
    /** load translation using xhr -> see /public/locales
     * learn more: https://github.com/i18next/i18next-xhr-backend
     * */
    .use(Backend)
    /**
     * detect user language. learn more: https://github.com/i18next/i18next-browser-languageDetector
     */
    .use(LanguageDetector)
    .use(initReactI18next)
    /**
     * init i18next. For all options read: https://www.i18next.com/overview/configuration-options
     */
    .init({
      fallbackLng: 'en',
      debug: true,
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + appInfo.app.version
      },
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      }
    });

export default i18n;
