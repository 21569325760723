import { createSlice } from "@reduxjs/toolkit";

const formStateSlice = createSlice({
    name: 'formState',
    initialState: {},
    reducers: {
        updateFormState(state, action) {
            state[action.payload.name] = action.payload.state;
        },
        clearFormState(state, action) {
            delete state[action.payload.name];
        }
    },
})

export const { updateFormState, clearFormState } = formStateSlice.actions

export default formStateSlice.reducer