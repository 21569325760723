import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import useStyle from './app-language-style';
import { useTranslation } from 'react-i18next';

const AppLanguage = () => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const languageChange = lang => {
    i18n.changeLanguage(lang);
    window.location.reload();
    handleClose();
  };
  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
    languageChange(event.target.checked ? 'de' : 'en');
  };
  const [state, setState] = useState({
    german: i18n.language ? i18n.language.substr(0, 2).toLowerCase() == 'de' ? true : false : false
  });
  const AntSwitch = withStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
    checked: {},
  }))(Switch);
  return (
    <div>
      {/* <Button
        aria-controls='language-menu'
        aria-haspopup='true'
        onClick={handleClick}
        startIcon={<LanguageIcon />}
        className={classes.root}
      >
        {i18n.language.substr(0, 2)}
      </Button>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => languageChange('en')}>English</MenuItem>
        <MenuItem onClick={() => languageChange('de')}>German</MenuItem>
      </Menu> */}

      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1} className={classes.root}>
          <Grid data-testid="lang-en" item>EN</Grid>
          <Grid item>
            <AntSwitch
              checked={state.german}
              onChange={handleChange('german')}
              value="german"
            />
          </Grid>
          <Grid data-testid="lang-de" item>DE</Grid>
        </Grid>
      </Typography>
    </div>
  );
};

export default AppLanguage;
