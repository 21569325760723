import React, { useState, useEffect } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Box, Grid, Avatar, Link } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import useStyle from './area-hunt-record-style';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';

export const HunterCard = props => {

  const styles = useStyle();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    value: {
      id: '',
      hunterName: null,
      cardId: ""
    },
    errors: null
  });
  let { editCard } = props


  /**
   * Handle the form value change
   */
  const onChange = ({ name, value }) => {
    if (disabledField) {
      return;
    }
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value,
        id: null
      }
    };
    setFormData(updatedFormData);
    if (props.errorMsg) {
      props.setErrorMsg(null);
    }
  };

  const [disabledField, setDisabledField] = useState(false);


  const onSelectHunter = ({ name, value }) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value,
        cardId: value ? value.cardId : ''
      }
    };
    setFormData(updatedFormData);
    setDisabledField(value ? true : false);
    if (props.errorMsg) {
      props.setErrorMsg(null);
    }
  }
  function resetForm() {
    setFormData({
      value: {
        id: '',
        hunterName: null,
        cardId: ""
      },
      errors: null
    });
    setDisabledField(false);
  }
  const isFormValid = () => {
    const {
      hunterName,
      cardId
    } = formData.value;

    return (
      cardId &&
      hunterName
    );
  };

  function onDelete() {
    props.onHunterCardDelete(formData.value.cardId);
    resetForm();
  }

  function onHunterCardSave() {
    let card = {
      hunterName: formData.value.hunterName,
      cardId: formData.value.cardId,
      isNewAdded: !disabledField,
      oldHunterCardId: editCard ? editCard.oldHunterCardId : null
    }
    let returnResult = props.onHunterCardSave(card);
    if (!returnResult)
      resetForm();

  }

  const defaultProps = {
    getOptionLabel: option => option.hunterName
  };


  function onHunterCardCancel() {
    resetForm();
    props.setErrorMsg(null);
    props.onHunterCardCancel()
  }

  useEffect(() => {
    if (editCard) {
      const updatedFormData = {
        value: {
          ...editCard
        }
      };
      setFormData(updatedFormData);
    }
  }, editCard)



  return (


    <Dialog open={props.open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{editCard ? t("huntRecord.editHunterCard") : t("huntRecord.addHunterCard")}
        {editCard && <span style={{ color: "red", cursor: "pointer", float: "right" }}> <DeleteIcon onClick={onDelete} /></span>}
      </DialogTitle>
      <DialogContent >
        <Box p={2} pt={4} style={{ paddingTop: "0px" }}>
          <Grid container>

            <Grid item xs={12} className={styles.formControl}>

              <FormControl fullWidth>
                <TextField
                  onChange={(event, newValue) => {
                    newValue = event.target.value;
                    newValue = newValue.toString().slice(0, 200)
                    onChange({ name: 'hunterName', value: newValue });


                  }}
                  disabled={disabledField}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13) {
                      // Cancel the default action, if needed
                      e.preventDefault();
                      // Trigger the button element with a click
                      document.getElementById("doccardid").focus();
                    }


                  }}
                  id="hunterNameid"
                  value={formData.value.hunterName}
                  label={t("huntRecord.hunterName")}
                  variant='outlined'
                  maxLength="20"
                  fullWidth
                  required
                />


              </FormControl>

            </Grid>
            <Grid item xs={12} className={styles.formControl}>
              <TextField
                onChange={(event, newValue) => {
                  newValue = event.target.value;
                  newValue = newValue.toString().slice(0, 20)
                  onChange({ name: 'cardId', value: newValue });


                }}
                disabled={disabledField}
                onKeyUp={(e) => {
                  e.preventDefault();
                  if (e.keyCode === 13) {
                    // Cancel the default action, if needed
                    e.preventDefault();
                    // Trigger the button element with a click
                    onHunterCardSave()
                  }


                }}
                id="doccardid"
                value={formData.value.cardId}
                label={t("huntRecord.cardId")}
                variant='outlined'
                fullWidth
                required
              />

            </Grid>

          </Grid>
          <Grid container>
            <span style={{ color: "red" }}> {props.errorMsg}</span>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>

        <Button data-testid="cancelbtn" onClick={onHunterCardCancel} size='large' variant='outlined'>
          {t("huntRecord.cancel")}
        </Button>
        <Button
          onClick={onHunterCardSave}
          size='large'
          variant='contained'
          color='primary'
          disabled={!isFormValid()}
          className={styles.noWrap}
        >
          {!editCard ? t("huntRecord.save") : t("huntRecord.update")}
        </Button>
      </DialogActions>
    </Dialog>


  );
}


export default HunterCard;
