import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fetchHuntingAreaMap } from '../services/hunting-area-map/hunting-area-map';
import { fetchHuntingAreas } from '../services/hunting-area/hunting-area';
import fetchHuntStatistics from '../services/hunting-plan/hunting-plan';
import { executeOnlineOrOffline } from '../../../shared/state/offline-requests';
import { fromDateString } from 'app/shared/dateMethods';
import { updateStatistics } from './additionalActions';
import { can, permissions } from 'app/shared/services/app-permissions/app-permissions';

const initialState = { loading: false, data: {}, error: null, hunterAreas: [], specialistAreas: [], skipQuota: false };

export const fetchAllAreas = createAsyncThunk('huntingArea/fetchAll', async (payload) => {
  return await fetchHuntingAreas(payload && payload.columns).then((res) => res.response);
});

export const fetchAllAreasForCreateRequest = createAsyncThunk('huntingArea/fetchAll', async () => {
  return await fetchHuntingAreas().then((res) => res.response);
});

export const fetchAreaMap = createAsyncThunk('huntingArea/fetchMap', async (id) => {
  return await fetchHuntingAreaMap(id);
});

export const fetchHuntingPlan = createAsyncThunk('huntingArea/fetchHuntingPlan', async (payload) => {
  return await fetchHuntStatistics(payload.areaId, payload.year).then((res) => res.response);
});

export const requestChangePicture = createAsyncThunk('huntingArea/requestChangePicture', async (payload, thunkApi) => {
  return executeOnlineOrOffline('huntingArea.changePicture', payload, thunkApi);
});

function changeAreaPictureReducer(state, action) {
  if (state.data[action.payload.id]) {
    if (action.payload.isMaster) state.data[action.payload.id].picture = action.payload.picture;
    else state.data[action.payload.id].hunterPicture = action.payload.picture;
  }
}

function hasUpdatePermission(area) {
  return can(permissions.huntingArea.changeDescription, area.permissions);
}

const huntingAreaSlice = createSlice({
  name: 'huntingArea',
  initialState,
  reducers: {
    changeAreaPicture: changeAreaPictureReducer,
    cleanupAreas: (state) => {
      const toDelete = Object.values(state.data).reduce((acc, area) => {
        if (area.lastUsedDate && (!acc || new Date(area.lastUsedDate) < new Date(acc.lastUsedDate))) {
          return area;
        }
        return acc;
      }, undefined);
      if (!toDelete) {
        state.skipQuota = true;
        return;
      }
      const deletable = { ...toDelete };
      delete deletable.lastUsedDate;
      delete deletable.geoJsonBorder;
      delete deletable.geoJsonTrails;
      state.data[deletable.id] = deletable;
    },
  },
  extraReducers: {
    [fetchAllAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllAreas.fulfilled]: (state, action) => {
      state.loading = false;
      if (!action.payload) return;
      action.payload.forEach((a) => {
        const payload = { ...a };
        delete payload.geoJsonBorder;
        delete payload.features;
        delete payload.huntingPlans;
        delete payload.huntingPlan;
        delete payload.huntingPlanYears;

        if (a.huntingStatistics && a.huntingStatistics.data) {
          payload.huntingStatistics.data = a.huntingStatistics.data.map((d) => ({
            animalTypeId: d.animalTypeId,
            name: d.name,
            icon: d.icon,
            position: d.position,
            actual: d.actual,
            target: d.target,
          }));
        }
        delete payload.userId;
        state.data[a.id] = Object.assign({}, state.data[a.id], payload);
      });
      const hunterAreas = action.payload.filter((a) => a.isHunter);
      state.hunterAreas = hunterAreas.map((a) => a.id);
      hunterAreas.forEach((a) => {
        state.data[a.id].hunterPicture = a.picture;
        state.data[a.id].hunterPermissions = a.permissions;
      });
      const specialistAreas = action.payload.filter((a) => !a.isHunter || hasUpdatePermission(a));
      state.specialistAreas = specialistAreas.map((a) => a.id);
      specialistAreas.forEach((a) => {
        state.data[a.id].picture = a.picture;
        state.data[a.id].permissions = a.permissions;
      });
      state.error = null;
    },
    [fetchAllAreas.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [fetchAreaMap.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const payload = { ...action.payload };
      payload.lastUsedDate = new Date().toISOString();
      delete payload.features;
      delete payload.records;

      state.data[action.payload.id] = Object.assign({}, state.data[action.payload.id], payload);
    },
    [fetchHuntingPlan.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const payload = { ...action.payload };
      payload.animalTypes = payload.data.map((a) => {
        const aa = {
          ...a,
          animalClasses: a.data.map((c) => {
            const cc = { ...c, shootingPlan: c.target, id: c.animalClassId };
            delete cc.animalClassId;
            delete cc.data;
            delete cc.actual;
            delete cc.target;
            return cc;
          }),
          shootingPlan: a.target,
          id: a.animalTypeId,
        };
        delete aa.animalTypeId;
        delete aa.actual;
        delete aa.data;
        delete aa.target;
        return aa;
      });
      delete payload.data;
      if (!state.data[action.payload.huntingAreaId]) return;
      state.data[action.payload.huntingAreaId].huntingPlans = Object.assign(
        (state.data[action.payload.huntingAreaId] && state.data[action.payload.huntingAreaId].huntingPlans) || {},
        { [payload.year]: payload }
      );
    },
    [requestChangePicture.fulfilled]: (state, action) => {
      if (!action.payload) return;
      changeAreaPictureReducer(state, {
        payload: {
          id: action.payload.HuntingAreaId,
          picture: action.payload.Picture,
          isMaster: action.payload.IsMasterPicture,
        },
      });
    },
    [updateStatistics]: (state, action) => {
      const updateNext = (next) => {
        if (!next) return;
        const areaToUpdate = state.data[next.huntingAreaId];
        if (areaToUpdate.defaultHuntingPlan !== fromDateString(next.huntingDate).getFullYear().toString()) return;
        if (!areaToUpdate) return;
        if (areaToUpdate.huntingStatistics && areaToUpdate.huntingStatistics.data) {
          const statisticsToUpdate = areaToUpdate.huntingStatistics.data.find(
            (d) => d.animalTypeId === next.animalTypeId
          );
          statisticsToUpdate.actual++;
        }
      };
      updateNext(action.payload.next);

      const updatePrevious = (previous) => {
        if (!previous) return;
        const areaToUpdate = state.data[previous.huntingAreaId];
        if (areaToUpdate.defaultHuntingPlan !== fromDateString(previous.huntingDate).getFullYear().toString()) return;
        if (!areaToUpdate) return;
        if (areaToUpdate.huntingStatistics && areaToUpdate.huntingStatistics.data) {
          const statisticsToUpdate = areaToUpdate.huntingStatistics.data.find(
            (d) => d.animalTypeId === previous.animalTypeId
          );
          statisticsToUpdate.actual--;
        }
      };
      updatePrevious(action.payload.previous);
    },
  },
});

export const { changeAreaPicture, cleanupAreas } = huntingAreaSlice.actions;

export default huntingAreaSlice.reducer;
