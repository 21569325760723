import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  marker: {
    top: '-2px',
    left: '5px',
    position: 'relative',
    width: '30px',
    height: '40px',
    '& >.top': {
      position: 'relative',
      width: '100%',
      height: '20px',
      borderTopLeftRadius: 'calc(24px)',
      borderTopRightRadius: 'calc(24px)',
      border: '3px solid #d60404',
      borderBottom: 'none',
      '& >.outline': {
        position: 'absolute',
        top: '-4px',
        left: '-7px',
        width: '38px',
        height: '40px',
        transform: 'scaleX(0.55) rotate(45deg)',
        border: '4px solid #d60404',
        borderTop: 'none',
        borderLeft: 'none',
      },
    }
  },
}));

export default useStyles;