import useQuery from './useQuery';
import { fromDateString } from './../dateMethods';
import { useAllAnimalTypes, useHuntingPlanWithDate, useValidFilterDefaults } from './useHuntingPlan';

export default function useRecordFilters(records, huntingArea) {
    const [query] = useQuery();
    query.onlyOwnShots = query.onlyOwnShots === 'true';

    let filtered = records;
    if (query.onlyOwnShots && huntingArea) {
        filtered = filtered.filter(r => r.userId === huntingArea.userId);
    }

    const allAnimalTypes = useAllAnimalTypes(huntingArea);

    const animalTypes = allAnimalTypes && allAnimalTypes.reduce((acc, t) => {
        acc[t.id] = t;
        return acc;
    }, {});

    const animalClasses = allAnimalTypes && allAnimalTypes.map(t => t.animalClasses).flat().reduce((acc, c) => {
        acc[c.id] = c;
        return acc;
    }, {});

    if (query.selectedAnimalTypes && query.selectedAnimalTypes.length > 0 && huntingArea) {
        filtered = filtered.filter(r => animalTypes[r.animalTypeId] && query.selectedAnimalTypes.includes(animalTypes[r.animalTypeId].name));
    }

    if (query.selectedAnimalClasses && query.selectedAnimalClasses.length && huntingArea) {
        filtered = filtered.filter(r => animalClasses[r.animalClassId] && query.selectedAnimalClasses.includes(animalClasses[r.animalClassId].name));
    }

    if (query.passing === 'shots') {
        filtered = filtered.filter(r => !r.animalFallen);
    } else if (query.passing === 'animalFallen') {
        filtered = filtered.filter(r => r.animalFallen);
    }

    const selectedHuntingPlan = useHuntingPlanWithDate(huntingArea, new Date());
    const { validFrom: validFromParts, validTo: validToParts } = useValidFilterDefaults(huntingArea);
    const validFromYear = validFromParts && validFromParts[2];
    const validToYear = validToParts && validToParts[2];
    const validFrom = validFromParts && validFromParts.slice(0, 2);
    const validTo = validToParts && validToParts.slice(0, 2);
    const fromYear = query.fromYear || validFromYear || new Date().getFullYear();
    const [fromDate, fromMonth] = query.fromDate ? query.fromDate.split('/').map(p => parseInt(p)) : selectedHuntingPlan ? validFrom.map(p => parseInt(p)) : [1, 1];
    const toYear = query.toYear || validToYear || new Date().getFullYear();
    const [toDate, toMonth] = query.toDate ? query.toDate.split('/').map(p => parseInt(p)) : selectedHuntingPlan ? validTo.map(p => parseInt(p)) : [31, 12];

    filtered = filtered.filter(r => {
        const [date, month, year] = r.huntingDate.split('/').map(p => parseInt(p));
        const compareDate = new Date(year, month - 1, date);
        for (let y = fromYear; y <= toYear; y++) {
            const lowerBound = new Date(y, fromMonth - 1, fromDate);
            const upperBound = new Date(y, toMonth - 1, toDate);
            if (upperBound < lowerBound) {
                upperBound.setFullYear(upperBound.getFullYear() + 1);
            }
            if (lowerBound <= compareDate && compareDate <= upperBound) return true;
        }
        return false;
    })

    const sortBy = query.sortBy || 'huntingDate';
    const sortDir = query.sortDir || 'desc';

    filtered = filtered
        .filter(r => animalTypes && animalTypes[r.animalTypeId] && animalClasses && animalClasses[r.animalClassId])
        .map(r => ({
            ...r,
            icon: animalTypes[r.animalTypeId].icon,
            animalTypeName: animalTypes && animalTypes[r.animalTypeId].name,
            animalClassName: animalClasses[r.animalClassId].name
        }));
    if (sortBy == 'huntingDate') {
        filtered = filtered.sort((a, b) => {
            const adate = fromDateString((sortDir == 'asc' ? a : b).huntingDate);
            const bdate = fromDateString((sortDir == 'asc' ? b : a).huntingDate);
            return (bdate < adate) - (bdate > adate);
        });
    } else if (sortBy == 'animalType') {
        filtered = filtered.sort((a, b) => {
            const aname = (sortDir == 'asc' ? a : b).animalTypeName;
            const bname = (sortDir == 'asc' ? b : a).animalTypeName;
            return aname.localeCompare(bname);
        });
    } else if (sortBy == 'animalClass') {
        filtered = filtered.sort((a, b) => {
            const aname = (sortDir == 'asc' ? a : b).animalClassName;
            const bname = (sortDir == 'asc' ? b : a).animalClassName;
            return aname.localeCompare(bname);
        });
    }
    return filtered;
}