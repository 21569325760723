import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFormState, updateFormState } from '../state/form-state';

export default function useFormState(name, initialState) {
    const formState = useSelector(({ global }) => global.formState[name]);
    const [temporaryState, setTemporaryState] = useState(formState || initialState);
    const dispatch = useDispatch();
    return [temporaryState, setTemporaryState, (state) => {
        dispatch(updateFormState({ name, state: state || temporaryState }))
    }, () => {
        dispatch(clearFormState({ name }));
    }, formState];
}