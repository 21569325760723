import React, { useState, useEffect } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Box, Grid, Avatar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import useStyle from './area-map-marker-style';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LocationService } from 'app/shared/services/location/location-service';

export const AreaMapMarker = props => {

  const styles = useStyle();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    value: {
      markerImage: null,
      title: '',
      comment: '',
      useMyLocation: false,
      useMap: false
    },
    errors: null
  });



  useEffect(() => {
    const locationService = new LocationService();
    locationService.subscribeToLocation(({ location }) => {
      setMyLocation({ lat: location[0], lng: location[1] });
    })
    if (navigator.geolocation) {
      locationService.getPosition();
    } else {
      const updatedFormData = {
        ...formData,
        value: {
          ...formData.value,
          useMap: true,
          useMyLocation: false
        }
      };
      setFormData(updatedFormData);
    }
    return () => locationService.clearPermissions();
  }, []);
  /**
   * Handle the form value change
   */
  const onChange = ({ name, value }) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value
      }
    };
    setFormData(updatedFormData);
  };
  function resetForm() {
    setFormData({
      value: {
        markerImage: null,
        name: '',
        comment: '',
        useMyLocation: false,
        useMap: false
      },
      errors: null
    });
  }
  const isFormValid = () => {
    const {
      markerImage,
      name,
      comment
    } = formData.value;

    return (
      markerImage &&
      name
    );
  };
  const [mylocation, setMyLocation] = useState(null);

  const defaultProps = {
    getOptionLabel: option => option.name
  };

  function onSaveMarker() {
    if (formData.value.useMyLocation && mylocation) {
      const marker = {
        name: formData.value.name,
        subType: formData.value.markerImage.id,
        comment: formData.value.comment,
        geometry: mylocation,
        useMyLocation: formData.value.useMyLocation,
      }
      props.onSaveMarker(marker);

    } else {
      if (isFormValid()) {
        const marker = {
          name: formData.value.name,
          subType: formData.value.markerImage.id,
          comment: formData.value.comment,
        }
        props.onSaveMarker(marker);
      }
    }
    resetForm();

  }

  function handleLocationClick({ value }) {
    let checked = formData.value.useMyLocation;
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        useMap: value === 'useMap' ? true : false,
        useMyLocation: !checked
      }
    };
    setFormData(updatedFormData);
  }

  function onCancel() {
    resetForm();
    props.onMarkerCancel()
  }

  return (
    <>

      <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("markerActions.addMarker")}</DialogTitle>
        <DialogContent >
          <Box p={2} pt={4} style={{ paddingTop: "0px" }}>
            <Grid container>
              <Grid item xs={12} className={styles.formControl}>

                <FormControl fullWidth>
                  <Autocomplete
                    {...defaultProps}
                    options={props.markerImageOptions}
                    value={formData.value.markerImage}
                    onChange={(event, newValue) => {
                      onChange({ name: 'markerImage', value: newValue });
                    }}
                    renderOption={option => (
                      <>
                        <Avatar alt="Remy Sharp" src={option.image} />
                        {option.name}
                      </>
                    )}
                    renderInput={params => (
                      <TextField
                        disabled={true}
                        autoComplete='off'
                        onKeyUp={(e) => {
                          e.preventDefault();
                          if (e.keyCode === 13) {
                            // Cancel the default action, if needed
                            e.preventDefault();
                            // Trigger the button element with a click

                            document.getElementById("doctitle").focus();
                          }
                        }}
                        {...params}
                        variant='outlined'
                        label={t("markerActions.markerSymbols")}
                        margin='normal'
                        fullWidth
                      />
                    )}
                  />

                </FormControl>

              </Grid>
              <Grid item xs={12} className={styles.formControl}>
                <TextField
                  onChange={(event, newValue) => {
                    newValue = event.target.value;
                    onChange({ name: 'name', value: newValue });
                  }}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13) {
                      // Cancel the default action, if needed
                      e.preventDefault();
                      // Trigger the button element with a click
                      document.getElementById("doccomment").focus();
                    }
                  }}
                  id="doctitle"
                  value={formData.value.name}
                  label={t("markerActions.title")}
                  variant='outlined'
                  fullWidth

                  type='text'
                  required
                />
              </Grid>
              <Grid item xs={12} className={styles.formControl}>
                <TextField
                  onChange={(event, newValue) => {
                    newValue = event.target.value;
                    onChange({ name: 'comment', value: newValue });
                  }}
                  value={formData.value.comment}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13) {
                      // Cancel the default action, if needed
                      e.preventDefault();
                      // Trigger the button element with a click
                      let item = document.getElementById("doccheckloc")
                      if (item)
                        document.getElementById("doccheckloc").focus();
                    }
                  }}
                  id="doccomment"
                  label={t("markerActions.comment")}
                  variant='outlined'
                  fullWidth
                  type='text'

                />
              </Grid>
              {isFormValid() &&
                <Grid item>
                  <FormControlLabel style={{ paddingLeft: "8px" }}
                    control={
                      <Checkbox
                        key={1}
                        checked={formData.value.useMyLocation}
                        onChange={() => handleLocationClick({ value: 'useMyLocation' })}
                        value={formData.value.useMyLocation}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'use my location' }}
                        disabled={!mylocation}
                        id="doccheckloc"
                      />
                    }
                    label={t("markerActions.useMyLocation")}
                  />

                </Grid>
              }
            </Grid>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancelbtn" onClick={onCancel} size='large' variant='outlined'>
            {t("markerActions.cancel")}
          </Button>
          <Button
            onClick={onSaveMarker}
            size='large'
            variant='contained'
            color='primary'
            disabled={!isFormValid()}
            className={styles.noWrap}
          >
            {formData.value.useMyLocation && mylocation ? t("markerActions.saveMarker") : t("markerActions.chooseLocation")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* {alert&&<AppDialog 
      {...alert}
      handleClose = {handleClose}
      handleAlertOk ={handleAlertOk}
      />} */}
    </>
  );
}


export default AreaMapMarker;
