import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 112px)'
  },
  formControl: {
    marginBottom: theme.spacing(1)
  },
  cardActionBox: {
    width: '100%'
  },
  actionButtons: {
    marginTop: theme.spacing(1)
  }
}));

export default useStyles;
