import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Fog(props) {
    return (
        <SvgIcon { ...props } viewBox="0 0 148 149">
            <g id="SketchAppSources.com" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-949.000000, -1138.000000)">
                    <g id="Group-6" transform="translate(948.000000, 1137.000000)">
                        <g id="icon" transform="translate(1.000000, 1.000000)">
                            <g id="cloud-/-normal" fill="#d0caca">
                                <path d="M89.2274192,90.9333328 L31.9495494,90.9333328 C14.3043005,90.9333328 0,76.6290323 0,58.9837835 C0,41.3385346 14.3043005,27.0342341 31.9495494,27.0342341 C37.2743281,27.0342341 42.2948661,28.3368434 46.7098872,30.6407859 C52.857462,12.8096503 69.7850559,0 89.704504,0 C114.815051,0 135.17117,20.3561199 135.17117,45.4666664 C135.17117,45.9813103 135.16262,46.4939572 135.145653,47.0044726 C142.475697,50.9425032 147.459459,58.6820342 147.459459,67.5855852 C147.459459,80.4801902 137.006316,90.9333328 124.111711,90.9333328 L89.704504,90.9333328 C89.6327307,90.9333195 89.2274192,90.9333328 89.2274192,90.9333328 L89.704504,90.9333328 L89.2274192,90.9333328 Z" id="Oval-4"></path>
                            </g>
                            <g id="fog" transform="translate(20.240000, 102.959999)" fill="#000000" fillOpacity="0.4">
                                <path d="M106.036348,36.6666665 C108.225412,36.6666665 109.999999,38.4474778 109.999999,40.6290294 L109.999999,41.5043034 C109.999999,43.6926561 108.227608,45.4666664 106.036348,45.4666664 L3.96365175,45.4666664 C1.77458733,45.4666664 0,43.6858551 0,41.5043034 L0,40.6290294 C0,38.4406768 1.77239106,36.6666665 3.96365175,36.6666665 L106.036348,36.6666665 Z M106.036348,18.4799999 C108.225412,18.4799999 109.999999,20.2608112 109.999999,22.4423629 L109.999999,23.3176369 C109.999999,25.5059895 108.227608,27.2799999 106.036348,27.2799999 L3.96365175,27.2799999 C1.77458733,27.2799999 0,25.4991886 0,23.3176369 L0,22.4423629 C0,20.2540102 1.77239106,18.4799999 3.96365175,18.4799999 L106.036348,18.4799999 Z M106.036348,0.293333332 C108.225412,0.293333332 109.999999,2.07414463 109.999999,4.2556963 L109.999999,5.13097031 C109.999999,7.31932295 108.227608,9.09333328 106.036348,9.09333328 L3.96365175,9.09333328 C1.77458733,9.09333328 0,7.31252198 0,5.13097031 L0,4.2556963 C0,2.06734366 1.77239106,0.293333332 3.96365175,0.293333332 L106.036348,0.293333332 Z"></path>
                            </g>
                        </g>
                        <rect id="Rectangle-Copy-7" x="0" y="0" width="150" height="150"></rect>
                    </g>
                </g>
            </g>
        </SvgIcon >
    );
}

export default Fog;
