import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import useStyle from './area-map-style';
import { useTranslation } from 'react-i18next';

export default function DownloadAlert(props) {
    const styles = useStyle();
    const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("downloadAlert.alertHeading")}</DialogTitle>
        <DialogContent>
            {!props.isOnline?<DialogContentText data-testid="dialog-description" id="alert-dialog-description">
          {t("downloadAlert.offlineMessage")}
          </DialogContentText> : null}
         {props.isOnline? <>
         {!props.downloadMapMessage? <DialogContentText data-testid="dialog-description" id="alert-dialog-description">
          {t("downloadAlert.alertMessage")}
          </DialogContentText> : null}
          {
               props.pCompleted>0 &&  <div className={styles.pbar}>
              <span>{props.downloadMapMessage}</span>
              <LinearProgress variant="buffer"  value={props.pCompleted} valueBuffer={props.buffer} />
                </div>
            }
                </> : null}
        </DialogContent>
        <DialogActions>
          <Button disabled={props.pCompleted>0 || !props.isOnline} onClick={props.handleDownloadAlertStart} color="primary">
          {t("downloadAlert.download")}
          </Button>
          <Button onClick={props.handleDownloadAlertCancel} color="primary" autoFocus>
          {props.isDownloadCompeleted?t("downloadAlert.close"):t("downloadAlert.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
