import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  searchField: {
    width: '100%'
  },
  areasItem: {
    marginTop: theme.spacing(2)
  },
  media: {
    height: 180
  }
}));

export default useStyles;
