import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 112px)'
  },
  card: {
    position: 'relative'
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0
  },
  cardActionBox: {
    width: '100%'
  },
  actionButton: {
    marginLeft: theme.spacing(1)
  },
  orgIdLabel: {
    position: 'absolute',
    top: theme.spacing(2),
    right: 0,
    minWidth: 48,
    background: theme.palette.primary.main,
    color: '#ffffff',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1)
  }
}));

export default useStyles;
