import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WeatherInfo from 'app/hunting-area/weather-info/weather-info';
import { can, permissions as permissionsType } from 'app/shared/services/app-permissions/app-permissions';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import useOnline from 'app/shared/hooks/useOnline';
import Compress from 'compress.js';
import { useDispatch } from 'react-redux';
import HuntingPlan from '../hunting-plan/hunting-plan';
import { requestChangePicture } from '../shared/state/hunting-area';
import useStyle from './area-item-style';

export const AreaItem = (props) => {
  const { area, setLoading, isLoading, callDarkSky, setWeatherProps, lang, hasOfflineRecords, skipFWCalculation } =
    props;
  const classes = useStyle();
  const { t } = useTranslation();
  const { name, picture, weather, description, specialist, permissions, id, isHunter, huntingStatistics } = area;
  const areaTitle = can(permissionsType.huntingArea.grantAccessToHunters, permissions)
    ? `${area.name} (${area.organisationUnit} - ${area.forestDistrict})`
    : `${area.name}`;
  const [uploadPic, setuploadPic] = useState(null);
  const dispatch = useDispatch();
  const { history } = props;
  function navigateToMap() {
    history.push(`/hunting-area/area-map/${area.id}`);
  }

  function navigateToHuntRecordForm() {
    history.push(`/hunting-area/${area.id}/records/new?backTo=list`);
  }

  function navigateToPlan(animalTypeId) {
    localStorage.setItem('isHunterView', JSON.stringify(area.isHunter));
    if (can(permissionsType.huntingArea.viewStatistics, permissions)) {
      history.push(`/hunting-area/${area.id}/plan` + (animalTypeId ? `?animalTypeId=${animalTypeId}` : ''));
    }
  }

  function navigateToShoot() {
    localStorage.setItem('isHunterView', JSON.stringify(area.isHunter));
    if (can(permissionsType.huntingArea.viewStatistics, permissions)) {
      history.push(`/hunting-area/${area.id}/shooting-list`);
    }
  }

  function navigateToUsers() {
    history.push(`/hunting-area/${area.id}/active-users`);
  }

  function navigateToReserations() {
    history.push(`/hunting-area/${area.id}/reservations`);
  }

  const handleAddPhotoClick = (e) => {
    uploadPic.click();
  };

  function handleFileUpload(e) {
    e.preventDefault();
    var _URL = window.URL || window.webkitURL;
    let _file = e.target.files[0];

    if (!_file) {
      return;
    }
    compress([_file]);
  }

  function compress(files) {
    const compress = new Compress();
    compress
      .compress(files, {
        size: 4,
        quality: 0.75,
        maxWidth: 800,
        maxHeight: 800,
        resize: true,
      })
      .then((modFiles) => {
        let uploadableFiles = [];

        for (var i = modFiles.length - 1; i >= 0; i--) {
          let file = Compress.convertBase64ToFile(modFiles[i].data, modFiles[i].ext);
          let filename = Date.now() + modFiles[i].alt;
          let filetype = modFiles[i].ext;
          let filelastMod = files[i].lastModified;
          uploadableFiles.push(
            new File([file], filename, {
              type: filetype,
              lastModified: filelastMod,
            })
          );
        }

        updatePicture(uploadableFiles[0]);
      });
  }

  async function updatePicture(file) {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    setLoading(true);
    const pic = 'data:image/png;base64' + (await toBase64(file)).split(';base64')[1];
    dispatch(
      requestChangePicture({
        Picture: pic,
        HuntingAreaId: id,
        IsMasterPicture: isHunter ? false : true,
      })
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isOnline } = useOnline();

  return (
    <Card className={classes.areasItem}>
      <div>
        <div className={classes.mediaSection}>
          {picture ? (
            <CardMedia className={classes.media} image={picture} title='Hunting area image' />
          ) : (
            <div style={{ height: '180px' }}></div>
          )}
          {can(permissionsType.huntingArea.changePicture, permissions) && (
            <Fab
              variant='extended'
              size='small'
              color='primary'
              aria-label='add'
              className={classes.picChange}
              onClick={handleAddPhotoClick}
            >
              <CameraAltIcon />
            </Fab>
          )}
          <input
            id='hunterAreaPic'
            type='file'
            onChange={handleFileUpload}
            name='hunterAreaPic'
            accept='image/*'
            ref={(ref) => setuploadPic(ref)}
            style={{ display: 'none' }}
          />
        </div>
        <CardContent className={classes.cardContent}>
          <Typography variant='h5' component='h3'>
            {areaTitle}
          </Typography>
          {!isOnline && hasOfflineRecords && (
            <Typography
              variant='body2'
              component='p'
              gutterBottom
              style={{ display: 'flex', padding: '10px 10px 0 0' }}
            >
              <CloudOffIcon color='primary' style={{ 'margin-right': '10px' }} />
              {t('huntingArea.offlineSyncWarning')}
            </Typography>
          )}

          {huntingStatistics && can(permissionsType.huntingArea.viewAmimalActualTargetInfo, permissions) && (
            <HuntingPlan
              huntingStatistics={huntingStatistics}
              skipFWCalculation={skipFWCalculation}
              isOOE={area.county === 'OÖ'}
              gotoPlan={navigateToPlan}
            />
          )}
          {weather &&
            huntingStatistics &&
            can(permissionsType.huntingArea.viewWeatherInfo, permissions) &&
            can(permissionsType.huntingArea.viewAmimalActualTargetInfo, permissions) && (
              <hr className={classes.separator} />
            )}
          {weather && can(permissionsType.huntingArea.viewWeatherInfo, permissions) && (
            <WeatherInfo
              lang={lang}
              callDarkSky={callDarkSky}
              name={name}
              setWeatherProps={setWeatherProps}
              weather={weather}
            />
          )}
          <div
            className={classes.shadowbox}
            style={{ display: 'flex', flexDirection: 'column' }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </CardContent>
      </div>
      <CardActions className={classes.cardActions}>
        {can(permissionsType.huntingArea.accessToMap, permissions) && (
          <div>
            <Button onClick={navigateToMap} size='small' color='primary'>
              {t('huntingArea.viewMap')}
            </Button>
          </div>
        )}
        {huntingStatistics && can(permissionsType.huntingArea.addHuntRecord, permissions) && (
          <Button onClick={navigateToHuntRecordForm} size='small' color='primary'>
            {t('huntRecord.addHuntRecord')}
          </Button>
        )}
        {can(permissionsType.huntingArea.seeActiveUsers, permissions) ||
        (huntingStatistics && can(permissionsType.huntingArea.viewAmimalActualTargetInfo, permissions)) ? (
          <IconButton
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
            aria-label='more menus'
            className={classes.moreActions}
          >
            <MoreVertIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-controls='simple-menu'
            aria-haspopup='true'
            aria-label='more menus'
            className={classes.moreActions}
          ></IconButton>
        )}
        {(can(permissionsType.huntingArea.seeActiveUsers, permissions) ||
          (huntingStatistics && can(permissionsType.huntingArea.viewAmimalActualTargetInfo, permissions))) && (
          <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {can(permissionsType.huntingArea.seeActiveUsers, permissions) && (
              <MenuItem onClick={handleClose}>
                <Button onClick={navigateToUsers} size='small' color='primary'>
                  {t('huntingArea.activeUsers')}
                </Button>
              </MenuItem>
            )}
            {can(permissionsType.huntingArea.viewAmimalActualTargetInfo, permissions) && (
              <MenuItem onClick={handleClose}>
                <Button onClick={() => navigateToPlan()} size='small' color='primary'>
                  {t('huntingArea.huntingStatistics')}
                </Button>
              </MenuItem>
            )}
            {can(permissionsType.huntingArea.viewAmimalActualTargetInfo, permissions) && (
              <MenuItem onClick={handleClose}>
                <Button onClick={navigateToShoot} size='small' color='primary'>
                  {t('textBoxLabel.shootingList')}
                </Button>
              </MenuItem>
            )}
            {can(permissionsType.huntingArea.viewReservations, permissions) && (
              <MenuItem onClick={handleClose}>
                <Button onClick={navigateToReserations} size='small' color='primary'>
                  {t('reservation.reservations')}
                </Button>
              </MenuItem>
            )}
          </Menu>
        )}
      </CardActions>
    </Card>
  );
};

export default withRouter(AreaItem);
