import { reflectKeys } from 'app/shared/services';

/** Prefix for state */
const namespacedPrefix = '[APP_PERMISSIONS]';

/**
 * Action types
 */
export const actionTypes = reflectKeys(
  [
    'APP_PERMISSIONS_DATA_SUCCESS',
    'APP_PERMISSIONS_DATA_REQUEST',
    'APP_PERMISSIONS_DATA_ERROR',
    'APP_PERMISSIONS_DATA_RESET',
    'FETCH_APP_PERMISSIONS_DATA'
  ],
  namespacedPrefix
);

export const requestAppPermissionsAction = () => ({
  type: actionTypes.APP_PERMISSIONS_DATA_REQUEST
});

export const receivedAppPermissionsAction = payload => ({
  type: actionTypes.APP_PERMISSIONS_DATA_SUCCESS,
  payload
});

export const errorAppPermissionsAction = error => ({
  type: actionTypes.APP_PERMISSIONS_DATA_ERROR,
  payload: error
});

export const resetAppPermissionsAction = () => ({
  type: actionTypes.APP_PERMISSIONS_DATA_RESET
});

export const fetchAppPermissionsDataAction = () => {
  return {
    type: actionTypes.FETCH_APP_PERMISSIONS_DATA
  };
};
