import React, { Suspense, useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { useTranslation } from 'react-i18next';
import AppTitle from 'app/shared/components/app-title/app-title';
import * as auth from '../app/shared/services/app-auth/app-auth';

// adding exception handler
import { initExceptionHandler } from './shared/services/exception-handler/exception-handler';
import { doAuthentication } from './shared/services/app-auth/app-auth';
import AppLoader from './shared/components/app-loader/app-loader';
import store from './app-state';
import AppRoutes from './app-routes';
import LockedUser from './shared/components/locked-user/';

import { receivedAppPermissionsAction } from './shared/state/app-permissions';
import { fetchAppPermissions } from './shared/services/app-permissions/app-permissions';
import { appNetwork } from './shared/services/app-network/app-network';
import { receivedAuthDetailsAction, updateUserInfo } from './shared/state/auth-details';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { getDeferredPrompt, getUserChoice, setDeferredPrompt } from 'app/shared/services';
import executeOfflineActions from './offlineActions';
import './app.css';
import { executeRequests } from './shared/state/offline-requests';

/** Initialize the exception handler */
initExceptionHandler();

function App() {
  const [authData, setAuthData] = useState(null);
  const [permissionData, setPermissionData] = useState(null);
  const [offlinePopup, setOfflinePopup] = useState(false);
  const [popup500, setPopup500] = useState(false);
  const [addToHomePopup, setAddToHomePopup] = useState(getDeferredPrompt() ? true : false);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(window.isUpdateAvailable ? true : false);
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState('de');
  let msalInstance = null;

  useEffect(() => {
    appNetwork.onOnline(() => {
      setOfflinePopup(true);
      store.dispatch(executeRequests());
    });
  }, []);
  const initializeAuth = async () => {
    const data = await doAuthentication();
    if (data) {
      const payload = { ...data };
      delete payload.token;
      delete payload.user;
      store.dispatch(receivedAuthDetailsAction(payload));
      setAuthData(data);
      store.dispatch(executeRequests());
      const { response, error } = await fetchAppPermissions();
      if (error) {
        setPopup500(true);
      }
      if (response && response.userInfo && response.userInfo.isLocked) {
        setIsAccountLocked(true);
      } else {
        setIsAccountLocked(false);
      }
      response && store.dispatch(updateUserInfo(response.userInfo));
      response && store.dispatch(receivedAppPermissionsAction(response.permissionData));
      setPermissionData(response);
    }
  };

  const getCurrentChoice = async () => {
    let choice = await getUserChoice();
    return choice;
  };
  let timeout = null;
  useEffect(() => {
    setLocale(i18n.language.substr(0, 2).toLowerCase());
    initializeAuth().catch((e) => {
      console.log(e);
    });
    appNetwork.onOnline(() => {
      executeOfflineActions();
      setOfflinePopup(false);
    });

    appNetwork.onOffline(() => {
      setOfflinePopup(true);
    });

    timeout = setTimeout(() => {
      getCurrentChoice().then((res) => {
        setAddToHomePopup(!res);
      });
    }, 1000);
  }, []);

  const addToHome = () => {
    let deferredPrompt = getDeferredPrompt();

    if (deferredPrompt) {
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setAddToHomePopup(false);
          setDeferredPrompt(null);
        }
        deferredPrompt = null;
      });
    }
  };

  function refreshPage() {
    window.isUpdateAvailable = false;
    window.location.reload();
  }
  const localeMap = {
    en: enLocale,
    de: deLocale,
  };

  const loadApp = authData && permissionData;
  return (
    <Suspense fallback='Please wait'>
      <Snackbar {...(popup500 ? 'style="display:none"' : '')} open={popup500} onClose={() => setPopup500(false)}>
        <SnackbarContent
          className='popup-content'
          message={t('general.networkError')}
          action={
            <>
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={() => {
                  setPopup500(false);
                }}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </>
          }
        />
      </Snackbar>
      <AppTitle />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
        <Provider store={store}>
          {loadApp && (
            <>
              <Router>
                {isAccountLocked ? <Route path='/' component={LockedUser} /> : <Route path='/' component={AppRoutes} />}
              </Router>
              <Snackbar
                open={offlinePopup}
                autoHideDuration={3000}
                message='You are offline'
                onClose={() => setOfflinePopup(false)}
              />
              <Snackbar open={addToHomePopup} autoHideDuration={5000} onClose={() => setAddToHomePopup(false)}>
                <SnackbarContent
                  className='popup-content'
                  message={t('profile.addToHome')}
                  action={
                    <>
                      <Button className='popup-btn' size='small' onClick={addToHome}>
                        {t('buttonText.add')}
                      </Button>
                      <IconButton
                        size='small'
                        aria-label='close'
                        color='inherit'
                        onClick={() => setAddToHomePopup(false)}
                      >
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    </>
                  }
                />
              </Snackbar>
              <Snackbar open={isUpdateAvailable} autoHideDuration={5000} onClose={() => setIsUpdateAvailable(false)}>
                <SnackbarContent
                  className='popup-content-update'
                  message={t('profile.appRefreshMessage')}
                  action={
                    <>
                      <Button className='popup-btn' size='small' onClick={refreshPage}>
                        {t('buttonText.refresh')}
                      </Button>
                      <IconButton
                        size='small'
                        aria-label='close'
                        color='inherit'
                        onClick={() => setIsUpdateAvailable(false)}
                      >
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    </>
                  }
                />
              </Snackbar>
            </>
          )}
          {!loadApp && <AppLoader />}
        </Provider>
      </MuiPickersUtilsProvider>
    </Suspense>
  );
}

export default App;
