import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';
import { format as formatDate } from 'date-fns';

/** requestType = 0 stands for pending requests */

const fetchAccessRequests = (requestType = 0) => {
  const url = `${API_CONSTANTS.accessRequests}?status=pending`;

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const fetchPendingRequest = (requestType = 0) => {
  const url = `${API_CONSTANTS.accessRequsetsCount}?status=pending`;

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const fetchAccessRequestsById = id => {
  const url = `${API_CONSTANTS.accessRequestsById}`.replace('{id}', id);

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const changeAccessRequestStatus = (requestId, status) => {
  const url = `${API_CONSTANTS.requestAccessStatusChange}?newStatus=${status}`.replace(
    '{requestId}',
    requestId
  );

  return httpClient.patch(url);
};

const createAccess = formData => {

  const url = API_CONSTANTS.createAccess;
  const reqData = createAccessRequestObject(formData);
  return httpClient
    .post(url, reqData)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};
const postHuntingRequest = formData => {
  const url = API_CONSTANTS.createHuntingRequest;
  return httpClient
    .post(url, {
      ...formData,
      organisationUnitId: parseInt(formData.organisationUnitId),
      forestDistrictCode: parseInt(formData.forestDistrictCode)
    })
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const createAccessRequestObject = formData => {
  const {
    accessEndDate,
    accessRole,
    accessStartDate,
    hunterEmailId,
    huntingAreaId,
    requestId = 0
  } = formData;
  return {
    huntingAreaId: huntingAreaId && huntingAreaId.id || 0,
    requestId,
    userId: hunterEmailId,
    roleId: accessRole && accessRole.id || 0,
    validFrom: formatDate(accessStartDate, 'dd/MM/yyyy'),
    validTo: formatDate(accessEndDate, 'dd/MM/yyyy')
  };
};

const fetchOrganizationsWithForestDistrict = () => {
  const url = API_CONSTANTS.organizations + "?retrieveForestDistricts=true&retrieveForestDistrictsFromHuntingAreas=true";
  return httpClient
    .get(url)
    .then(res => ({
      response: res.data
    }))
    .catch(error => ({ error }));
};

// const parseOrgDropdownData = huntingAreas => {
//   const organisationsData = huntingAreas
//     .filter(item => !item.specialist)
//     .reduce((obj, item) => {
//       const items = obj[item['organisationUnit']] || [];
//       obj[item['organisationUnit']] = [...items, { ...item, name: item.name }];
//       return obj;
//     }, {});
//   const organisations = Object.keys(organisationsData).map(key => ({
//     id: key,
//     name: key
//   }));

//   return { organisationsData, organisations };
// };

const fetchAccessRequestRoles = () => {
  const url = `${API_CONSTANTS.huntingAreaAccessRoles}`.replace('{id}', 1);
  return httpClient
    .get(url)
    .then(res => ({
      response: res.data
    }))
    .catch(error => ({ error }));
};

export {
  fetchAccessRequests,
  createAccess,
  fetchAccessRequestRoles,
  fetchAccessRequestsById,
  changeAccessRequestStatus,
  fetchOrganizationsWithForestDistrict,
  postHuntingRequest,
  fetchPendingRequest
};
