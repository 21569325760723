import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
 
function SunLightBoxing(props) {
  return (
    <SvgIcon { ...props } version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1900.000000pt" height="2000.000000pt" viewBox="25 20 40.000000 40.000000"
    preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,93.000000) scale(0.100000,-0.100000)"
fill="#006633"  stroke="none">
<path d="M410 685 c0 -29 -4 -35 -21 -35 -27 0 -89 -62 -89 -89 0 -17 -6 -21
-35 -21 -19 0 -35 -4 -35 -10 0 -5 16 -10 35 -10 27 0 35 -4 35 -19 0 -27 39
-71 77 -87 27 -12 33 -20 33 -44 0 -17 5 -30 10 -30 6 0 10 13 10 30 0 24 6
32 33 44 38 16 77 60 77 87 0 15 8 19 35 19 19 0 35 5 35 10 0 6 -16 10 -35
10 -29 0 -35 4 -35 21 0 12 -15 36 -34 55 -19 19 -43 34 -55 34 -17 0 -21 6
-21 35 0 19 -4 35 -10 35 -5 0 -10 -16 -10 -35z m81 -84 c64 -65 20 -171 -71
-171 -58 0 -100 42 -100 100 0 33 6 48 29 71 23 23 38 29 71 29 33 0 48 -6 71
-29z"/>
<path d="M433 540 c-38 -40 -38 -40 -54 -19 -10 14 -19 18 -23 11 -3 -6 5 -23
19 -39 l26 -28 17 25 c9 14 30 37 45 51 18 16 25 29 18 33 -5 4 -27 -11 -48
-34z"/>
</g>
    </SvgIcon>
  );
}
 
export default SunLightBoxing;