import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
 
function SunLightUnoxing(props) {
  return (
    <SvgIcon { ...props } version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1900.000000pt" height="2000.000000pt" viewBox="25 20 40.000000 40.000000"
    preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,93.000000) scale(0.100000,-0.100000)"
fill="#006633" stroke="none">
<path d="M410 688 c0 -29 -5 -35 -41 -50 -31 -13 -44 -26 -57 -57 -15 -36 -21
-41 -49 -41 -18 0 -33 -4 -33 -10 0 -5 15 -10 33 -10 29 0 35 -5 46 -35 14
-38 54 -75 82 -75 15 0 19 -8 19 -35 0 -19 5 -35 10 -35 6 0 10 14 10 30 0 20
6 32 18 36 42 13 68 35 87 73 16 33 25 41 47 41 16 0 28 5 28 10 0 6 -13 10
-30 10 -23 0 -31 5 -36 25 -8 31 -68 85 -94 85 -16 0 -20 7 -20 35 0 19 -4 35
-10 35 -5 0 -10 -15 -10 -32z m78 -83 c74 -62 33 -175 -64 -175 -52 0 -91 29
-100 75 -18 100 87 165 164 100z"/>
<path d="M370 580 c0 -7 7 -22 17 -32 15 -17 15 -19 0 -36 -28 -30 -20 -49 8
-22 l25 23 25 -23 c14 -13 28 -20 32 -17 3 4 -4 18 -17 32 l-23 25 21 23 c29
31 16 44 -15 15 l-23 -21 -25 23 c-16 15 -25 19 -25 10z"/>
</g>
    </SvgIcon>
  );
}
 
export default SunLightUnoxing;