import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 112px)'
  },
   formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardActionBox: {
    width: '100%'
  },
  actionButtons: {
    marginTop: theme.spacing(1)
  },
  noWrap : {
    'white-space': 'nowrap'
  }
}));

export default useStyles;
