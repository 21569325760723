import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Box
} from '@material-ui/core';

import { changeAccessRequestStatus, fetchPendingRequest } from '../shared/services/access-requests/access-requests';
import { accessRequestStatus } from '../shared/config/access-request-status';

import useStyles from './access-request-item-style';
import { AppButton } from 'app/shared/components';
import { useTranslation } from 'react-i18next';
import store from 'app/app-state';
import { updatePendingRequestCounter } from 'app/shared/state/auth-details';
import { fetchAllAccessRequests } from './../shared/state/access-requests';

const AccessRequestItem = props => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { accessRequest } = props;
  const classes = useStyles();
  const history = props.history;
  const {
    huntingAreaName,
    notes,
    user,
    organisationUnitId,
    forestDistrictCode,
    createdAt,
    id: requestId
  } = accessRequest;
  const userName = `${user.firstName} ${user.lastName}`;

  function approveRequest() {
    history.push(
      `/hunting-area/create-access/${accessRequest.id}`,
      accessRequest
    );
    fetchPendingRequestCount();
  }

  async function rejectRequest() {
    setLoading(true);
    const result = await changeAccessRequestStatus(
      requestId,
      accessRequestStatus.rejected
    ).catch(e => {
      console.log(e);
    });

    setLoading(false);

    if (result) {
      dispatch(fetchAllAccessRequests());
      fetchPendingRequestCount();
    }
  }

  const fetchPendingRequestCount = () => {
    fetchPendingRequest().then(count => {
      if (count)
        store.dispatch(updatePendingRequestCounter(count.response));
      // setCounter(count.response);
    }).catch(ex => {
      console.error(ex)
    })
  }

  return (
    <Box mb={2}>
      <Card className={classes.card}>
        <CardHeader title={userName} subheader={user.email} />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item xs={6}>
              <Typography color='textSecondary' variant='subtitle1'>
                {t('labelInfo.requestedArea')}
              </Typography>
              <Typography variant='body2' gutterBottom>
                {huntingAreaName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color='textSecondary' variant='subtitle1'>
                {t('labelInfo.date')}
              </Typography>
              <Typography variant='body2' gutterBottom>
                {new Date(createdAt).toDateString()}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Typography color='textSecondary' variant='subtitle1'>
              {t('labelInfo.additionalNotes')}
            </Typography>
            <Typography variant='body2' gutterBottom>
              {notes}
            </Typography>
          </div>
        </CardContent>
        <CardActions disableSpacing>
          <Box px={1} className={classes.cardActionBox}>
            <Grid
              container
              spacing={2}
              alignItems='flex-end'
              justify='flex-end'
            >
              <Grid item>
                <AppButton
                  variant='outlined'
                  color='secondary'
                  onClick={rejectRequest}
                  loading={loading}
                >
                  {t('buttonText.reject')}
                </AppButton>
                <AppButton
                  data-testid='approvebtn'
                  className={classes.actionButton}
                  variant='outlined'
                  color='primary'
                  onClick={approveRequest}
                >
                  {t('buttonText.approve')}
                </AppButton>
              </Grid>
            </Grid>
          </Box>
        </CardActions>
        <div className={classes.orgIdLabel}>{organisationUnitId}{forestDistrictCode && `- ${forestDistrictCode < 10 ? '0' : ''}${forestDistrictCode}`}</div>
      </Card>
    </Box>
  );
};

export default withRouter(AccessRequestItem);
