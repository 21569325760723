import { fetchRecord } from 'app/hunting-area/shared/state/hunting-record';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useHuntingRecord(id) {
    const huntingRecords = useSelector(({ huntingArea }) => huntingArea.huntingRecords);
    const dispatch = useDispatch();
    const selectedRecord = huntingRecords.data[id];

    useEffect(() => {
        if (id && (!selectedRecord || !selectedRecord.pictures)) dispatch(fetchRecord({ id }));
    }, [id]);

    return {
        huntingRecord: selectedRecord && selectedRecord.pictures ? selectedRecord : null,
    };
}