class AppNetwork {
  online = navigator.onLine;
  onlineCallback = [];
  offlineCallback = [];

  get isOffline() {
    return !this.online;
  }

  updateStatus(status) {
    this.online = status;
    if (status === true) {
      this.fireOnlineEvents();
    } else {
      this.fireOfflineEvents();
    }
  }

  onOnline(fn) {
    this.onlineCallback.push(fn);
  }

  onOffline(fn) {
    this.offlineCallback.push(fn);
  }

  removeOnlineCallback(fn) {
    this.onlineCallback.splice(this.onlineCallback.indexOf(fn), 1);
  }

  removeOfflineCallback(fn) {
    this.offlineCallback.splice(this.offlineCallback.indexOf(fn), 1);
  }

  fireOnlineEvents() {
    this.onlineCallback.forEach(fn => fn(true));
  }

  fireOfflineEvents() {
    this.offlineCallback.forEach(fn => fn(false));
  }
}

const appNetwork = new AppNetwork();

window.updateOnline = appNetwork.updateStatus.bind(appNetwork);
window.addEventListener('online', () => appNetwork.updateStatus(true));
window.addEventListener('offline', () => appNetwork.updateStatus(false));

export { appNetwork };
