import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useStyle from './hunting-plan-style';
import CardMedia from '@material-ui/core/CardMedia';
import { baseBlobUrl } from 'environement/environment';
import useHuntingArea from 'app/shared/hooks/useHuntingArea';

const HuntingPlan = (props) => {
  const classes = useStyle();
  const { huntingStatistics, gotoPlan, isOOE, skipFWCalculation } = props;
  const basePicUrl = baseBlobUrl + '$web/animal-images/';

  const { huntingRecords, huntingArea } = useHuntingArea(huntingStatistics.huntingAreaId, undefined, skipFWCalculation);
  const [animalFallenCountPerType, setAnimalFallenCountPerType] = useState({});

  useEffect(() => {
    huntingRecords.forEach((entry) => {
      const { animalTypeId, animalCount, animalFallen } = entry;

      const count = ['mähverlust', 'roadRail', 'miscellaneous'].includes(animalFallen) && isOOE ? animalCount : 0;

      if (count > 0) {
        setAnimalFallenCountPerType((prev) => ({
          ...prev,
          [animalTypeId]: isNaN(prev[animalTypeId]) ? count : prev[animalTypeId] + count,
        }));
      }
    });
  }, [huntingStatistics.huntingAreaId]);

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        {huntingStatistics &&
          huntingStatistics.data &&
          huntingStatistics.data.slice(0, 5).map((item, index) => {
            let fallenCountForAnimalType = animalFallenCountPerType[item.animalTypeId]
              ? animalFallenCountPerType[item.animalTypeId]
              : 0;

            return (
              item &&
              item.position > 0 && (
                <Grid item className={classes.infoItem} xs key={index}>
                  <span onClick={() => gotoPlan(item.animalTypeId)}>
                    <CardMedia
                      className={classes.media}
                      image={basePicUrl + item.icon}
                      title={item.name}
                      id={item.animalTypeId}
                    />
                  </span>
                  <Typography variant='caption' display='block' color='textSecondary' style={{ fontWeight: '600' }}>
                    {item.actual - fallenCountForAnimalType} {item.target > -1 && `/` + item.target}
                  </Typography>
                </Grid>
              )
            );
          })}
      </Grid>
    </Box>
  );
};

export default HuntingPlan;
