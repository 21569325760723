import { PublicClientApplication } from '@azure/msal-browser';
import i18n from '../../../../i18n';

import { authConfig } from 'environement/environment';
import { appNetwork } from '../app-network/app-network';
import { appLocalStorage } from '../app-storage/app-storage';
import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';

let authDetails = null;
const baseMsalConfig = {
  auth: {
    clientId: authConfig.clientId,
    authority: `${authConfig.instance}${authConfig.tenant}${authConfig.signInPolicy}`,
    knownAuthorities: [
      `${authConfig.instance}${authConfig.tenant}${authConfig.signInPolicy}`
    ],
    redirectUri: authConfig.redirectUri,
    postLogoutRedirectUri: authConfig.postLogoutRedirectUri,
    validateAuthority: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
}

export const msalInstance = new PublicClientApplication(baseMsalConfig);

const getRequestObj = () => ({
  scopes: authConfig.scopes,
  extraQueryParameters: { ui_locales: i18n.language }
});

async function acquireToken() {
  const user = msalInstance.getActiveAccount();
  const token = await msalInstance
    .acquireTokenSilent(getRequestObj())
    .catch(e => {
      return undefined;
    });

  // if access token is null
  if (!token || !token.accessToken) {
    msalInstance.loginRedirect(getRequestObj());
    return undefined;
  }

  return { token, user };
}

function getAuthData() {
  return authDetails;
}

function getAccessToken() {
  if (authDetails && authDetails.token) {
    return authDetails.token.accessToken;
  }

  return null;
}

function signOut() {
  msalInstance.logout();
}

async function silentReauth() {
  const tokenResult = await acquireToken();
  if (appNetwork.online) {
    if (tokenResult) {
      authDetails = parseAuthData(tokenResult);
      appLocalStorage.setItem('app:authDetails', authDetails);
    }
  }
}

async function doAuthentication() {
  const allAccounts = msalInstance.getAllAccounts();
  if (allAccounts.length > 0) {
    msalInstance.setActiveAccount(allAccounts[0]);
  }
  const redirectResult = await msalInstance.handleRedirectPromise();
  let tokenResult;
  if (redirectResult) {
    msalInstance.setActiveAccount(redirectResult.account);
    tokenResult = { user: redirectResult.account, token: redirectResult };
  } else {
    tokenResult = await acquireToken();
  }
  if (appNetwork.online) {
    if (tokenResult) {
      authDetails = parseAuthData(tokenResult);
      appLocalStorage.setItem('app:authDetails', authDetails);
    }
  } else {
    authDetails = appLocalStorage.getItem('app:authDetails');
  }

  return authDetails;
}

function parseAuthData({ user, ...rest }) {
  const { idTokenClaims } = user;
  const userInfo = {
    firstName: idTokenClaims.given_name,
    lastName: idTokenClaims.family_name,
    emailId: idTokenClaims.emails
  };

  return {
    ...rest,
    user,
    userInfo
  };
}

function changePassword() {
  const authInstance = authConfig.instance
    ? authConfig.instance
    : 'https://login.microsoftonline.com/tfp/';
  const authority = `${authInstance}${authConfig.tenant}${authConfig.resetPolicy}`;
  new PublicClientApplication({
    auth: {
      ...baseMsalConfig.auth,
      authority,
    },
    ...baseMsalConfig,
  }).acquireTokenRedirect({
    ...getRequestObj(),
    authority
  });
}

async function deleteAccount(data) {
  let url = API_CONSTANTS.deleteAccount;
  return httpClient.delete(url + "?userPassword=" + data.userPassword)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const updateUserSettings = (data) => {
  return httpClient
    .post(API_CONSTANTS.updateUserSettings, data)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

export {
  doAuthentication,
  silentReauth,
  getAuthData,
  getAccessToken,
  signOut,
  changePassword,
  deleteAccount,
  updateUserSettings
};
