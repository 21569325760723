import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const ReservationDialog = props => {
    const { t } = useTranslation();
    const { isOpen, onClose, reservations, featureId, editedId, minDate, maxDate, lastTimeFrom, lastTimeTo } = props;

    const date = lastTimeFrom && new Date(lastTimeFrom.getTime());
    if (date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
    }

    const getDateForMinutes = (minutes, to) => {
        const ret = timeDate ? new Date(timeDate.getTime()) : new Date()
        
        if (to && minutes == 24 * 60) minutes = 0;
        ret.setHours(Math.floor(minutes / 60));
        ret.setMinutes(minutes % 60);
        ret.setSeconds(0);
        ret.setMilliseconds(0);
        return ret;
    }

    const getMinutesForDate = (date, to) => {
        if(date && to) {
            if (to && date.getHours() == 0 && date.getMinutes() == 0) return 24 * 60;
            return date.getHours() * 60 + date.getMinutes();
        } 
    }

    const  [timeDate, setTimeDate] = useState(date || new Date());
    const [timeFrom, setTimeFrom] = useState(lastTimeFrom || getDateForMinutes(0));
    const [timeTo, setTimeTo] = useState(lastTimeTo || getDateForMinutes(24 * 60, true));

    useEffect(() => {
        if (isOpen) {
            setTimeFrom(lastTimeFrom || getDateForMinutes(0));
            setTimeTo(lastTimeTo || getDateForMinutes(24 * 60, true));
            setTimeDate(date || new Date());
        }
    }, [isOpen]);


    const timeFromMinutes = getMinutesForDate(timeFrom);
    const timeToMinutes = getMinutesForDate(timeTo, true);

    const isTimeFrameValid = (from, to) => {
        if (!reservations) return true;
        try {
            const possibleCollisions = reservations.filter(r => r.timeFrom.toISOString().split('T')[0] === timeDate.toISOString().split('T')[0]);
            return !possibleCollisions.some(r => getMinutesForDate(r.timeFrom) < to && from < getMinutesForDate(r.timeTo));
        } catch {
            return false;
        }
    }

    const validationError = timeFromMinutes >= timeToMinutes
        ? t('general.fromBeforeToError')
        : !isTimeFrameValid(timeFromMinutes, timeToMinutes) ? t('reservation.timeFrameNotAvailable') : null;

    const [formIsValid, setFormIsValid] = useState(true);

    const handleDatePickerChange = (event) => {
        setTimeDate(event);
        setFormIsValid(true);
    }

    const submit = () => {
        if(timeDate && timeTo && timeFrom) {
            timeFrom.setFullYear(timeDate.getFullYear());
            timeFrom.setMonth(timeDate.getMonth());
            timeFrom.setDate(timeDate.getDate());
            timeTo.setFullYear(timeDate.getFullYear());
            timeTo.setMonth(timeDate.getMonth());
            if (timeTo.getHours() == 0) {
                timeTo.setDate(timeDate.getDate() + 1);
            } else {
                timeTo.setDate(timeDate.getDate());
            }
    
            onClose({ timeFrom: timeFrom.toISOString(), timeTo: timeTo.toISOString(), featureId, editedId });
        } else {
            setFormIsValid(false);
        }
    }

    return (
        <Dialog open={isOpen}>
            <DialogContent style={{ padding: "0 0" }}>
                <Box p={2} pt={4} style={{ paddingTop: "0px", marginTop: "12px" }}>
                    <Grid container style={{ marginTop: '12px' }}>
                        {<KeyboardDatePicker
                            fullWidth
                            inputVariant='outlined'
                            margin='normal'
                            id='date-picker-dialog'
                            label={t('labelInfo.date')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            okLabel={t("buttonText.ok")}
                            cancelLabel={t("buttonText.cancel")}
                            onChange={handleDatePickerChange}
                            value={timeDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            format='dd/MM/yyyy'
                        />}
                    </Grid>
                    <Grid container style={{ marginTop: '12px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="fromPicker"
                                    label={t('general.From')}
                                    value={timeFrom}
                                    onChange={setTimeFrom}
                                    ampm={false}
                                    minutesStep={5}
                                    okLabel={t("buttonText.ok")}
                                    cancelLabel={t("buttonText.cancel")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="toPicker"
                                    label={t('general.To')}
                                    value={timeTo}
                                    onChange={setTimeTo}
                                    ampm={false}
                                    minutesStep={5}
                                    okLabel={t("buttonText.ok")}
                                    cancelLabel={t("buttonText.cancel")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography style={{ visibility: !validationError ? 'hidden' : 'visible' }} color='error' variant='body2'>{validationError || 'NO ERROR'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Box mr={1}>
                            <Button color='primary' variant='outlined' disabled={!isTimeFrameValid(4 * 60, 12 * 60)} onClick={() => {
                                setTimeFrom(getDateForMinutes(4 * 60));
                                setTimeTo(getDateForMinutes(12 * 60));
                            }}>04:00-12:00</Button>
                        </Box>
                        <Box>
                            <Button color='primary' variant='outlined' disabled={!isTimeFrameValid(12 * 60, 24 * 60)} onClick={() => {
                                setTimeFrom(getDateForMinutes(12 * 60));
                                setTimeTo(getDateForMinutes(24 * 60));
                            }}>12:00-00:00</Button>
                        </Box>
                    </Grid>
                </Box>
            </DialogContent>
            <Typography style={{ visibility: formIsValid ? 'hidden' : 'visible' , marginLeft: '1rem'}} color='error'>{t('reservation.dateFieldError')}</Typography>
            <DialogActions>

                <Button data-testid="cancelbtn" onClick={() => onClose()} size='small' variant='outlined'>
                    {t('buttonText.cancel')}
                </Button>
                <Button
                    disabled={!!validationError}
                    onClick={() => submit()}
                    size='small'
                    variant='contained'
                    color='primary'
                >
                    {editedId ? t('buttonText.edit') : t('reservation.makeReservation')}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default ReservationDialog;
