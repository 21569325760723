import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loaderContainer: {
    width: 250,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  loaderLabel: {
    marginBottom: theme.spacing(2)
  },
  appLogo: {
    width: 250,
    marginBottom: theme.spacing(4)
  }
}));
