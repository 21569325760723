import React from 'react';
import AppNavbar from '../app-navbar/app-navbar';
import AppFooter from '../app-footer/app-footer';
import LinearProgress from '@material-ui/core/LinearProgress';

const AppPage = props => {
  const { loading, style, beforeRedirect } = props;
  return (
    <div  style={style}>
      <AppNavbar {...props}>
        {/* {loading && <LinearProgress />} */}
        {props.children}
      </AppNavbar>
      <AppFooter beforeRedirect = {beforeRedirect}/>
    </div>
  );
};

export default AppPage;
