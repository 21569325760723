import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Home from '@material-ui/icons/Home';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Paper from '@material-ui/core/Paper';
import { fetchPendingRequest } from 'app/hunting-area/shared/services/access-requests/access-requests';
import store from 'app/app-state';
import {
  updatePendingRequestCounter
} from '../../../shared/state/auth-details';

import {
  can,
  permissions
} from '../../services/app-permissions/app-permissions';

import useStyles from './app-footer-style';

const AppFooter = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigateTo = path => {
    props.history.push(path);
  };

  useEffect(() => {
    setActiveRoute();
    fetchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRequest = () => {
    if (can(permissions.viewRequests))
      fetchPendingRequest().then(count => {
        if (count)
          store.dispatch(updatePendingRequestCounter(count.response));
        // setCounter(count.response);
      }).catch(ex => {
        console.error(ex)
      })
  }

  const setActiveRoute = () => {
    const { match } = props;
    let value = null;
    switch (true) {
      case match.path.startsWith('/hunting-area/create-access/:id?'):
        value = 'permissions';
        break;
      case match.path.startsWith('/hunting-area/area-access-requests'):
        value = 'requestAccess';
        break;
      case match.path.startsWith('/hunting-area/request-hunting-access'):
        value = 'requestHunting';
        break;
      case match.path.startsWith('/profile'):
        value = 'profile';
        break;
      case match.path.startsWith('/hunting-area'):
        value = 'home';
        break;
      default:
    }
    setValue(value);
  };

  const redirectTo = (url) => {
    props.beforeRedirect
      ? props.beforeRedirect(() => { navigateTo(url) })
      : navigateTo(url)
  }

  return (
    <Paper className={classes.root} elevation={2}>
      <BottomNavigation value={value} onChange={handleChange} showLabels={true}>
        {can(permissions.huntingAreaView) && (
          <BottomNavigationAction
            label={t('footer.home')}
            value='home'
            icon={<Home />}
            onClick={() => { redirectTo('/hunting-area/overview') }}
          />
        )}
        {!can(permissions.viewRequests) && (
          <BottomNavigationAction
            label={t('footer.requestAccess')}
            value='requestHunting'
            icon={<LowPriorityIcon />}
            onClick={() => redirectTo('/hunting-area/request-hunting-access')}
          />
        )}
        {can(permissions.viewRequests) && (
          <BottomNavigationAction
            label={t('footer.pendingRequests')}
            value='requestAccess'
            icon={<Badge badgeContent={props.pendingRequestCount || 0} color="secondary">
              <LowPriorityIcon />
            </Badge>}
            onClick={() => redirectTo('/hunting-area/area-access-requests')}
          />
        )}
        {can(permissions.grantPermissions) && (
          <BottomNavigationAction
            label={t('footer.permissions')}
            value='permissions'
            icon={<PlaylistAddIcon />}
            onClick={() => redirectTo('/hunting-area/create-access')}
          />
        )}
        <BottomNavigationAction
          label={t('footer.profile')}
          value='profile'
          icon={<PersonRoundedIcon />}
          onClick={() => redirectTo('/profile')}
        />
      </BottomNavigation>
    </Paper>
  );
};

const mapStateToProps = state => ({
  pendingRequestCount: state.global.authDetails.pendingRequestCount,
});

export default withRouter(connect(mapStateToProps, {})(AppFooter));
