import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import LZString from 'lz-string';

import huntingArea from 'app/hunting-area/hunting-area-state';
import globalState from 'app/shared/state/shared-state';
import { appPermissionsSaga } from 'app/shared/state/app-permissions';
import appInfo from 'app/app-version';
import { msalInstance } from 'app/shared/services/app-auth/app-auth';
import { cleanupAreas } from './hunting-area/shared/state/hunting-area';

const sagaMiddleware = createSagaMiddleware();

const CLEAR_STORE = 'CLEAR_STORE';
const appReducer = combineReducers({ huntingArea, global: globalState });

const rootReducer = (state, action) => {   
  if(action.type === CLEAR_STORE)
     state = {
      ...state,
      huntingArea: undefined,
     };
  
  return appReducer(state, action);
};

function loadState() {
  const accountId = localStorage.getItem('app:authDetails') && JSON.parse(localStorage.getItem('app:authDetails')).user.homeAccountId;
  
  // NOTE: Remove the previous non compressed local storage
  localStorage.removeItem(`state.${accountId}`);

  const stateStr = accountId && localStorage.getItem(`compressed-state.${accountId}`);
  if (stateStr) {
    const decompressed = LZString.decompressFromBase64(stateStr);
    const state = JSON.parse(decompressed);
    // NOTE: You can use the version in the local storage here to do migrations
    delete state.version;
    return state;
  }

  return undefined;
}

export function clearLocalState() {
  const accountId = localStorage.getItem('app:authDetails') && JSON.parse(localStorage.getItem('app:authDetails')).user.homeAccountId;
  if (accountId) localStorage.removeItem(`compressed-state.${accountId}`);
  store.dispatch({ type: CLEAR_STORE });
}

const store = configureStore({
  preloadedState: loadState(),
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware, subscriberMiddleware),
});

function saveState(state) {
  const lsKey = `compressed-state.${msalInstance.getActiveAccount().homeAccountId}`;
  for (const key of Object.keys(localStorage).filter(k => k.startsWith("compressed-state.") && k !== lsKey)) {
    localStorage.removeItem(key);
  }

  try {
    const stateStr = JSON.stringify({ version: appInfo.app.version, ...state });
    const compressed = LZString.compressToBase64(stateStr);
    localStorage.setItem(lsKey, compressed);
  }
  catch (err) {
    // NOTE: This should mostly not happen but we have to opt out of the quota stuff if somehow the quota gets exceeded even after all possible cleanup
    if (state.huntingArea.huntingAreaData.skipQuota) {
      return;
    }
    store.dispatch(cleanupAreas());
  }
}

function subscriberMiddleware() {
  return next => action => {
    store.actions.emit(action);
    const returnValue = next(action);
    return returnValue;
  }
}

function* rootSaga() {
  yield spawn(appPermissionsSaga);
}

sagaMiddleware.run(rootSaga);
store.actions = {
  subscriber: null,
  emit: function (action) {
    if (this.subscriber) this.subscriber(action)
  },
  subscribe: function (subscriber) {
    this.subscriber = subscriber;
  },
}
store.subscribe(() => saveState(store.getState()));

export default store;
