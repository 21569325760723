import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StatisticsTree from './hunt-statistics';
import { AppPage } from 'app/shared/components';
import useStyles from './hunting-plan-style';
import FilterPopup, { planFilterOptions } from './filter-popup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { UpIcon, DownIcon, FilterIcon } from 'app/shared/components/';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import useHuntingArea from 'app/shared/hooks/useHuntingArea';
import useOfflineRequests from 'app/shared/hooks/useOfflineRequests';
import useQuery, { additionalQuery, pickQueryOptions } from 'app/shared/hooks/useQuery';
import { fromDateString } from 'app/shared/dateMethods';
import { fetchHuntingPlan } from '../shared/state/hunting-area';
import { can, permissions } from '../../shared/services/app-permissions/app-permissions';
import { useHuntingPlanWithDate, useHuntingPlanWithYear } from './../../shared/hooks/useHuntingPlan';

const HuntingPlan = (props) => {
  const { t } = useTranslation();
  const { match, history } = props;
  const { params } = match;
  const dispatch = useDispatch();
  const { huntingArea, huntingRecords } = useHuntingArea(params.id);
  const { offlineRequests } = useOfflineRequests();
  const offlineRecords = offlineRequests.filter((r) => r.type.startsWith('huntingRecord')).map((r) => r.payload);
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const planQueryBack = pickQueryOptions(query, planFilterOptions);
  function onBack() {
    props.history.push(`/hunting-area/overview`);
  }
  function navigateToHuntRecordForm(recordId) {
    history.push(`/hunting-area/${params.id}/records/${recordId}?backTo=plan${additionalQuery(planQueryBack)}`);
  }

  function navigateToHuntRecordOnMap(recordId) {
    history.push(
      `/hunting-area/area-map/${params.id}?feature=${recordId}&backTo=plan${additionalQuery(planQueryBack)}`
    );
  }

  let { year, fromDate, toDate, animalTypeId, animalClassId, hunterName } = query;
  const defaultHuntingPlan = useHuntingPlanWithDate(huntingArea, !year && new Date());
  const yearHuntingPlan = useHuntingPlanWithYear(huntingArea, year);
  const selectedHuntingPlan = yearHuntingPlan || defaultHuntingPlan;
  if (!selectedHuntingPlan) dispatch(fetchHuntingPlan({ areaId: params.id, year }));
  const [expandAll, setExpandAll] = useState([]);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const canEdit = huntingArea && can(permissions.huntingArea.updateHuntRecord, huntingArea.permissions);
  const canModifyAll = huntingArea && can(permissions.huntingArea.modifyAllHuntRecords, huntingArea.permissions);
  const dateFilter = (date, fromDate, toDate) => {
    const ds = date.split('/').map((s) => parseInt(s));
    if (fromDate) {
      const fds = fromDate.split('/').map((s) => parseInt(s));
      if (fds[1] > ds[1]) return false;
      if (fds[0] > ds[0]) return false;
    }
    if (toDate) {
      const tds = toDate.split('/').map((s) => parseInt(s));
      const ds = date.split('/').map((s) => parseInt(s));
      if (tds[1] < ds[1]) return false;
      if (tds[0] < ds[0]) return false;
    }

    return true;
  };

  const treeData =
    selectedHuntingPlan &&
    selectedHuntingPlan.animalTypes
      .filter((a) => !animalTypeId || a.id == animalTypeId)
      .map((a) => {
        const animalTypeRecords = huntingRecords
          .filter((r) => (!hunterName || r.hunterName === hunterName) && dateFilter(r.huntingDate, fromDate, toDate))
          .filter((r) => !animalClassId || r.animalClassId == animalClassId)
          .filter((r) => r.animalTypeId === a.id);
        const animalClasses = a.animalClasses.filter((c) => !animalClassId || c.id == animalClassId);
        return {
          id: a.id,
          type: 'animalType',
          name: a.name,
          icon: a.icon,
          shootingPlan: animalClasses.reduce((acc, c) => acc + c.shootingPlan, 0),
          count: animalTypeRecords.reduce((acc, r) => acc + r.animalCount, 0),
          data: animalClasses
            .map((c) => {
              const animalClassRecords = animalTypeRecords.filter((r) => r.animalClassId === c.id);
              return {
                id: c.id,
                type: 'animalClass',
                name: c.name,
                shootingPlan: c.shootingPlan,
                count: animalClassRecords.reduce((acc, r) => acc + r.animalCount, 0),
                data: animalClassRecords.map((r) => ({
                  id: r.id,
                  type: 'huntingRecord',
                  hunterName: r.hunterName,
                  huntingDate: r.huntingDate,
                  animalFallen: r.animalFallen,
                  count: r.animalCount,
                  hasCoordinates: !!r.coordinates,
                  isSynced: r.isSynced,
                  isOwnRecord: r.userId === huntingArea.userId,
                })),
              };
            })
            .filter((c) => c.shootingPlan > 0 || c.count > 0),
        };
      })
      .filter((a) => a.shootingPlan > 0 || a.count > 0 || a.id == animalTypeId);

  const showAll = () => {
    setQuery({});
  };

  const showFilterPopup = () => {
    setOpenFilterPopup(true);
  };

  const closeFilterPopup = () => {
    setOpenFilterPopup(false);
  };

  const [isExpand, setIsExpand] = useState(false);
  const expandTree = () => {
    if (isExpand) {
      setExpandAll([]);
      setIsExpand(false);
    } else {
      let nodes = [...treeData.map((item) => [item.id, ...item.data.map((i) => i.id)])].flat();
      setExpandAll(nodes);
      setIsExpand(true);
    }
  };

  const renderDate = () => {
    let from = fromDateString(fromDate).toDateString().split(' ');
    let to = fromDateString(toDate).toDateString().split(' ');
    return from[1] + ' ' + from[2] + '  -  ' + to[1] + ' ' + to[2];
  };

  const showRightBar = (count) => {
    if (count == 1) {
      if (animalClassId || hunterName || fromDate || toDate) return true;
    } else if (count == 2) {
      if (hunterName || fromDate || toDate) return true;
    } else if (count == 3) {
      if (fromDate || toDate) return true;
    }
    return false;
  };

  return (
    <AppPage
      className='item-mobile-view'
      headerTitle={t('headerTitle.huntingPlan')}
      headerSubTitle={huntingArea ? huntingArea.name : ''}
      loading={!treeData}
      headerLeft={
        <IconButton data-testid='iconbtn' color='inherit' aria-label='back' onClick={onBack} edge='start'>
          <ArrowBackIcon />
        </IconButton>
      }
    >
      {treeData && (
        <div className='item-mobile-view filter-box'>
          <Grid
            container
            spacing={2}
            style={{ borderBottom: '2px solid #cdc6c6', marginBottom: '6px', paddingBottom: '6px' }}
            className={classes.actionButtons}
          >
            <div
              style={{
                width: '15%',
                borderRight: '2px solid #006633',
                margin: '3px',
              }}
            >
              <Button
                data-testid='submitbtn'
                onClick={showAll}
                size='small'
                variant='contained'
                color='primary'
                style={{
                  'min-width': '36%',
                  marginLeft: '12px',
                  height: '33px',
                  textTransform: 'none',
                  'padding-top': '6px',
                }}
                disabled={
                  !animalTypeId &&
                  !animalClassId &&
                  !hunterName &&
                  !fromDate &&
                  !toDate &&
                  year === huntingArea.defaultHuntingPlan
                }
              >
                {t('buttonText.all')}
              </Button>
            </div>
            <div className={animalTypeId || animalClassId || hunterName || fromDate || toDate ? 'filter-value' : ''}>
              <span
                className={animalTypeId ? 'span-item' : ''}
                style={!showRightBar(1) ? { border: '0' } : {}}
                variant='body2'
              >
                {animalTypeId && treeData && treeData.filter((item) => item.id == animalTypeId)[0].name}
              </span>
              <span
                style={!showRightBar(2) ? { border: '0' } : {}}
                className={animalClassId ? 'span-item' : ''}
                variant='body2'
              >
                {animalClassId &&
                  treeData.filter((item) => item.id == animalTypeId)[0].data.filter((cl) => cl.id == animalClassId)[0]
                    .name}
              </span>
              <span
                style={!showRightBar(3) ? { border: '0' } : {}}
                className={hunterName ? 'span-item' : ''}
                variant='body2'
              >
                {hunterName}
              </span>

              <span style={{ border: '0' }} className={fromDate | toDate ? 'span-item' : ''} variant='body2'>
                {(fromDate || toDate) && renderDate()}
              </span>
            </div>
            <div className='filter-icon-hp'>
              <Button
                fullWidth
                variant='text'
                color='primary'
                size='large'
                onClick={showFilterPopup}
                startIcon={<FilterIcon />}
                children=''
              ></Button>
            </div>
          </Grid>
          <Grid container spacing={2}>
            {treeData && (
              <>
                <Grid item xs={4}>
                  <Typography
                    style={{ paddingLeft: '12px', fontWeight: '700', color: 'grey' }}
                    variant='body2'
                    className={classes.labelText}
                  >
                    {t('huntingPlan.animalType')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    style={{ paddingLeft: '50%', fontWeight: '700', color: 'grey' }}
                    variant='body2'
                    className={classes.labelText}
                  >
                    {t('huntingPlan.actualTarget')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div onClick={expandTree}>
                    <div style={{ float: 'right', marginRight: '13px' }}>{!isExpand ? <DownIcon /> : <UpIcon />}</div>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      )}
      {treeData && (
        <StatisticsTree
          navigateToHuntRecordForm={navigateToHuntRecordForm}
          navigateToHuntRecordOnMap={navigateToHuntRecordOnMap}
          treeItems={treeData}
          expandNodes={expandAll}
          canEdit={canEdit}
          canModifyAll={canModifyAll}
        />
      )}
      {offlineRecords && offlineRecords.length ? (
        <div className='item-mobile-view-offline'>
          <label>*{t('general.offlineRecordssMsg')}</label>
          <ul>
            {offlineRecords.map((r, i) => {
              const animalType = selectedHuntingPlan.animalTypes.find((a) => r.animalTypeId === a.id);
              const animalClass = animalType.animalClasses.find((a) => r.animalClassId === a.id);
              return (
                <li key={'ofl_li' + i}>
                  {animalType.name} &gt; {animalClass.name} &gt; {r.hunterName} &gt; {r.huntingDate}{' '}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
      {openFilterPopup && (
        <FilterPopup isOpen={openFilterPopup} huntingAreaId={huntingArea.id} closeFilterPopup={closeFilterPopup} />
      )}
      {/* </div> */}
    </AppPage>
  );
};

export default withRouter(HuntingPlan);
