import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import WeatherInfoItem from '../weather-info-item/weather-info-item';
import useStyle from './weather-info-style';

const WeatherInfo = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { weather, setWeatherProps, callDarkSky, name, lang } = props;
  const [showTommorowWeather, setShowTommorowWeather] = useState(null);
  const toggleWeatherTile = () => {
    setShowTommorowWeather(!showTommorowWeather);
  };

  const nextDay = new Date().getDay() + 1;

  const getDay = () => {
    switch (nextDay) {
      case 1:
        return 'huntingArea.mon';
      case 2:
        return 'huntingArea.tue';
      case 3:
        return 'huntingArea.wed';
      case 4:
        return 'huntingArea.thu';
      case 5:
        return 'huntingArea.fri';
      case 6:
        return 'huntingArea.sat';
      default:
        return 'huntingArea.sun';
    }
  };
  return (
    <Box py={2}>
      <WeatherInfoItem
        weather={weather[0]}
        showTommorowWeather={showTommorowWeather}
        isTodayWeather={true}
        toggleWeatherTile={toggleWeatherTile}
      />
      <br />
      {showTommorowWeather && (
        <Typography variant='caption' display='block' gutterBottom color='Primary'>
          {t(getDay())}
        </Typography>
      )}
      {showTommorowWeather && (
        <WeatherInfoItem
          weather={weather[1]}
          showTommorowWeather={showTommorowWeather}
          toggleWeatherTile={toggleWeatherTile}
        />
      )}
      {showTommorowWeather && (
        <Box mt={2}>
          <Grid container justify='flex-end'>
            <Grid item>
              {/* <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" target="_blank"
                  href={`https://darksky.net/forecast/${weather[0].latitude},${weather[0].longitude}/ca12/${lang}`} 
                  >

                   {t('huntingArea.weatherDetails')}
                  </a> */}
              {/* <Link
                  target="_blank"
                    onClick={(e)=>{e.preventDefault();
                      callDarkSky({showMoreWeather:true,
                        latitude:weather[0].latitude,
                        longitude:weather[0].longitude,
                        huntingAreaName:name
                      })
                     }}
                  >
                    {t('huntingArea.weatherDetails')}
                  </Link> */}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default WeatherInfo;
