import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Box, Grid, Typography } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import Snackbar from '@material-ui/core/Snackbar';
import { AppPage } from 'app/shared/components';
import AppLanguage from '../../shared/components/app-language/app-language';
import useStyle from './user-info-style';
import { useTranslation } from 'react-i18next';
import { signOut, changePassword, deleteAccount } from 'app/shared/services/app-auth/app-auth';
import { UserDeleteDialog } from './user-delete-dialog';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Apps from '@material-ui/icons/Apps';
import { getDeferredPrompt, getUserChoice, setDeferredPrompt } from 'app/shared/services';
import appInfo from 'app/app-version';
import store, { clearLocalState } from 'app/app-state';

const UserInfo = (props) => {
  const { firstName, lastName, emailId } = props.userInfo;
  const fullName = `${firstName} ${lastName}`;
  const [openDialog, setopenDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState('');
  const [snackBarOpen, setSnackBar] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(getDeferredPrompt() ? false : true);
  const { global: { authDetails: { user: { idTokenClaims: { idp } = {} } = {} } = {} } = {} } = store.getState();

  const classes = useStyle();
  const { t } = useTranslation();

  const onDelete = async (userPassword) => {
    setLoading(true);
    const result = await deleteAccount({ userPassword: userPassword }).catch((e) => {
      signOut();
    });

    if (result && result.response) {
      if (result.response == true) {
        setSnackBar(true);
        setopenDialog(false);
        signOut();
      } else if (result.response == 'WrongPassword') {
        setErrorMessageKey('profile.accountDeleteError');
        setShowError(true);
      }
    } else if (result && result.error && result.error.response && result.error.response.data == 'WrongPassword') {
      setErrorMessageKey('profile.accountDeleteError');
      setShowError(true);
    } else {
      signOut();
    }
    setLoading(false);
  };

  const onCancel = () => {
    setopenDialog(false);
    setShowError(false);
  };

  const deleteAcount = () => {
    setopenDialog(true);
  };

  const clearLocalData = () => {
    clearLocalState();
  };

  const addToHome = () => {
    let deferredPrompt = getDeferredPrompt();

    if (deferredPrompt) {
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setIsAppInstalled(true);
          setDeferredPrompt(null);
        }
        deferredPrompt = null;
      });
    }
  };

  const getCurrentChoice = async () => {
    let choice = await getUserChoice();
    return choice;
  };

  useEffect(() => {
    getCurrentChoice().then((res) => {
      setIsAppInstalled(res);
    });
  }, []);

  return (
    <>
      <AppPage headerTitle={t('profile.profileHeader')} loading={loading}>
        <Box p={2}>
          <Grid container direction='column' justify='center' alignItems='center'>
            <Avatar className={classes.avatar}>
              {firstName && lastName && `${firstName[0]}${lastName[0]}`.toUpperCase()}
            </Avatar>
            <Typography variant='h5' className={classes.userName}>
              {firstName && fullName}
            </Typography>
            <Typography variant='subtitle1' color='textSecondary'>
              {emailId}
            </Typography>
          </Grid>
          <Box mt={7} mb={8}>
            <Grid container direction='column' spacing={2}>
              <Grid item className={classes.translateBtn}>
                {/* <Button fullwidth color='primary'> */}
                <AppLanguage />
                {/* </Button> */}
              </Grid>
              {!isAppInstalled && (
                <Grid item>
                  <Button
                    fullWidth
                    variant='text'
                    color='primary'
                    onClick={addToHome}
                    startIcon={<AddToHomeScreenIcon />}
                    id='profile-add-to-home'
                  >
                    {t('profile.addToHome')}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button fullWidth variant='text' color='primary' onClick={clearLocalData} startIcon={<RefreshIcon />}>
                  {t('profile.resetApp')}
                </Button>
              </Grid>
              {!!idp || (
                <Grid item>
                  <Button fullWidth variant='text' color='primary' onClick={changePassword} startIcon={<VpnKeyIcon />}>
                    {t('profile.changePassword')}
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Button fullWidth variant='text' color='primary' onClick={deleteAcount} startIcon={<DeleteIcon />}>
                  {t('profile.deleteAccount')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  variant='text'
                  color='primary'
                  onClick={() => {
                    window.open('https://www.bundesforste.at/jagdapp.html', '_blank');
                  }}
                  startIcon={<LiveHelpIcon />}
                >
                  {t('profile.help')}
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant='text' color='primary' startIcon={<Apps />}>
                  {t('general.appVersion')} : {appInfo.app.version}
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant='text' color='primary' onClick={signOut} startIcon={<PowerSettingsNewIcon />}>
                  {t('profile.logout')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackBarOpen}
          autoHideDuration={2000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message-id'>{t('profile.deleteAccountSuccess')}</span>}
        />
      </AppPage>
      {openDialog && (
        <UserDeleteDialog
          openDialog={openDialog}
          onDelete={onDelete}
          onCancel={onCancel}
          showError={showError}
          setShowError={setShowError}
          errorMessageKey={errorMessageKey}
          isSocialAccount={!!idp}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ global }) => ({
  userInfo: global.authDetails ? global.authDetails.userInfo : {},
});

export default connect(mapStateToProps, {})(UserInfo);
