import React from 'react';

import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import LinearProgress from '@material-ui/core/LinearProgress';


import useStyles from './app-navbar-style';
import { Grid } from '@material-ui/core';

const AppNavbar = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { headerTitle, headerSubTitle, headerLeft, loading } = props;

  const handleDrawerOpen = () => {
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        color='primary'
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          {/* {!headerLeft && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )} */}
          {headerLeft}
          <Grid>
          <Typography variant='h6' noWrap>
            {headerTitle}
          </Typography>
          <Typography style={{  width: '10em', overflow: 'hidden', textOverflow: 'ellipsis'}} 
             variant='subtitle1' noWrap>
            {headerSubTitle}
          </Typography>
          </Grid>
          <div className={classes.toolbarSpacing}></div>

          <img
          className={classes.appLogo}
          src='/assets/OEBF_LOGO_white_150dpi_rgb.png'
          alt='app logo'
        />

          {/* <AppLanguage /> */}
          {/* <VpnKeyIcon onClick={changePassword} /> &nbsp; &nbsp;
          <PowerSettingsNewIcon onClick={signOut} /> */}
          {props.right}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        {loading && <div className={classes.loader}>
          <LinearProgress />
          </div>
        }
        {props.children}
      </main>
    </div>
  );
};

export default AppNavbar;
