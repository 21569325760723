import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Box, Chip, Grid } from '@material-ui/core';
import useStyles from './area-map-filter-style';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import SelectDropdown from 'app/shared/controls/select-dropdown'
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import "./filterP.css";
import useQuery from 'app/shared/hooks/useQuery';
import useHuntingArea from 'app/shared/hooks/useHuntingArea.js';
import { useHuntingPlanWithDate, useAllAnimalTypes, useValidFilterDefaults } from './../../shared/hooks/useHuntingPlan';

export const recordFilterOptions = [
  'fromYear', 'toYear', 'fromDate', 'toDate', 'passing', 'onlyOwnShots', 'selectedAnimalTypes', 'selectedAnimalClasses', 'sortBy', 'sortDir',
];

const FilterPopup = props => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { isOpen, onClose, huntingAreaId } = props;
  const [query, setQuery] = useQuery();
  query.onlyOwnShots = query.onlyOwnShots === 'true'
  query.selectedAnimalTypes = query.selectedAnimalTypes ? query.selectedAnimalTypes.split(',') : undefined;
  query.selectedAnimalClasses = query.selectedAnimalClasses ? query.selectedAnimalClasses.split(',') : undefined;
  const { huntingArea } = useHuntingArea(huntingAreaId, false);
  const [formData, setFormData] = useState(query);
  let { fromYear, toYear, fromDate, toDate, selectedAnimalTypes, selectedAnimalClasses, onlyOwnShots, passing } = formData;

  const allAnimalTypes = useAllAnimalTypes(huntingArea);

  const { validFrom: validFromParts, validTo: validToParts } = useValidFilterDefaults(huntingArea);

  const validFromYear = validFromParts && validFromParts[2];
  const validToYear = validToParts && validToParts[2];
  const validFrom = validFromParts && validFromParts.slice(0, 2);
  const validTo = validToParts && validToParts.slice(0, 2);

  const passingTypes = [
    { name: t('buttonText.all'), id: "all" },
    { name: t('general.shots'), id: "shots" },
    { name: t('huntRecord.animalFallen'), id: "animalFallen" },
  ];

  if (!fromYear) fromYear = validFromYear || new Date().getFullYear();
  if (!toYear) toYear = validToYear || new Date().getFullYear();
  if (!fromDate) fromDate = validFrom && validFrom.join('/');
  if (!toDate) toDate = validTo && validTo.join('/');
  if (!passing) passing = passingTypes[0];

  const pickedFromDate = (() => {
    const spl = (fromDate || "01/01").split('/');
    return new Date(fromYear, spl[1] - 1, spl[0]);
  })();

  const pickedToDate = (() => {
    const spl = (toDate || "31/12").split('/');
    return new Date(toYear, spl[1] - 1, spl[0]);
  })();

  const animalTypes = huntingArea && [...new Set(allAnimalTypes.map(t => t.name))].sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  const animalClasses = huntingArea && Object.keys(allAnimalTypes.reduce((acc, t) => {
    t.animalClasses.forEach(c => {
      acc[c.name] = c;
    });
    return acc;
  }, {})).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));


  const handleChangeAnimal = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleFormChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckBox = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };
  const applyFilter = () => {
    if (formData.selectedAnimalTypes && formData.selectedAnimalTypes.length === animalTypes.length) formData.selectedAnimalTypes = undefined;
    if (formData.selectedAnimalClasses && formData.selectedAnimalClasses.length === animalClasses.length) formData.selectedAnimalClasses = undefined;
    if (!formData.onlyOwnShots) formData.onlyOwnShots = undefined;
    setQuery({ ...query, ...formData });
    onClose();
  }

  const close = () => {
    onClose();
  }

  return (
    <Dialog open={isOpen} >
      <DialogContent style={{ padding: "0 0" }}>
        <Box p={2} pt={4} style={{ paddingTop: "0px", marginTop: "12px" }}>
          <Grid container>
            <FormLabel component="text">{t('huntingPlan.year')}</FormLabel>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant='outlined'
                  margin='normal'
                  id='date-picker-dialog'
                  label={t('textBoxLabel.from')}
                  format='yyyy'
                  views={["year"]}
                  maxDate={new Date(toYear, 0, 1)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  okLabel={t("buttonText.ok")}
                  cancelLabel={t("buttonText.cancel")}
                  onChange={value => handleFormChange("fromYear", value.getFullYear())}
                  value={new Date(fromYear, 0, 1)}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant='outlined'
                  margin='normal'
                  id='date-picker-dialog'
                  label={t('textBoxLabel.to')}
                  format='yyyy'
                  views={["year"]}
                  minDate={new Date(fromYear, 0, 1)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  okLabel={t("buttonText.ok")}
                  cancelLabel={t("buttonText.cancel")}
                  onChange={value => handleFormChange("toYear", value.getFullYear())}
                  value={new Date(toYear, 0, 1)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.formControl}>
              <Grid style={{ marginTop: "10px" }} >
                <InputLabel id="mutiple-label-animaltype">{t('huntingPlan.animalType')}</InputLabel>
              </Grid>
              <Chip style={{ margin: "2px" }} label={t('buttonText.all')} onClick={() => {
                if (selectedAnimalTypes) {
                  handleChangeAnimal('selectedAnimalTypes', undefined);
                }
              }} color={!selectedAnimalTypes ? 'primary' : 'default'} />
              {animalTypes.map((animal) => (
                <Chip style={{ margin: "2px" }} key={animal} value={animal} label={animal} onClick={() => {
                  if (selectedAnimalTypes && selectedAnimalTypes.some(t => t == animal)) {
                    const newTypes = selectedAnimalTypes.filter(t => t !== animal);
                    handleChangeAnimal('selectedAnimalTypes', newTypes.length > 0 ? newTypes : undefined);
                  } else {
                    const newTypes = [...selectedAnimalTypes || [], animal];
                    handleChangeAnimal('selectedAnimalTypes', newTypes);
                  }
                }} color={selectedAnimalTypes && selectedAnimalTypes.some(t => t == animal) ? 'primary' : 'default'} />
              ))}
            </Grid>
            <Grid item xs={12} className={styles.formControl}>
              <Grid style={{ marginTop: "10px" }} >
                <InputLabel id="mutiple-label-animalclass">{t('huntingPlan.animalClass')}</InputLabel>
              </Grid>
              <Chip style={{ margin: "2px" }} label={t('buttonText.all')} onClick={() => {
                if (selectedAnimalClasses) {
                  handleChangeAnimal('selectedAnimalClasses', undefined);
                }
              }} color={!selectedAnimalClasses ? 'primary' : 'default'} />
              {animalClasses.map((animal) => (
                <Chip style={{ margin: "2px" }} key={animal} value={animal} label={animal} onClick={() => {
                  if (selectedAnimalClasses && selectedAnimalClasses.some(t => t == animal)) {
                    const newClasses = selectedAnimalClasses.filter(t => t !== animal);
                    handleChangeAnimal('selectedAnimalClasses', newClasses.length > 0 ? newClasses : undefined);
                  } else {
                    const newClasses = [...selectedAnimalClasses || [], animal];
                    handleChangeAnimal('selectedAnimalClasses', newClasses.length > 0 ? newClasses : undefined);
                  }
                }} color={selectedAnimalClasses && selectedAnimalClasses.some(t => t == animal) ? 'primary' : 'default'} />
              ))}
            </Grid>
            <FormLabel style={{ marginTop: "10px" }} component="text">{t('general.periodOfTimeout')}</FormLabel>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant='outlined'
                  margin='normal'
                  id='date-picker-dialog'
                  label={t('textBoxLabel.from')}
                  format='dd/MM'
                  variant="dialog"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  okLabel={t("buttonText.ok")}
                  cancelLabel={t("buttonText.cancel")}
                  onChange={value => handleFormChange('fromDate', value.getDate() + '/' + (value.getMonth() + 1))}
                  value={pickedFromDate}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant='outlined'
                  margin='normal'
                  id='date-picker-dialog'
                  label={t('textBoxLabel.to')}
                  format='dd/MM'
                  variant="dialog"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  okLabel={t("buttonText.ok")}
                  cancelLabel={t("buttonText.cancel")}
                  onChange={value => handleFormChange('toDate', value.getDate() + '/' + (value.getMonth() + 1))}
                  value={pickedToDate}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.formControl}>
              <Grid style={{ marginTop: "10px" }} >
                <SelectDropdown
                  label={t('general.passing')}
                  value={passing}
                  handleChange={item => handleFormChange('passing', item.value)}
                  fieldName='passing'
                  options={passingTypes}
                />
              </Grid>
              <Grid style={{ marginTop: "10px", }} row >
                <FormControl component="fieldset" style={{ marginLeft: "0px" }}>
                  <FormGroup aria-label="position" style={{ marginLeft: "0px" }} row>
                    <FormLabel style={{ marginLeft: "0px", marginTop: "13px", marginRight: "10px" }} component="text">{t('general.onlyOwnShots')}</FormLabel>
                    <FormControlLabel
                      value="start"
                      control={<Checkbox checked={onlyOwnShots} onChange={handleCheckBox} color="primary" />}
                      labelPlacement="only_own_shots"
                      name="onlyOwnShots"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>

        <Button data-testid="cancelbtn" onClick={close} size='small' variant='outlined'>
          {t('buttonText.cancel')}
        </Button>
        <Button
          onClick={applyFilter}
          size='small'
          variant='contained'
          color='primary'
          className={styles.noWrap}
        >
          {t('buttonText.apply')}
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default FilterPopup
