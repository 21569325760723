import React from 'react'
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, FormControl, InputLabel, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

const SelectDropdown = (props) => {
  const { t } = useTranslation();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    // <Grid item xs={6} className={props.classes.formControl}>
    <FormControl variant="outlined" fullWidth>
      <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
        {props.label}
      </InputLabel>
      <Select
        native
        value={props.value}
        onChange={(event, newValue) => {
          props.handleChange({ name: props.fieldName, value: event.target.value });
        }}
        labelWidth={labelWidth}
        inputProps={{
          name: props.label,
          id: 'outlined-count-native-simple',
        }}
      >
        {props.isEmptyValueRequired && <option value="-1" >{t('huntingPlan.noSelection')}</option>}
        {props.options && props.options.map((item, index) =>
          <option key={index + 1} value={item.id ? item.id : item}>{item.name ? item.name : item}</option>
        )}

      </Select>
    </FormControl>


    // </Grid>
  )
}

export default SelectDropdown

