import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FilterIcon(props) {
    return (
        <SvgIcon  version="1.1" x="0px" y="0px" viewBox="0 0 410.182 410.182" enableBackground="new 0 0 410.182 410.182">
         
         <g>
	<g>
		<path d="M392.995,0H17.187c-5.632,0-9.216,6.144-5.632,11.264l137.728,193.024c6.144,8.192,9.728,18.944,9.728,29.696v168.448
			c0.512,5.632,5.12,9.216,9.728,7.168l78.336-29.696c2.56-0.512,4.096-3.584,4.096-6.144V233.984
			c0-10.752,3.584-20.992,9.728-29.696L398.627,11.264C402.211,6.144,398.627,0,392.995,0z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
        </SvgIcon>
      );
}

export default FilterIcon;
