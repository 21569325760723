import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useStyle from './weather-info-item-style';
import SunLightBoxing from 'app/shared/components/sunlightboxing-icon/sun-light-boxing-icon';
import SunLightUnoxing from 'app/shared/components/sunlightboxing-icon/sun-light-unboxing-icon';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PartlyCloudyDay from 'app/shared/components/partly-cloudy-day/partly-cloudy-day-icon';
import ClearDay from 'app/shared/components/clear-day/clear-day-icon';
import Cloudy from 'app/shared/components/cloudy/cloudy-icon';
import Rain from 'app/shared/components/rain/rain-icon';
import Sleet from 'app/shared/components/sleet/sleet-icon';
import Snow from 'app/shared/components/snow/snow-icon';
import Wind from 'app/shared/components/wind/wind-icon';
import Fog from 'app/shared/components/fog/fog-icon';

const WeatherInfoItem = props => {

  const classes = useStyle();
  let { weather } = props;
  weather = { ...weather };
  const { showTommorowWeather, toggleWeatherTile, isTodayWeather } = props;

  modifyWindSpeed();
  function modifyWindSpeed() {
    if (weather && weather.sunriseWindSpeed) {
      var riseSpeed = weather.sunriseWindSpeed.split(' ');
      riseSpeed[0] = Math.round(riseSpeed[0]);
      weather.sunriseWindSpeed = riseSpeed[0] + ' ' + riseSpeed[1];
    }
    if (weather && weather.sunsetWindSpeed) {
      var setSpeed = weather.sunsetWindSpeed.split(' ');
      setSpeed[0] = Math.round(setSpeed[0]);
      weather.sunsetWindSpeed = setSpeed[0] + ' ' + setSpeed[1];
    }

  }
  const getWeatherIcon = (icon) => {
    switch (icon) {
      case 'clear-day':
        return <ClearDay viewBox='0 0 150 150' style={{ position: "relative" }} />;
      case 'partly-cloudy-day':
        return <PartlyCloudyDay style={{ position: "relative" }} viewBox='0 0 145 107' />;
      case 'cloudy':
        return <Cloudy style={{ position: "relative" }} />;
      case 'rain':
        return <Rain style={{ position: "relative" }} />;
      case 'sleet':
        return <Sleet style={{ position: "relative" }} />;
      case 'snow':
        return <Snow style={{ position: "relative" }} />;
      case 'wind':
        return <Wind style={{ position: "relative" }} />;
      case 'fog':
        return <Fog style={{ position: "relative" }} />;
      default:
        return <PartlyCloudyDay style={{ position: "relative" }} viewBox='0 0 145 107' />;
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems='center' style={{ 'flexFlow': 'nowrap' }}>
        <Grid style={{ alignItems: "flex-end", paddingRight: 0 }} item className={classes.infoItem} xs>
          <SunLightBoxing color='primary' />
          <Typography variant='caption' display='block' color='textSecondary'>
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-start" }} item className={classes.infoItem} xs>
          {/* <SunriseIcon viewBox={ viewBoxSize } /> */}
          <Typography variant='caption' display='block' color='textSecondary'>
            {weather.sunrise}
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-start", position: "relative" }} item className={classes.weatherInfoItem} xs>
          {/* <WeatherIcon viewBox={ viewBoxSize } /> */}
          {getWeatherIcon(weather.sunriseIcon)}
          <Typography variant='caption' display='block' color='textSecondary' className='weather-temperature'>
            {weather.sunriseTemperature}&deg;
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-end", paddingRight: 0 }} item className={classes.infoItem} xs>
          <ArrowRightAltIcon style={{ transform: 'rotate(' + (parseInt(weather.sunriseWindDirection) + 90) + 'deg)' }} color='primary' />
          <Typography variant='caption' display='block' color='textSecondary'>
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-start" }} item className={classes.infoItem} xs>
          <Typography variant='caption' display='block' color='textSecondary' style={{ 'whiteSpace': 'nowrap' }}>
            {weather.sunriseWindSpeed}
          </Typography>
        </Grid>
        <Grid item>
          {<ExpandMoreIcon color='primary' style={{ visibility: 'hidden' }} onClick={toggleWeatherTile} />}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems='center' style={{ 'flexFlow': 'nowrap' }}>
        <Grid style={{ alignItems: "flex-end", paddingRight: 0 }} item className={classes.infoItem} xs>
          <SunLightUnoxing color='primary' />
          <Typography variant='caption' display='block' color='textSecondary'>
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-start" }} item className={classes.infoItem} xs>
          {/* <SunsetIcon viewBox={ viewBoxSize } /> */}
          <Typography variant='caption' display='block' color='textSecondary'>
            {weather.sunset}
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-start", position: "relative" }} item className={classes.weatherInfoItem} xs>
          {/* <WeatherIcon viewBox={ viewBoxSize } /> */}
          {getWeatherIcon(weather.sunsetIcon)}
          <Typography variant='caption' display='block' color='textSecondary' className='weather-temperature'>
            {weather.sunsetTemperature}&deg;
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-end", paddingRight: 0 }} item className={classes.infoItem} xs>
          <ArrowRightAltIcon color='primary' style={{ transform: 'rotate(' + (parseInt(weather.sunsetWindDirection) + 90) + 'deg)' }} />
          <Typography variant='caption' display='block' color='textSecondary'>
          </Typography>
        </Grid>
        <Grid style={{ alignItems: "flex-start" }} item className={classes.infoItem} xs>
          <Typography variant='caption' display='block' color='textSecondary' style={{ 'whiteSpace': 'nowrap' }}>
            {weather.sunsetWindSpeed}
          </Typography>
        </Grid>
        <Grid item>
          {!showTommorowWeather && isTodayWeather && <ExpandMoreIcon color='primary' onClick={toggleWeatherTile} />}
          {showTommorowWeather && isTodayWeather && <ExpandLessIcon color='primary' onClick={toggleWeatherTile} />}
          {!isTodayWeather && <ExpandMoreIcon color='primary' style={{ visibility: 'hidden' }} />}
        </Grid>
      </Grid>

    </>
  );
};

export default WeatherInfoItem;