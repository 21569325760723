import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAreaMap } from 'app/hunting-area/shared/state/hunting-area';

export default function useHuntingArea(id, forceFetch, skipFetch) {
  const dispatch = useDispatch();
  const huntingAreas = useSelector(({ huntingArea }) => huntingArea.huntingAreaData);
  const huntingRecords = useSelector(({ huntingArea }) => huntingArea.huntingRecords);
  const markers = useSelector(({ huntingArea }) => huntingArea.markers);
  const huntingStatistics = useSelector(({ huntingArea }) => {
    return huntingArea.huntingAreaData.data[id] && huntingArea.huntingAreaData.data[id].huntingStatistics;
  });

  if (forceFetch === undefined) forceFetch = true;

  useEffect(() => {
    if (!skipFetch) {
      if (forceFetch || !(huntingAreas.data[id] && huntingAreas.data[id].defaultHuntingPlan)) {
        dispatch(fetchAreaMap(id));
      }
    }
  }, [id]);

  return {
    huntingArea: huntingAreas.data[id],
    huntingRecords: Object.values(huntingRecords.data).filter((r) => r.huntingAreaId == id),
    huntingStatistics,
    markers: Object.values(markers.data).filter((r) => r.huntingAreaId == id),
    huntingRecordsByKey: huntingRecords,
    markersByKey: {
      ...markers,
      data: Object.keys(markers.data).reduce((acc, key) => {
        if (markers.data[key].huntingAreaId == id) {
          acc[key] = markers.data[key];
        }
        return acc;
      }, {}),
    },
  };
}
