import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllAreas } from '../../hunting-area/shared/state/hunting-area';

export default function useHuntingAreas(columns) {
    const huntingAreas = useSelector(({ huntingArea }) => huntingArea.huntingAreaData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllAreas({ columns }));
    }, []);
    return {
        huntingAreas,
        specialistAreas: huntingAreas.specialistAreas.map(id => ({ ...huntingAreas.data[id], isHunter: false })),
        hunterAreas: huntingAreas.hunterAreas.map(id => ({
            ...huntingAreas.data[id],
            picture: huntingAreas.data[id].hunterPicture,
            permissions: huntingAreas.data[id].hunterPermissions,
            isHunter: true
        })),
    };
}