const {
  REACT_APP_API_BASE_URL,
  NODE_ENV = '',
  REACT_APP_API_AUTH_INSTANCE,
  REACT_APP_API_AUTH_TENANT,
  REACT_APP_API_AUTH_SIGN_IN_POLICY,
  REACT_APP_API_AUTH_CLIENT_ID,
  REACT_APP_API_AUTH_SCOPE,
  REACT_APP_API_AUTH_SCOPE1 = '', // deprecated
  REACT_APP_API_AUTH_REDIRECT_URI,
  REACT_APP_API_AUTH_POST_LOGOUT_URI,
  REACT_APP_API_AUTH_RESET_POLICY,
  REACT_APP_BLOB_BASE_URL,
} = process.env;

/** environment variables */
const environment = NODE_ENV.toLowerCase();

/** Base url for APIs */
const apiBaseUrl = REACT_APP_API_BASE_URL;

const authConfig = {
  instance: REACT_APP_API_AUTH_INSTANCE,
  tenant: REACT_APP_API_AUTH_TENANT,
  signInPolicy: REACT_APP_API_AUTH_SIGN_IN_POLICY,
  clientId: REACT_APP_API_AUTH_CLIENT_ID,
  scopes: REACT_APP_API_AUTH_SCOPE
    ? REACT_APP_API_AUTH_SCOPE.split(',')
    : REACT_APP_API_AUTH_SCOPE1.split(','),
  redirectUri: REACT_APP_API_AUTH_REDIRECT_URI,
  postLogoutRedirectUri: REACT_APP_API_AUTH_POST_LOGOUT_URI
    ? REACT_APP_API_AUTH_POST_LOGOUT_URI
    : window.location.origin,
  resetPolicy: REACT_APP_API_AUTH_RESET_POLICY,
};

const baseBlobUrl = REACT_APP_BLOB_BASE_URL;

export { environment, apiBaseUrl, authConfig, baseBlobUrl };
