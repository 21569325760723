import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    // paddingLeft: '0.5rem',
    // paddingRight: '0.5rem',
    zIndex: 1100,
    borderRadius: 0
  }
});
