import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#006633'
    }
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        minWidth: '60px'
      }
    }
  }
});
theme = responsiveFontSizes(theme);

export default theme;
