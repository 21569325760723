import { appNetwork } from "../services";
import { useState, useEffect } from 'react';

export default function useOnline() {
    const [isOnline, setOnline] = useState(!appNetwork.isOffline);
    useEffect(() => {
        const onlineCallback = () => {
            setOnline(true);
        };
        appNetwork.onOnline(onlineCallback);

        const offlineCallback = () => {
            setOnline(false);
        };
        appNetwork.onOffline(offlineCallback);

        return () => {
            appNetwork.removeOnlineCallback(onlineCallback);
            appNetwork.removeOfflineCallback(offlineCallback);
        }
    }, []);

    return isOnline;
}