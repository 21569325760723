import React, { useContext, useEffect } from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { Switch, Redirect, Route, useHistory } from 'react-router-dom';
import HuntingAreaRoutes from './hunting-area/hunting-area-routes';
import UnauthorizedUser from './shared/components/unauthorized-user/unauthorized-user';
import UserProfileRoutes from './user-profile/user-profile-routes';
import LockedUser from './shared/components/locked-user/';

const AppRoutes = props => {
  const store = useContext(ReactReduxContext).store;
  const history = useHistory();
  useEffect(() => {
    store.actions.subscribe(action => {
      if (action.type !== 'huntingRecord/updateRecordId') return;
      const match = window.location.href.match(/(\/hunting-area\/\d+\/records\/)(generated_\d*)(.*)/);
      if (match && action.payload.oldId === match[2]) {
        history.replace(match[1] + action.payload.newId + match[3]);
      }
    });
  }, []);
  return (
    <Switch>
      <Route path='/hunting-area' component={HuntingAreaRoutes} />
      <Route path='/profile' component={UserProfileRoutes} />
      <Route exact path='/unauthorized' component={UnauthorizedUser} />
      <Route exact path='/locked' component={LockedUser} />
      <Redirect exact from='/' to='hunting-area' />
      <Route component={UnauthorizedUser} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  permissions: state.global.permissions
});

export default connect(mapStateToProps, {})(AppRoutes);
