import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
    createStyles({
        infoItem: {
            display: 'flex',
            flexDirection: 'column',
            'align-items': 'center'
        },
        media : {
            'width': '37px',
            'height': '40px',
            'padding': '0px',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            'margin-bottom': '4px'
        }
    })
);

export default useStyles;
