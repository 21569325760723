import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';
import axiosRetry from 'axios-retry';

let permissionsData = {};

const permissions = {
  grantPermissions: 'PR011',
  viewRequests: 'PR012',
  requestAccess: 'PR013',
  huntingAreaView: 'PR014',
  huntingArea: {
    accessToMap: 'PR001',
    changePicture: 'PR003',
    addFeatures: 'PR002',
    viewWeatherInfo: 'PR004',
    changeDescription: 'PR005',
    grantAccessToHunters: 'PR006',
    seeActiveUsers: 'PR015',
    viewAmimalActualTargetInfo: 'PR016',
    viewReservations: 'PR021',
    modifyOwnReservations: 'PR022',
    modifyAllReservations: 'PR023',
    reservationsWithoutTimeRestriction: 'PR024',
    addHuntRecord: 'PR025',
    viewStatistics: 'PR026',
    deleteHuntRecord: 'PR027',
    updateHuntRecord: 'PR028',
    modifyAllHuntRecords: 'PR029',
    dataPrivacy: 'PR030'
  }
};

const fetchAppPermissions = () => {
  const url = API_CONSTANTS.userpermission;
  axiosRetry(httpClient, {
    retries: 3
  });
  return httpClient
    .get(url)
    .then(res => {
      /*  debugger; */
      const { permissions, ...userInfo } = res.data;
      permissionsData = parsePermissions(permissions);
      return { permissionsData, userInfo };
    })
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

function parsePermissions(permissions) {
  return permissions.reduce((obj, item) => {
    obj[item.name] = item;
    return obj;
  }, {});
}

function can(permissionType, data) {
  if (data) {
    data = parsePermissions(data);
  } else {
    data = permissionsData;
  }

  switch (permissionType) {
    // to do need to update the business logic
    case permissions.huntingAreaView:
      return true;
    default:
      return !!data[permissionType];
  }
}

export { fetchAppPermissions, permissions, can };
