import React from 'react';

import { Box } from '@material-ui/core';
import { AppPage, NoData } from 'app/shared/components';
import AccessRequestItem from '../access-request-item/access-request-item';

import { useTranslation } from 'react-i18next';
import useAccessRequests from 'app/shared/hooks/useAccessRequests';

const AreaAccessRequests = () => {
  const { t } = useTranslation();
  const { accessRequests, loading } = useAccessRequests();

  const isNoData = accessRequests.length <= 0 && !loading;
  return (
    <AppPage data-testid='compAppPage' headerTitle={t('headerTitle.pendingRequests')} loading={loading}>
      <Box className="item-mobile-view" style={{ paddingBottom: "60px" }} p={2}>
        {accessRequests &&
          accessRequests.map(item => (
            <AccessRequestItem key={item.id} accessRequest={item} />
          ))}
        {isNoData && <NoData />}
      </Box>
    </AppPage>
  );
};

export default AreaAccessRequests;
