import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  mediaSection:{
    position: 'relative'
  },
  imageLabel : {
    'justify-content': 'right',
    'display': 'inline-grid',
    'padding-top': '15px !important',
    'margin-top': '9px !important'
  },
  fabIcon: {
    width : '37px !important',
    'float': 'right',
    'margin-right': '8px'
  },
  camIcon : {
    'padding-top': '6px !important',
    'padding-left': '6px !important',
    'padding-bottom': '13px !important',
    'margin-bottom': '16px !important'    
  }
}));

export default useStyles;
