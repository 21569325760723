const dateMethods = {
    convert: function (d) {
        // Converts the date in d to a date-object. The input can be:
        //   a date object: returned without modification
        //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
        //   a number     : Interpreted as number of milliseconds
        //                  since 1 Jan 1970 (a timestamp) 
        //   a string     : Any format supported by the javascript engine, like
        //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
        //  an object     : Interpreted as an object with year, month and date
        //                  attributes.  **NOTE** month is 0-11.
        return (
            d.constructor === Date ? d :
                d.constructor === Array ? new Date(d[0], d[1], d[2]) :
                    d.constructor === Number ? new Date(d) :
                        d.constructor === String ? new Date(d) :
                            typeof d === "object" ? new Date(d.year, d.month, d.date) :
                                NaN
        );
    },
    compare: function (a, b) {
        // Compare two dates (could be of any type supported by the convert
        // function above) and returns:
        //  -1 : if a < b
        //   0 : if a = b
        //   1 : if a > b
        // NaN : if a or b is an illegal date
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(a = this.convert(a).valueOf()) &&
                isFinite(b = this.convert(b).valueOf()) ?
                (a > b) - (a < b) :
                NaN
        );
    },
    inRange: function (d, start, end) {
        d.setHours(0, 0, 0, 0)
        // Checks if date in d is between dates in start and end.
        // Returns a boolean or NaN:
        //    true  : if d is between start and end (inclusive)
        //    false : if d is before start or after end
        //    NaN   : if one or more of the dates is illegal.
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(d = this.convert(d).valueOf()) &&
                isFinite(start = this.convert(start).valueOf()) &&
                isFinite(end = this.convert(end).valueOf()) ?
                start <= d && d <= end :
                NaN
        );
    }
}

export const fromDateString = (dateString) => {
    if (!dateString) return dateString;
    const [date, month, year] = dateString.split('/').map(s => parseInt(s));
    return new Date(Date.UTC(year, month - 1, date));
}

const doubleDigit = (d) => {
    return d >= 10 ? d : `0${d}`;
}

export const toDateString = (date) => {
    return doubleDigit(date.getDate()) + '/' + (doubleDigit(date.getMonth() + 1)) + '/' + date.getFullYear();
}

export const toDateStringWithTime = (date) => {
    return toDateString(date) + ' ' + toTimeString(date);
}

export const toTimeString = (date) => {
    return doubleDigit(date.getHours()) + ':' + doubleDigit(date.getMinutes());
}

export const unstupidifiedDate = (year, month, day, hour, minute, second) => {
    return new Date(year, month - 1, day, hour || 0, minute || 0, second || 0);
}

export const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

export default dateMethods