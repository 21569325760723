export class LocationService {
    location = {
        location: null,
        accuracy: null,
    };

    clearWatch() {
        if (!navigator.geolocation) return;
        if (this.geolocationHandle) {
            navigator.geolocation.clearWatch(this.geolocationHandle);
            this.geolocationHandle = null;
        }
    }

    startWatch() {
        if (!navigator.geolocation) return;
        this.clearWatch();
        this.getPosition();
        this.geolocationHandle = navigator.geolocation.watchPosition(p => this.setPosition(p), undefined, { enableHighAccuracy: true, timeout: 20000, maximumAge: 0, distanceFilter: 10 });
    }

    getPosition() {
        const getCurrentPosition = () => {
            navigator.geolocation.getCurrentPosition(p => this.setPosition(p), undefined, { enableHighAccuracy: true, timeout: 20000, maximumAge: 0, distanceFilter: 10 });
        }

        if (navigator.geolocation) {
            if (navigator.permissions && navigator.permissions.query) {
                navigator.permissions.query({ name: 'geolocation' }).then(status => {
                    getCurrentPosition();
                    this.clearStatus = status;
                    this.clearStatusFunc = function () {
                        if (this.state === 'granted') getCurrentPosition();
                    }
                    status.addEventListener('change', this.clearStatusFunc)
                })
            }
            else {
                getCurrentPosition();
            }
        }
    }

    clearPermissions() {
        if (this.clearStatus) this.clearStatus.removeEventListener('change', this.clearStatusFunc);
    }

    start() {
        const self = this;
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'geolocation' }).then(status => {
                this.startWatch();
                status.addEventListener('change', function () {
                    if (this.state === 'granted') self.startWatch();
                    else self.clearWatch();
                })
                this.clearStatus = status;
            })
        }
        else {
            this.startWatch();
        }
    }

    setPosition(position) {
        if (position) {
            const location = [position.coords.latitude, position.coords.longitude];
            let accuracy = position.coords.accuracy;
            let updatedAcc = 10;
            let dynamicAcc = 10;
            if (accuracy && accuracy > 0) {
                let length = accuracy.toString().length;
                if (length > 2) {
                    dynamicAcc = accuracy / ((length - 2) * 10)
                }
                updatedAcc = (updatedAcc > dynamicAcc) ? updatedAcc : dynamicAcc;
            }
            this.location = { location, accuracy: updatedAcc };
            if (this.subscriber) this.subscriber(this.location);
        }
    }

    subscribeToLocation(subscriber) {
        this.subscriber = subscriber;
    }
}