import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { appNetwork } from 'app/shared/services';
import { createHuntRecord, updateHuntRecord, removeHuntReord as removeHuntRecord, saveHunters, updateHunters, deleteHunters } from '../../hunting-area/shared/services/hunt-record/hunt-record';
import { AddMapMarker, DeleteMarker } from '../../hunting-area/shared/services/hunting-area-map/hunting-area-map';
import { changePictureRequest } from '../../hunting-area/shared/services/hunting-area/hunting-area'
import { updateUserSettings } from '../services/app-auth/app-auth';
import { updateMarkerId, updateRecordId, updateStatistics } from 'app/hunting-area/shared/state/additionalActions';
import { v4 as uuid } from 'uuid';

const initialState = { requests: [] };

const requestTable = {
    'huntingRecord.add': (payload, dispatch) => {
        dispatch(updateStatistics({ previous: null, next: payload }));
        return createHuntRecord({ ...payload, id: undefined }).then(res => {
            dispatch(updateRecordId({ oldId: payload.id, newId: res.response }));
        });
    },
    'huntingRecord.update': (payload, dispatch, getState) => {
        dispatch(updateStatistics({ previous: getState().huntingArea.huntingRecords.data[payload.id], next: payload }));
        return updateHuntRecord(payload);
    },
    'huntingRecord.remove': (payload, dispatch) => {
        dispatch(updateStatistics({ previous: payload, next: null }));
        return removeHuntRecord(payload.id);
    },
    'hunter.add': (payload) => saveHunters(payload.hunterName, payload.cardId),
    'hunter.update': (payload) => updateHunters(payload.hunterName, payload.cardId, payload.oldCardId),
    'hunter.remove': (payload) => deleteHunters(payload.cardId),
    'marker.add': (payload, dispatch) => AddMapMarker(payload.huntingAreaId, { ...payload, id: undefined }).then(res => {
        dispatch(updateMarkerId({ oldId: payload.id, newId: res.response }))
    }),
    'marker.remove': (payload) => DeleteMarker(payload.id),
    'huntingArea.changePicture': changePictureRequest,
    'authDetails.updateUserSettings': updateUserSettings,
}

function addOfflineActionHunter(state, mainType, subType, request) {
    if (subType === 'remove') {
        const update = state.requests.find(r => r.payload.cardId === request.payload.cardId && r.type.split('.')[1] === 'update');
        state.requests = state.requests.filter(r => r.payload.cardId !== request.payload.cardId);
        if (update) {
            pushRequestWithId(state, { type: request.type, payload: { ...request.payload, cardId: update.payload.oldCardId } });
        } else {
            pushRequestWithId(state, request);
        }
    } else if (subType === 'update') {
        const create = state.requests.find(r => r.payload.cardId === request.payload.oldCardId && r.type.split('.')[1] === 'add');
        const update = state.requests.find(r => r.payload.cardId === request.payload.oldCardId && r.type.split('.')[1] === 'update');
        state.requests = state.requests.filter(r => r.payload.cardId !== request.payload.oldCardId);
        let newRequest = request;
        if (update) {
            newRequest = { type: request.type, payload: { ...request.payload, oldCardId: update.payload.oldCardId } };
        } else if (create) {
            newRequest = { type: mainType + '.add', payload: { hunterName: request.payload.hunterName, cardId: request.payload.cardId } };
        }

        const remove = state.requests.find(r => r.payload.cardId === newRequest.payload.cardId && r.type.split('.')[1] === 'remove');
        if (remove) {
            state.requests = state.requests.filter(r => r.payload.cardId !== newRequest.payload.cardId);
            pushRequestWithId(state, { type: mainType + '.remove', payload: { hunterName: newRequest.payload.hunterNam, cardId: newRequest.payload.oldCardId } });
            pushRequestWithId(state, { type: mainType + '.update', payload: { ...newRequest.payload, oldCardId: newRequest.payload.cardId } });
        } else {
            pushRequestWithId(state, newRequest);
        }
    } else {
        const remove = state.requests.find(r => r.payload.cardId === request.payload.cardId && r.type.split('.')[1] === 'remove');
        state.requests = state.requests.filter(r => r.payload.cardId !== request.payload.cardId);
        if (remove) {
            pushRequestWithId(state, { type: mainType + '.update', payload: { hunterName: request.payload.hunterName, cardId: request.payload.cardId, oldCardId: request.payload.cardId } });
        } else {
            pushRequestWithId(state, request);
        }
    }
}

function addOfflineActionIDLike(state, mainType, subType, request) {
    const create = state.requests.find(r => r.payload.id === request.payload.id && r.type.split('.')[1] === 'add');
    if (subType === 'remove') {
        state.requests = state.requests.filter(r => r.payload.id !== request.payload.id);
        if (!create) {
            pushRequestWithId(state, request);
        }
    } else if (subType === 'update') {
        state.requests = state.requests.filter(r => r.payload.id !== request.payload.id);
        if (create) {
            pushRequestWithId(state, { type: mainType + '.add', payload: request.payload });
        } else {
            pushRequestWithId(state, request);
        }
    } else {
        pushRequestWithId(state, request);
    }
}

function pushRequestWithId(state, request) {
    state.requests.push({ ...request, id: uuid() });
}

export async function executeOnlineOrOffline(type, payload, thunkApi) {
    if (appNetwork.online) {
        if (type.endsWith('remove') && payload.id && payload.id.startsWith && payload.id.startsWith('generated')) return;
        requestTable[type](payload, thunkApi.dispatch, thunkApi.getState).then();
    }
    else {
        thunkApi.dispatch(addOfflineRequest({ type, payload }));
    }
    return payload;
}

export const executeRequests = createAsyncThunk(
    'offlineRequests/executeRequests',
    async (_, thunkApi) => {
        if (!appNetwork.online) return [];
        const ids = await Promise.all(thunkApi.getState().global.offlineRequests.requests.map(r => requestTable[r.type](r.payload, thunkApi.dispatch, thunkApi.getState).then(() => r.id).catch(() => null)));
        return ids.filter(id => !!id);
    }
)

const offlineRequestsSlice = createSlice({
    name: 'offlineRequests',
    initialState,
    reducers: {
        addOfflineRequest(state, action) {
            const [mainType, subType] = action.payload.type.split('.');
            if (mainType === 'hunter') addOfflineActionHunter(state, mainType, subType, action.payload);
            else addOfflineActionIDLike(state, mainType, subType, action.payload);
        },
    },
    extraReducers: {
        [executeRequests.fulfilled]: (state, action) => {
            state.requests = state.requests.filter(r => !action.payload.includes(r.id));
        },
    }
})

export const { addOfflineRequest, removeRequests } = offlineRequestsSlice.actions

export default offlineRequestsSlice.reducer