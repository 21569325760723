import axios from 'axios';

import { apiBaseUrl } from 'environement/environment';
import { getAccessToken, silentReauth } from '../app-auth/app-auth';

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
  baseURL: apiBaseUrl,
  retryConfig: {
    retry: 1
  }
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config);

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = config => {
  /** add auth token */
  const { headers } = config;
  const authHeader = { Authorization: `Bearer ${getAccessToken()}` };
  config.headers = {
    ...headers,
    ...authHeader,
  };
  return config;
};

const loggerInterceptor = config => {
  /** Add logging here */
  return config;
};

const silentReauthInterceptor = async error => {
  if (error.response.status === 401) {
    await silentReauth();
  }
  return Promise.reject(error);
};

const retryInterceptor = error => {
  const config = error.config;
  config.retryConfig = config.retryConfig || { retry: 3, retriedCount: 0 };

  const { retry, retriedCount = 0 } = config.retryConfig;

  if (retriedCount < retry) {
    config.retryConfig.retriedCount = retriedCount + 1;
    return httpClient.request(config);
  } else {
    return Promise.reject(error);
  }
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use(null, silentReauthInterceptor);
httpClient.interceptors.response.use(null, retryInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    /** Do something with response error */
    return Promise.reject(error);
  }
);

export { httpClient };
