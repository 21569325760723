import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Cloudy(props) {
    return (
        <SvgIcon { ...props } viewBox="0 0 146 90">
            <g id="SketchAppSources.com" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-735.000000, -982.000000)">
                    <g id="Group-4" transform="translate(733.000000, 952.000000)">
                        <g id="icon" transform="translate(2.000000, 30.000000)" fill="#d0caca">
                            <g id="cloud-/-normal">
                                <path d="M88.2134722,89.9000005 L31.5864867,89.9000005 C14.1417518,89.9000005 0,75.7582487 0,58.3135138 C0,40.868779 14.1417518,26.7270272 31.5864867,26.7270272 C36.8507566,26.7270272 41.814243,28.0148341 46.1790936,30.2925955 C52.2568096,12.6640862 68.9920447,0 88.6851356,0 C113.510335,0 133.635136,20.1248006 133.635136,44.9500002 C133.635136,45.458796 133.626682,45.9656172 133.609908,46.4703314 C140.856656,50.3636117 145.783785,58.0151935 145.783785,66.8175679 C145.783785,79.5656434 135.449428,89.9000005 122.701352,89.9000005 L88.6851356,89.9000005 C88.6141779,89.8999873 88.2134722,89.9000005 88.2134722,89.9000005 L88.6851356,89.9000005 L88.2134722,89.9000005 Z" id="Oval-4"></path>
                            </g>
                        </g>
                        <rect id="Rectangle-Copy-2" x="0" y="0" width="150" height="150"></rect>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default Cloudy;
