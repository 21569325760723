import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import UserInfo from './user-info/user-info';

const UserProfileRoutes = props => (
  <>
    <Switch>
      <Route path={props.match.url + '/user-info'} component={UserInfo} />
      <Redirect from={props.match.url} to={props.match.url + '/user-info'} />
    </Switch>
  </>
);

export default UserProfileRoutes;
