import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function UpIcon(props) {
    return (
        <SvgIcon enable-background="new 0 0 512.005 512.005" style={{color:"grey"}} className="rotateimg180" height="26px" id="Layer_1" version="1.1"  viewBox="0 0 512.005 512.005"  width="26px">
         
        
		 {props.single? <g></g>: <g>
        <g>
          <path d="M392.173,236.293c-7.957-3.328-17.152-1.493-23.253,4.629L256.002,353.84L143.085,240.922
            c-6.101-6.101-15.253-7.957-23.253-4.629c-7.979,3.285-13.163,11.093-13.163,19.712v108.139c0,5.717,2.304,11.179,6.336,15.168
            l128,126.528c4.16,4.096,9.579,6.165,14.997,6.165c5.419,0,10.837-2.069,14.997-6.165l128-126.528
            c4.053-3.989,6.336-9.451,6.336-15.168v-108.14C405.335,247.386,400.151,239.577,392.173,236.293z"/>
        </g>
      </g>
        }
<g>
	<g>
		<path d="M392.173,1.626c-7.957-3.307-17.152-1.472-23.253,4.629L256.002,119.173L143.085,6.256
			c-6.101-6.101-15.253-7.936-23.253-4.629c-7.979,3.285-13.163,11.093-13.163,19.712v108.139c0,5.717,2.304,11.179,6.336,15.168
			l128,126.528c4.16,4.096,9.579,6.165,14.997,6.165c5.419,0,10.837-2.069,14.997-6.165l128-126.528
			c4.053-3.989,6.336-9.451,6.336-15.168V21.338C405.335,12.72,400.151,4.912,392.173,1.626z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>        </SvgIcon>
      );
}

export default UpIcon;
