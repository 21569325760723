import React from 'react';

const UnauthorizedUser = () => {
  return (
   <div>
     <h2>
       You are not authorised to access this functionality. 
     </h2>
   </div>
  );
};

export default UnauthorizedUser;
