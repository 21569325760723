import React, { useEffect, useState } from 'react';
import { Box, Grid, Avatar, Divider, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useTranslation } from 'react-i18next';

import { AppPage, NoData, AppButton } from 'app/shared/components';
import { fetchActiveUsers } from '../shared/services/hunting-area/hunting-area';

import useStyles from './active-users-style';
import ActiveUser from '../active-user/active-user';

const ActiveUsers = props => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [revokeAccessLoading, setRevoleAccessLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { match } = props;

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUsers() {
    setLoading(true);
    const { id } = match.params;
    const { response } = await fetchActiveUsers(id);
    setLoading(false);
    if (response) {
      setUsers(response);
    }
  }

  function onBack() {
    props.history.push(`/hunting-area/overview`);
  }

  return (
    <AppPage
      loading={loading}
      headerTitle={t('huntingArea.activeUsers')}
      headerLeft={
        <IconButton
        data-testid='iconbtn'
          color='inherit'
          aria-label='back'
          onClick={onBack}
          edge='start'
        >
          <ArrowBackIcon />
        </IconButton>
      }
    >
      <Box className="item-mobile-view" style={{paddingBottom:"60px"}}  p={2}>
        {users.map(user => (
          <div key={user.email}>
            <ActiveUser
            data-testid='activeuser'
              user={user}
              t ={t}
              huntingAreaId={match.params.id}
              refreshUsers={getUsers}
            />
            {/* <Box py={2}>
              <Grid container justify='space-between'>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar className={classes.avatar}>AR</Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant='h5'>{`${user.firstName} ${user.lastName}`}</Typography>
                      <Typography variant='caption' color='textSecondary'>
                        {user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <a href={`mailto: ${user.email}`}>
                    <IconButton color='primary'>
                      <MailOutlineIcon />
                    </IconButton>
                  </a>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Grid container justify='flex-end'>
                  <Grid item>
                    <AppButton
                      loading={revokeAccessLoading}
                      onClick={() => revokeAccess(user)}
                      variant='outlined'
                      color='secondary'
                    >
                      Revoke Access
                    </AppButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider /> */}
          </div>
        ))}
        {users.length <= 0 && <NoData loading={loading} />}
      </Box>
    </AppPage>
  );
};

export default ActiveUsers;
