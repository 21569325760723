import { useMemo } from 'react';
import { fromDateString } from './../dateMethods';

export function useHuntingPlanWithDate(huntingArea, date) {
  return useMemo(() => {
    if (!huntingArea || !huntingArea.huntingPlans || !date) return undefined;
    const plan = huntingArea.huntingPlans.find(p => {
      const validFrom = fromDateString(p.validFrom);
      const validTo = fromDateString(p.validTo);

      return validFrom <= date && validTo >= date;
    })

    return plan;
  }, [huntingArea, huntingArea && huntingArea.huntingPlans, date]);
}

export function useHuntingPlanWithYear(huntingArea, year) {
  return useMemo(() => {
    if (!huntingArea || !huntingArea.huntingPlans || !year) return undefined;
    const plan = huntingArea.huntingPlans.find(p => {
      return parseInt(year) === parseInt(p.validFrom.split("/")[2]);
    });

    return plan;
  }, [huntingArea, huntingArea && huntingArea.huntingPlans, year]);
}

export function useAllAnimalTypes(huntingArea) {
  return useMemo(() => {
    if (!huntingArea || !huntingArea.huntingPlans) return undefined;
    return huntingArea.huntingPlans.flatMap(p => p.animalTypes);
  }, [huntingArea, huntingArea && huntingArea.huntingPlans]);
}

export function useValidFilterDefaults(huntingArea) {
  const huntingPlan = useHuntingPlanWithDate(huntingArea, new Date());
  return useMemo(() => {
    if (!huntingPlan) return { validFrom: [], validTo: [] };
    const [vfdate, vfmonth, vfyear] = huntingPlan.validFrom.split('/').map(p => parseInt(p));
    const [vtdate, vtmonth] = huntingPlan.validTo.split('/').map(p => parseInt(p));

    return { validFrom: [vfdate, vfmonth, vfyear], validTo: [vtdate, vtmonth, vfyear] };
  }, [huntingPlan]);
}

export function useReselectAnimalTypeAndClass(allAnimalTypes, selectedHuntingPlan, animalTypeId, animalClassId) {
  const animalType = selectedHuntingPlan && selectedHuntingPlan.animalTypes.find(t => t.id == animalTypeId);
  const aanimalType = allAnimalTypes && allAnimalTypes.find(t => t.id == animalTypeId);
  const sapType = selectedHuntingPlan && aanimalType && selectedHuntingPlan.animalTypes.find(t => t.sapAnimalTypeId == aanimalType.sapAnimalTypeId);
  const aanimalClass = sapType && allAnimalTypes.flatMap(t => t.animalClasses).find(t => t.id == animalClassId);
  const sapClass = selectedHuntingPlan && aanimalClass && sapType.animalClasses.find(c => c.sapAnimalClassId == aanimalClass.sapAnimalClassId);

  if (!animalType && sapType) {
    return [sapType.id, sapClass && sapClass.id];
  }
  else if (!animalTypeId && !sapType && (animalTypeId || animalClassId)) {
    return [undefined, undefined];
  }

  return false;
}