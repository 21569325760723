import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
  const AppDialog = (alert) =>{
    return (
        <>
        <Dialog
          open={alert.open}
          onClose={alert.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
  <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alert.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
           { alert.cancel&&<Button onClick={alert.handleClose} color="primary">
              {alert.cancel}
            </Button>
  }
           {alert.ok&& <Button onClick={alert.handleAlertOk} color="primary" autoFocus>
              {alert.ok}
            </Button>
          }
          </DialogActions>
        </Dialog>
      </>
    )
}

export default AppDialog;

