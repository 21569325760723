import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';

const fetchHuntStatistics = (areaId, year) => {
  const url = API_CONSTANTS.getStatistics
    .replace('{huntingAreaId}', areaId)
    .replace('{year}', year);

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

export default fetchHuntStatistics