import React, { useEffect, useState, useRef } from 'react';
import 'leaflet.offline';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import "./marker.css";
import "./speed-dial-mod.css";
/* import {huntingAreaData} from '../../shared/state/hunting-area'; */


import { AppPage } from 'app/shared/components';

import FavoriteIcon from '@material-ui/icons/PinDrop';
import ScheduleIcon from '@material-ui/icons/Schedule';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

// import { actionTypes, fetchHuntingAreaDataAction, receivedHuntingAreaAction } from '../shared/state/hunting-area';

import DeleteIcon from '@material-ui/icons/Delete';

import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import InitializeMapDownload from './map-download'
import MinusMagnifyIcon from '../../shared/components/minus-magnify-icon/minus-magnify-icon'
import PlusMagnifyIcon from '../../shared/components/plus-magnify-icon/plus-magnify-icon'
import FilterIcon from '../../shared/components/filter-icon/filter-icon'

import DownloadAlert from './area-map-download-dialog';

import { baseBlobUrl } from 'environement/environment';
import { Box, Grid, Typography } from '@material-ui/core';
import AppDialog from '../../shared/components/app-dialog/app-dialog';

import {
  requestUpdateUserSettings
} from '../../shared/state/auth-details';

import L from 'leaflet';
import 'leaflet.snogylop';
import {
  LayerGroup,
  LayersControl,
  Map,
  Marker,
  Popup,
  TileLayer,
  CircleMarker,
  GeoJSON,
} from 'react-leaflet';

import { useDispatch } from 'react-redux';
import {
  can,
  permissions as permissionsType
} from 'app/shared/services/app-permissions/app-permissions';

import useStyle from './area-map-style';
import { AreaMapMarker } from '../area-map-marker/area-map-marker';
import useQuery, { additionalQuery, pickQueryOptions } from 'app/shared/hooks/useQuery.js';
import useUserSettings from 'app/shared/hooks/useUserSettings.js';
import useOnline from 'app/shared/hooks/useOnline.js';
import useHuntingArea from 'app/shared/hooks/useHuntingArea.js';
import { fetchReservableTypes, requestAddMarker, requestRemoveMarker } from '../shared/state/markers';
import { LocationService } from 'app/shared/services/location/location-service';
import useFormState from 'app/shared/hooks/useFormState';
import { requestAddRecord, requestUpdateRecord } from '../shared/state/hunting-record';
import FilterPopup, { recordFilterOptions } from './filter-popup';
import useRecordFilters from 'app/shared/hooks/useRecordFilters';
import { planFilterOptions } from '../hunt-statistics/filter-popup';
import { useAllAnimalTypes } from './../../shared/hooks/useHuntingPlan';
delete L.Icon.Default.prototype._getIconUrl;


L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

InitializeMapDownload(L);

export const AreaMap = props => {
  const { BaseLayer, Overlay } = LayersControl;
  const { match, history } = props;
  const [query, setQuery] = useQuery();
  const planQueryBack = pickQueryOptions(query, planFilterOptions);
  const mapQueryBack = pickQueryOptions(query, recordFilterOptions);
  const userSettings = useUserSettings();
  const [settingsHolder] = useState({});
  useEffect(() => {
    settingsHolder.userSettings = userSettings;
  }, [userSettings]);
  const [recordFormData, , , clearRecordFormData] = useFormState('huntRecord');
  const { huntingArea, huntingRecords, markers } = useHuntingArea(match.params.id);
  const dispatch = useDispatch();
  const styles = useStyle();
  const mapProps = {
    lat: 47.4102655501636,
    lng: 13.522536315108661,
    zoom: 14
  };
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const permissions = huntingArea && huntingArea.permissions;
  const animalTypes = useAllAnimalTypes(huntingArea);

  const filteredRecords = useRecordFilters(huntingRecords, huntingArea);

  let features = huntingArea && [
    ...markers,
    ...filteredRecords
      .map(r => ({ ...r, geometry: r.coordinates ? JSON.parse(r.coordinates) : null, type: "HuntRecord" })).filter(r => !!r.geometry),
  ];

  const isOnline = useOnline();

  const refMap = useRef(null);
  const { t } = useTranslation();

  const luftbild = useRef(null);

  const reviergrenze = useRef(null);

  /***************Some Map Styles************************/
  let areaStyle = {
    color: '#888888',
    fillColor: '#888888',
    weight: 0,
    fillOpacity: 0.7
  };
  let style_steige = {
    "color": "#dcb26a",
    "fillColor": "#FFFFFF",
    "dashArray": '2, 4',
    "weight": 1,
    "opacity": 0.8,
    "fillOpacity": 0.8
  };
  let style_wege = {
    "color": "#111111",
    "fillColor": "#FFFFFF",
    // "dashArray": '2, 4',
    "weight": 1,
    "opacity": 0.6,
    "fillOpacity": 0.3
  };
  /***************End some Map Styles************************/
  const [center, setCenter] = useState([mapProps.lat, mapProps.lng]);
  let [marker, setMarker] = useState(null);

  const useMapForHuntRecord = query.chooseLocation === 'true';

  const [zoom, setZoom] = useState(16);
  const [openMarkerForm, setOpenMarkerForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (huntingArea && huntingArea.geoJsonBorder) {
      let defCenter = [47.4102655501636, 13.522536315108661];
      if (huntingArea.geoJsonBorder.bbox && huntingArea.geoJsonBorder.bbox.length) {
        try {
          let bbox = huntingArea.geoJsonBorder.bbox;
          let center = [(bbox[3] + bbox[1]) / 2, (bbox[0] + bbox[2]) / 2];
          if (!query.feature) setCenter(center);
        } catch (ex) {
          if (!query.feature) setCenter(defCenter);
        }
      } else {
        try {
          let bbox = huntingArea.geoJsonBorder.features[0].geometry.coordinates[0][0][0];
          let cordsLength = huntingArea.geoJsonBorder.features[0].geometry.coordinates.length;
          let length = Math.ceil(cordsLength / 2);
          let bbox2 = huntingArea.geoJsonBorder.features[0].geometry.coordinates[length - 1][0][0];
          let center = [bbox[1] + (bbox2[1] - bbox[1]) / 2, bbox[0] + (bbox2[0] - bbox[0]) / 2];
          if (!query.feature) setCenter(center);
        } catch (ex) {
          if (!query.feature) setCenter(defCenter);
        }

      }
      setLoading(false);
    }
  }, [huntingArea, JSON.stringify(query)]);

  useEffect(() => {
    dispatch(fetchReservableTypes());
  }, [])

  const [myLocation, setMyLocation] = useState(null);
  const [accuracy, setAccuracy] = useState(20);

  useEffect(() => {
    const locationService = new LocationService();
    locationService.subscribeToLocation(({ location, accuracy }) => {
      setMyLocation(location);
      setAccuracy(accuracy);
    })
    locationService.start();
    return () => {
      locationService.clearWatch();
      locationService.clearPermissions();
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [useMap, setUseMap] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  function updateMapLayerChoice(layer, choice) {
    const updatedSettings = settingsHolder.userSettings.map(s => ({ ...s }));
    updatedSettings[updatedSettings.findIndex(s => s.key === layer)].value = choice;
    dispatch(requestUpdateUserSettings(updatedSettings));
  }

  const handleClose = () => {
    setOpen(false);
  };
  const [deleteAlert, setDeleteAlert] = useState({ open: false, id: 0, text: null, isNewAdded: false });

  function toDataUrl(url, fileName) {
    ;
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        // callback(reader.result);
        let img = reader.result
          ;
        img = "data:image/png;base64" + img.split(';base64')[1];
        ;
        localStorage.setItem(fileName, img);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url + fileName + '.png');
    xhr.responseType = 'blob';
    xhr.send();
  }

  /** Marker Actions */
  let LeafIcon = L.Icon.extend({
    options: {
      //shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      iconSize: [40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    }
  });

  let LeafDivIcon = L.DivIcon.extend({
    options: {
      iconSize: [30, 40],
      iconAnchor: [15, 40],
      popupAnchor: [0, -40],
    }
  });

  const defaultIcon = new LeafIcon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
  });

  let dynamicMarkers = t('markerTypes', { returnObjects: true });
  const [markerImageOptions, setMarkerImageOptions] = useState([]);
  const baseMarkerUrl = baseBlobUrl + "$web/markers/";
  useEffect(() => {
    if (LeafIcon) {
      let markerImageOption2 = [];
      let i = 1;
      for (var item in dynamicMarkers) {

        let option = {
          id: item, name: dynamicMarkers[item], image: baseMarkerUrl + item + '.png', icon: new LeafIcon({
            iconRetinaUrl: baseMarkerUrl + item + '.png',
            iconUrl: baseMarkerUrl + item + '.png',
          })
        }
        markerImageOption2.push(option);
        i++;
      }
      setMarkerImageOptions(markerImageOption2)
    }
  }, [props]);


  useEffect(() => {
    if (useMapForHuntRecord && recordFormData && recordFormData.value) {
      setMarker({ icon: setHuntRecordImage(recordFormData.value.animalTypeId) });
    }
  }, useMapForHuntRecord, recordFormData);


  function setMarkerImage(id) {
    if (id) {
      let icon = defaultIcon;
      markerImageOptions.forEach(element => {
        if (element.id === id) {
          icon = element.icon;
        }
      });
      return icon;
    }
    else {
      return defaultIcon;

    }

  }


  function setHuntRecordImage(id) {
    let _img = getHuntRecordAnimalIcon(id);
    if (_img) {

      return new LeafIcon({
        className: 'filter',
        iconRetinaUrl: baseMarkerUrl + _img,
        iconUrl: baseMarkerUrl + _img,
      })
    } else {
      return defaultIcon;
    }

  }

  function getHuntRecordAnimalIcon(id) {
    if (animalTypes) {
      let animal = animalTypes.find(a => a.id == id);
      if (animal && animal.icon) {
        return animal.icon.charAt(0).toUpperCase() + animal.icon.slice(1)
      }
    }
    return null;
  }

  function onMapClick(event) {
    if (useMap || (useMapForHuntRecord && recordFormData && recordFormData.value)) {
      setMarker({ ...marker, geometry: { ...event.latlng } });
    }
  }

  function onMapDrag(event) {
    if (useMap || (useMapForHuntRecord && recordFormData && recordFormData.value)) {
      setMarker({ ...marker, geometry: { ...event.target._latlng } });
    }

  }

  function onBack() {
    if (useMapForHuntRecord) {
      if (recordFormData && recordFormData.value) {
        if (query.recordBackTo) {
          props.history.replace(`/hunting-area/${match.params.id}/records/${!recordFormData.value.id.toString().startsWith('generated') ? recordFormData.value.id : 'new'}?backTo=${query.recordBackTo}${additionalQuery(mapQueryBack)}`);
        } else {
          props.history.replace(`/hunting-area/${match.params.id}/records/${!recordFormData.value.id.toString().startsWith('generated') ? recordFormData.value.id : 'new'}${additionalQuery(mapQueryBack, true)}`);
        }
      }
    } else if (query.backTo == 'record') {
      if (query.recordBackTo) {
        props.history.replace(`/hunting-area/${match.params.id}/records/${query.feature}?backTo=${query.recordBackTo}`);
      } else {
        props.history.replace(`/hunting-area/${match.params.id}/records/${query.feature}`);
      }
    } else if (query.backTo == 'plan') {
      props.history.replace(`/hunting-area/${match.params.id}/plan${additionalQuery(planQueryBack, true)}`);
    } else if (query.backTo == 'shooting-list') {
      props.history.replace(`/hunting-area/${match.params.id}/shooting-list${additionalQuery(mapQueryBack, true)}`);
    } else {
      props.history.replace(`/hunting-area/overview`);
    }
  }


  function handleAddMarker() {
    setOpenMarkerForm(true);
    setOpen(false);
  }

  function handleAddHuntRecord() {
    props.history.push(`/hunting-area/${match.params.id}/records/new?backTo=map${additionalQuery(mapQueryBack)}`);
  }

  function onSaveMarker(marker) {
    if (!marker.icon) {
      let icon = setMarkerImage(marker.subType);
      marker = { ...marker, icon };
    }
    if (marker.geometry) {
      dispatch(requestAddMarker({
        ...marker,
        geometry: `[${marker.geometry.lat}, ${marker.geometry.lng}]`,
        id: 'generated_' + Math.floor(Math.random() * 10000),
        huntingAreaId: huntingArea.id,
        type: 'Marker',
      }));
      setMarker(null);
      setOpenMarkerForm(false);
      setUseMap(false);
    } else {
      setMarker(marker);
      setOpenMarkerForm(false);
      setUseMap(true);
    }
  }
  function onMarkerCancel() {
    setMarker(null);
    setOpenMarkerForm(false);
    setUseMap(false);
  }


  function onDeleteMarker(id) {
    dispatch(requestRemoveMarker({ id }));
  }

  const showMyLocation = userSettings.find(s => s.key === 'location').value === '1';


  /***************************************End Service************************************** */

  /********************  Download map************************/
  let [downloadMapMessage, setDownloadMapMessage] = useState(null);
  const [openDownloadAlert, setOpenDownloadAlert] = useState(false);
  const [isDownloadCompeleted, setIsDownloadCompeleted] = useState(false);
  useEffect(() => {
    if (luftbild && luftbild.current) {
      luftbild.current.leafletElement.on('seedprogress', function (seedData) {
        let percent = 100 - Math.floor(seedData.remainingLength / seedData.queueLength * 100);
        downloadMapMessage = percent + '% ' + t("downloadAlert.downloadProgressMessage");
        setPCompleted(percent);
        setDownloadMapMessage(downloadMapMessage)
      });
      luftbild.current.leafletElement.on('seedend', function (seedData) {
        setIsDownloadCompeleted(true);
        setDownloadMapMessage(t("downloadAlert.downloadCompleteMessage"));

      });
    }

  }, [luftbild.current]);

  function handleDownloadAlertStart() {
    L._stopDownloadingofHA = false;
    let bound = reviergrenze.current.leafletElement.getBounds();  ///huntingArea.geoJsonBorder.bbox;
    // var bound = L.latLngBounds(L.latLng(-80,-180), L.latLng(85,180));
    luftbild.current.leafletElement.seed(bound, 12, 17);
  }

  function handleDownloadAlertCancel() {
    setOpenDownloadAlert(false);
    setPCompleted(0);
    setDownloadMapMessage(null);
    L._stopDownloadingofHA = true;
  }

  function download() {
    if (pCompleted == 0)
      setOpenDownloadAlert(true);

  }; //download

  const [pCompleted, setPCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progress = React.useRef(() => { });
  React.useEffect(() => {
    progress.current = () => {
      if (pCompleted > 100) {
        setPCompleted(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setPCompleted(pCompleted + diff);
        setBuffer(pCompleted + diff + diff2);
      }
    };
  }, []);

  function setZoomLevel(isUp) {
    let currentZoomLevel = refMap.current.leafletElement.getZoom();
    if (isUp) {
      if (!isOnline) {
        currentZoomLevel < 17 && refMap.current.leafletElement.setZoom(currentZoomLevel + 1)
      } else
        currentZoomLevel < 19 && refMap.current.leafletElement.setZoom(currentZoomLevel + 1)
    }
    else {
      refMap.current.leafletElement.setZoom(currentZoomLevel - 1);
    }
  }

  function addHuntRecordOnMap() {
    if (!recordFormData || !recordFormData.value) return;
    const record = { ...recordFormData.value };
    record.coordinates = `[${marker.geometry.lat}, ${marker.geometry.lng}]`;
    delete record.location;
    delete record.changeLocation;
    if (record.id.toString().startsWith('generated')) dispatch(requestAddRecord(record));
    else dispatch(requestUpdateRecord(record));
    clearRecordFormData();
    setQuery({ ...query, backTo: 'list', chooseLocation: undefined });
    setMarker(null);
  }

  function onCancelHuntRecord() {
    onBack();
  }

  function viewHuntRecord(r) {
    if (useMapForHuntRecord || useMap)
      return;

    history.push(`/hunting-area/${r.huntingAreaId}/records/${r.id}?backTo=map${additionalQuery(mapQueryBack)}`)
  }

  function showLayer(layer) {
    const layerSetting = userSettings.find(s => s.key === layer);
    return layerSetting && layerSetting.value === '1';
  }

  function hasFutureReservation(feature) {
    return !!feature.nextReservation;
  }

  return (
    <AppPage
      headerTitle={t("huntingArea.areaMap")}
      headerSubTitle={huntingArea ? huntingArea.name : ''}
      headerLeft={
        <IconButton
          color='inherit'
          aria-label='back'
          onClick={onBack}
          edge='start'
        >
          <ArrowBackIcon />
        </IconButton>
      }
      loading={loading}
    >{
        loading ? <div className="pls-wt" >Please wait ...</div> :

          <div className={styles.root}>
            <div>
              <Map
                onclick={onMapClick}
                center={center}
                className={styles.root}
                zoom={zoom}
                ref={refMap}
                zoomControl={false}
                whenReady={({ target: map }) => {
                  const findMapLayer = (name) => {
                    const mapLayers = t('mapLayer', { returnObjects: true });
                    return Object.keys(mapLayers).find(k => mapLayers[k] === name)
                  }
                  map.on('overlayadd', ({ name }) => {
                    updateMapLayerChoice(findMapLayer(name), '1');
                  })
                  map.on('overlayremove', ({ name }) => {
                    updateMapLayerChoice(findMapLayer(name), '0');
                  })
                  const featureToJumpTo = features.find(f => f.id == query.feature);
                  if (featureToJumpTo) setCenter(featureToJumpTo.geometry);
                }
                }
              >
                <LayersControl position='topleft'>
                  <BaseLayer checked name={t('mapLayer.base')}>
                    <TileLayer
                      ref={luftbild}
                      minZoom={1}
                      maxZoom={18}
                      tms={false}
                      // subdomains={['maps', 'maps1', 'maps2', 'maps3']}
                      continuousWorld={false}
                      noWrap={false}
                      crossOrigin={true}
                      // Offline
                      useCache={true}
                      // url='https://{s}.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg'
                      url='https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg'
                    />
                  </BaseLayer>
                  <Overlay checked={showLayer("places")} name={t('mapLayer.places')}>
                    <TileLayer
                      minZoom={1}
                      maxZoom={18}
                      tms={false}
                      // subdomains={['maps', 'maps1', 'maps2', 'maps3']}
                      continuousWorld={false}
                      // url='https://{s}.wien.gv.at/basemap/bmapoverlay/normal/google3857/{z}/{y}/{x}.png'
                      url='https://mapsneu.wien.gv.at/basemap/bmapoverlay/normal/google3857/{z}/{y}/{x}.png'
                    />
                  </Overlay>
                  <Overlay checked={showLayer("huntingArea")} name={t('mapLayer.huntingArea')}>
                    <LayerGroup >
                      {huntingArea.geoJsonBorder && (
                        <GeoJSON
                          test-id="jsonborder"
                          data={huntingArea.geoJsonBorder}
                          ref={reviergrenze}
                          style={areaStyle}
                          invert={true}
                        />
                      )}
                    </LayerGroup>
                  </Overlay>
                  <Overlay checked={showLayer("huntRecord")} name={t('mapLayer.huntRecord')}>
                    <LayerGroup>
                      {!marker && features.length && features.map(
                        (f, i) => f.type === 'HuntRecord' && f.geometry && (
                          <React.Fragment>
                            <Marker
                              key={'m' + i}
                              draggable={false}
                              onDragend={() => { }}
                              onClick={() => { viewHuntRecord(f) }}
                              position={f.geometry}
                              icon={setHuntRecordImage(f.animalTypeId)}//{f.subType==1?greenIcon:f.subType==2?redIcon:defaultIcon}
                              ref={null}
                            >
                            </Marker>
                            {f.id == query.feature && <Marker
                              key={'mo' + i}
                              draggable={false}
                              interactive={false}
                              position={f.geometry}
                              icon={new LeafDivIcon({
                                className: 'marker',
                                html: '<div class="top"><div class="outline"></div>'
                              })}
                            >
                            </Marker>}
                          </React.Fragment>
                        )
                      )}
                    </LayerGroup>
                  </Overlay>
                  <Overlay checked={showLayer("marker")} name={t('mapLayer.marker')}>
                    <LayerGroup>
                      {features.length && features.map(
                        (f, i) =>
                          f.type === 'Marker' && <React.Fragment>
                            <Marker
                              key={'m' + i}
                              draggable={false}
                              onDragend={() => { }}
                              position={f.geometry}
                              icon={setMarkerImage(f.subType)}//{f.subType==1?greenIcon:f.subType==2?redIcon:defaultIcon}
                              ref={null}
                            >
                              {!marker && <Popup key={'p' + i} minWidth={90}>
                                <Box p={2}>
                                  <Grid item>
                                    <Grid container spacing={1}>

                                      <Grid item>
                                        <Typography variant='h5'>{t(`markerTypes.${[f.subType]}`)}</Typography>
                                        <Typography variant='caption'>
                                          {f.name}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant='caption' color='textSecondary'>  {f.comment} </Typography>
                                      </Grid>
                                      <Grid container>
                                        {can(permissionsType.huntingArea.viewReservations, permissions) && f.reservable && <Button style={{ color: '#006633' }} color="primary" size='small' component={Link} to={`/hunting-area/${match.params.id}/reservations?expanded=${f.id}&backTo=map`}>{t('reservation.reservations')}</Button>}
                                        <DeleteIcon titleAccess="delete icon" className="delete-marker-link" onClick={(e) => {
                                          e.preventDefault();
                                          setDeleteAlert({ open: true, text: t("markerActions.confirmDelete"), ok: t("markerActions.delete"), cancel: t("markerActions.cancel"), id: f.id, isNewAdded: f.isNewAdded })

                                        }} />
                                      </Grid>

                                    </Grid>
                                  </Grid>
                                </Box>

                              </Popup>}
                            </Marker>
                            {hasFutureReservation(f) && <Marker
                              key={'mo' + i}
                              draggable={false}
                              interactive={false}
                              position={f.geometry}
                              icon={new LeafDivIcon({
                                className: 'marker',
                                html: '<div class="top"><div class="outline"></div>'
                              })}
                            >
                            </Marker>}
                          </React.Fragment>
                      )}
                    </LayerGroup>
                  </Overlay>
                  <LayerGroup>
                  {marker && marker.geometry && (<Marker
                        draggable={true}
                        onDragend={onMapDrag}
                        position={marker.geometry}
                        ref={null}
                        icon={marker.icon}
                      // onClick={onMarkerClick}
                      >
                        <Popup minWidth={90}>
                          <Box p={2}>
                            <Grid item>
                              <Grid container spacing={1}>

                                <Grid item>
                                  <Typography variant='h5'>{t(`markerTypes.${[marker.subType]}`)}</Typography>
                                  <Typography variant='caption'>
                                    {marker.name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant='caption' color='textSecondary'>  {marker.comment} </Typography>

                                </Grid>

                              </Grid>
                            </Grid>
                          </Box>
                        </Popup>
                      </Marker>)
                      }
                  </LayerGroup>
                  <Overlay checked={showLayer("location")} name={t('mapLayer.location')}>
                    <LayerGroup>
                      {myLocation && showMyLocation &&
                        <CircleMarker center={myLocation} color="blue" radius={accuracy}>
                          <Popup>{"your position"}</Popup>
                        </CircleMarker>
                      }
                    </LayerGroup>
                  </Overlay>
                  {huntingArea.geoJsonTrails && (
                    <Overlay checked={showLayer("trails")} name={t('mapLayer.trails')}>
                      <LayerGroup>

                        <GeoJSON
                          data={huntingArea.geoJsonTrails}
                          style={(feature) => {

                            if (feature.properties.inhalt == '2') {
                              return style_wege;
                            } else {
                              return style_steige;
                            }
                          }}
                        />

                      </LayerGroup>
                    </Overlay>
                  )}
                  <LayerGroup>
                    <div className=" easy-button-container leaflet-control dwn-position">
                      <Button onClick={download} className="MuiFab-root map-icon-custom"
                      >
                        <CloudDownload
                          style={{ color: "white" }}

                        >
                        </CloudDownload></Button>

                    </div>
                  </LayerGroup>
                  <LayerGroup>
                    <div className="  leaflet-control zoom-position">
                      <Button className="MuiFab-root map-icon-custom"
                        onClick={(e) => { e.preventDefault(); setZoomLevel(true) }}
                      >
                        <PlusMagnifyIcon /></Button>
                      <Button className="MuiFab-root map-icon-custom"
                        onClick={(e) => { e.preventDefault(); setZoomLevel(false) }}
                        style={{ marginTop: "6px" }}>
                        <MinusMagnifyIcon /></Button>
                    </div>
                  </LayerGroup>
                  {/* //=============================================== */}
                  <LayerGroup>
                    <div className="leaflet-control filter-position">
                      <Button className="map-filtericon-custom"
                        onClick={(e) => { setOpenFilterPopup(true) }}
                        style={{ marginTop: "64px", marginLeft: "10px" }}>
                        <FilterIcon style={{ fill: "#fff" }} /></Button>
                    </div>
                  </LayerGroup>
                  {/* //=============================================== */}
                </LayersControl>
              </Map>
            </div>
            <AppDialog
              {...deleteAlert}
              handleClose={() => { setDeleteAlert({ open: false, text: null, id: 0, isNewAdded: false }) }}
              handleAlertOk={() => { onDeleteMarker(deleteAlert.id, deleteAlert.isNewAdded); setDeleteAlert({ open: false, text: null, id: 0, isNewAdded: false }) }}
            >

            </AppDialog>
            <div style={{ display: openMarkerForm ? "block" : "none" }}>
              <AreaMapMarker
                open={openMarkerForm}
                onSaveMarker={onSaveMarker}
                markerImageOptions={markerImageOptions}
                onMarkerCancel={onMarkerCancel}
              />
            </div>

            <div>
              <DownloadAlert
                open={openDownloadAlert}
                isDownloadCompeleted={isDownloadCompeleted}
                handleDownloadAlertStart={handleDownloadAlertStart}
                handleDownloadAlertCancel={handleDownloadAlertCancel}
                downloadMapMessage={downloadMapMessage}
                pCompleted={pCompleted}
                buffer={buffer}
                amount={40}
                isOnline={isOnline}
              />
            </div>
            <Backdrop open={open} />
            <div style={{ display: openMarkerForm || useMap | useMapForHuntRecord ? "none" : "block" }}>
              {(can(permissionsType.huntingArea.addFeatures, permissions)) &&
                <SpeedDial
                  ariaLabel="SpeedDial tooltip"
                  className={styles.speedDial}
                  style={{ right: "8px", bottom: "80px", position: "fixed" }}
                  icon={<SpeedDialIcon />}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >

                  {can(permissionsType.huntingArea.addFeatures, permissions) &&
                    <SpeedDialAction
                      key="Marker"
                      className="add-marker-icon"
                      icon={<FavoriteIcon />}
                      tooltipTitle={t("markerActions.addMarker")}
                      tooltipOpen
                      onClick={handleAddMarker}
                    />}
                  {can(permissionsType.huntingArea.addHuntRecord, permissions) &&
                    animalTypes &&
                    <SpeedDialAction
                      key="HuntRecord"
                      className="hunt-record-icon"
                      icon={<GpsFixedIcon />}
                      tooltipTitle={t("huntRecord.addHuntRecord")}
                      tooltipOpen
                      onClick={handleAddHuntRecord}
                    />}
                  {can(permissionsType.huntingArea.viewReservations, permissions) &&
                    <SpeedDialAction
                      key="Marker"
                      className="add-marker-icon"
                      icon={<ScheduleIcon />}
                      tooltipTitle={t("reservation.reservations")}
                      tooltipOpen
                      onClick={() => {
                        history.push(`/hunting-area/${match.params.id}/reservations?backTo=map`)
                      }}
                    />}
                </SpeedDial>
              }
            </div>
            {!openMarkerForm && useMap && marker && <div className="marker-save-btn">
              {marker.geometry &&
                (<><Button
                  onClick={() => onSaveMarker(marker)}
                  size='large'
                  variant='contained'
                  color='primary'
                  className="fl-rgt"
                >
                  {t("markerActions.saveMarker")}
                </Button>

                  <Button
                    onClick={onMarkerCancel}
                    size='large'
                    variant='outlined'
                    className="marker-cancel-btn"
                  >
                    {t("markerActions.cancel")}
                  </Button>
                </>
                )
              }{
                !marker.geometry && <div className="marker-hint-msg">
                  {t("markerActions.clickAnyWhere")}
                  <ArrowUpward className="marker-hint-arrow"></ArrowUpward>
                </div>
              }
            </div>
            }
            {
              animalTypes ?
                animalTypes.map((animal, i) => {
                  let link = baseMarkerUrl + animal.icon.charAt(0).toUpperCase() + animal.icon.slice(1);
                  return <img key={"imgg" + i} style={{ display: "none" }} src={link} />
                }) : null
            }
            {useMapForHuntRecord && <div className="marker-save-btn">
              {marker && marker.geometry &&
                (<><Button
                  onClick={addHuntRecordOnMap}
                  size='large'
                  variant='contained'
                  color='primary'
                  className="fl-rgt"
                >
                  {t("buttonText.submit")}
                </Button>

                  <Button
                    onClick={onCancelHuntRecord}
                    size='large'
                    variant='outlined'
                    className="marker-cancel-btn"
                  >
                    {t("buttonText.cancel")}
                  </Button>
                </>
                )
              }{
                marker && !marker.geometry && <div className="marker-hint-msg">
                  {t("markerActions.clickAnyWhere")}
                  <ArrowUpward className="marker-hint-arrow"></ArrowUpward>
                </div>
              }
            </div>
            }
          </div>
      }
      {openFilterPopup && huntingArea && <FilterPopup isOpen={openFilterPopup} huntingAreaId={huntingArea.id} onClose={() => setOpenFilterPopup(false)} />}
    </AppPage>
  );
};

const mapStateToProps = state => ({
  userSettings: state.global && state.global.authDetails && state.global.authDetails.userInfo && state.global.authDetails.userInfo.userSettings,
});

export default withRouter(connect(mapStateToProps, {})(AreaMap));