export const API_CONSTANTS = {
  getOrganisations: '/mock-data/organisations.json',
  huntingAreaPerOrganisation: '/mock-data/org-hunting-areas.json',
  updateUserSettings: '/users/updateusersettings',
  roles: '',
  huntingareas: '/huntingareas/',
  huntingarea: '/huntingareas/{id}',
  huntingmap: '/huntingareas/',
  accessRequests: '/users/huntingrequests',
  accessRequsetsCount: '/users/huntingrequests/count',
  accessRequestsById: '/users/huntingrequest/{id}',
  createAccess: '/roles/user/huntingarea',
  activeUsers: '/huntingareas/{id}/users',
  huntingAreaAccessRoles: '/roles/assignmentlevel/{id}',
  requestAccessStatusChange: '/users/huntingrequests/{requestId}/changestatus',
  huntingAreaPictureUpdate: '/huntingareas/updatepicture',
  organizations: '/organizationunits',
  createHuntingRequest: '/users/huntingrequest',
  revokeUserAccessHA: 'users/{userId}/huntingareas/{huntingAreaId}',
  usersByKey: '/users/SearchUsers/{key}',
  huntRecord: '/huntrecord/',
  getHunters: '/huntrecord/hunters',
  addHunters: '/huntrecord/hunters/add',
  updateHunters: '/huntrecord/hunters/update',
  deleteHunters: '/huntrecord/hunters/delete',
  getStatistics: '/huntingplan/{huntingAreaId}/{year}',
  getAnimalTypes: '/HuntRecord/GetAllAnimalType/',
  userpermission: 'users/detail/',
  reservableTypes: 'featurereservations/reservablefeaturesubtypes',
  reservationsHAFeature: '/huntingareas/{id}/feature/{featId}/reservations',
  reservations: '/featurereservations',
  deleteAccount: '/users/deleteuseraccount',
};
