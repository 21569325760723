import React from 'react';
import ReactDOM from 'react-dom';

import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import { render, unmountComponentAtNode } from "react-dom";
import App from './app/app';
import {initialiseAddToHomeService} from './app/shared/services';
// import {
//   initializeAuth,
//   runAuth
// } from './app/shared/services/app-auth/app-auth';
// // import 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';

import * as serviceWorker from './serviceWorker';

/** Load the i18n */
import { initializeI18n } from './i18n';

const initializeApp = async () => {  
  initialiseAddToHomeService();

  await initializeI18n().catch(e => {
    console.error('Error in Language initialization',e);
  });


  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>,
    document.getElementById('root')
  );
};

initializeApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
