import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    color: '#888888',
    fontSize: '1.5rem',
    textAlign: 'center'
  }
}));

export default useStyles;
