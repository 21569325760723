import { makeStyles } from '@material-ui/core/styles';
import { relative } from 'path';

const useStyles = makeStyles(theme => ({
  areasItem: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 180,
    position: 'relative'
  },
  mediaSection: {
    position: 'relative'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 0
    }
  },
  picChange: {
    position: 'absolute',
    right: 16,
    bottom: 16
  },
  separator: {
    width: '96%'
  },
  shadowbox: {
    "& p": {
      margin: "3px 0",
    }
  }
}));

export default useStyles;
