import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyle from './app-button-style';

const AppButton = props => {
  const classes = useStyle();

  const { loading, onClick, ...rest } = props;

  const buttonClick = !loading ? onClick : () => {};

  return (
    <Button className={classes.root} {...rest} onClick={buttonClick}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size={24} color={props.color} />
        </div>
      )}
      <div>{props.children}</div>
    </Button>
  );
};

export default AppButton;
