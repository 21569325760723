import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MinusMagnifyIcon(props) {
  return (
    <SvgIcon viewBox="0 0 512.00533 512" height="512px" width="512px">
     
     <g
id="minusMag"
>
    <path
     d="m490.667969 512.003906c-5.460938 0-10.925781-2.09375-15.082031-6.25l-161.28125-161.28125c-8.34375-8.34375-8.34375-21.824218 0-30.164062 8.339843-8.34375 21.824218-8.34375 30.164062 0l161.28125 161.277344c8.339844 8.34375 8.339844 21.824218 0 30.167968-4.160156 4.15625-9.621094 6.25-15.082031 6.25zm0 0"
     fill="#fff"
      data-original="#006633"/>
    <path 
    d="m202.667969.00390625c-111.746094 0-202.667969 90.92187475-202.667969 202.66406275 0 111.746093 90.921875 202.667969 202.667969 202.667969 111.742187 0 202.664062-90.921876 202.664062-202.667969 0-111.742188-90.921875-202.66406275-202.664062-202.66406275zm0 0" 
    fill="#fff" 
    data-original="#2196F3" 
    className=""
    style={{fill:"#fff"}} 
     data-old_color="#2196f3"/>
    <path 
    d="m277.332031 224.003906h-149.332031c-11.777344 0-21.332031-9.558594-21.332031-21.335937 0-11.773438 9.554687-21.332031 21.332031-21.332031h149.332031c11.777344 0 21.335938 9.558593 21.335938 21.332031 0 11.777343-9.558594 21.335937-21.335938 21.335937zm0 0" 
    fill="#006633" 
    data-original="#FFF" 
    className="active-path" 
    style={{fill:"#006633"}} 
     data-old_color="#fff"/>  
</g> 

    </SvgIcon>
  );
}

export default MinusMagnifyIcon;
