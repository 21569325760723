import React, { useState } from 'react';
import { Box, Grid, Avatar, Divider, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import useStyles from './active-user-style';
import { AppButton } from 'app/shared/components';

import { revokeHAAccessForUser } from '../shared/services/hunting-area/hunting-area';

const ActiveUser = props => {
  const { user, huntingAreaId, refreshUsers, t } = props;
  const [revokeAccessLoading, setRevoleAccessLoading] = useState(false);
  const classes = useStyles();

  async function revokeAccess() {
    setRevoleAccessLoading(true);
    const { error } = await revokeHAAccessForUser(user.id, huntingAreaId);
    setRevoleAccessLoading(false);

    if (!error) {
      refreshUsers();
    }
  }

  function getFormattedDate(date, by, max){
    try{
      let dateArr = date.split(by);
      if(dateArr.length>2){
        if(max){
          return new Date(dateArr[2],dateArr[1]-1,dateArr[0],23,59,59)
        }else{
          return new Date(dateArr[2],dateArr[1]-1,dateArr[0],0,0,0)
        }
      }
      
      else
       return new Date();
    }catch(ex){
      console.log("for date",date);
      console.error("date format error",ex);
      
    }
    return new Date();
  }
  function isDateValid(){
    let currentDate = (new Date()).getTime();

     let date1 = getFormattedDate(user.validFrom,"-").getTime();
     let date2 = getFormattedDate(user.validTo,"-",true).getTime();
     if(currentDate>=date1&&currentDate<=date2){
       return true;
     }
     return false;
  }

 function GetUserAbrv(){
   let abr=''
   if(user.firstName&&user.firstName.length){
     abr = user.firstName.toUpperCase().substr(0,1);
   }else{
    abr = user.email.toUpperCase().substr(0,1);
   }
   if(user.lastName&&user.lastName.length){
    abr+= user.lastName.toUpperCase().substr(0,1)
   }
   
   return abr;
 }

  return (
    <>
      <Box py={2}>
        <Grid container justify='space-between'>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
  <Avatar className={classes.avatar}>{GetUserAbrv()}</Avatar>
              </Grid>
              <Grid item>
                <Typography variant='h5'>{`${user.firstName} ${user.lastName}`}</Typography>
                <Typography variant='caption' color='textSecondary'>
                  {user.email}
                </Typography>
               <Typography className="active-user-valid" style={!isDateValid()?{color:"red"}:{}} >
               {`${t('general.validFrom')} ${user.validFrom} 
                ${t('general.to')} ${user.validTo}`}
               </Typography>
              </Grid>
             
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <a href={`mailto: ${user.email}`}>
                  <IconButton color='primary'>
                    <MailOutlineIcon />
                  </IconButton>
                </a>
             
              </Grid>
             
              {/* <Grid item>
                <IconButton onClick={revokeAccess} color='secondary'>
                  <RemoveCircleIcon />
                </IconButton>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Box mt={2}>
       
          
          <Grid container justify='flex-end'>
          
            <Grid item>
           
              <AppButton
              data-testid='revokebtn'
                loading={revokeAccessLoading}
                onClick={revokeAccess}
                variant='outlined'
                color='secondary'
              >
               {t('buttonText.revokeAccess')}
              
              </AppButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default ActiveUser;
