import { makeStyles } from '@material-ui/core/styles';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, #ffffff)`,
      color: 'var(--tree-view-color)',
      cursor: 'pointer',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      cursor: 'pointer',
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      // paddingLeft: theme.spacing(0),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    height: 'fit-content',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    // 'background-color':'#006633'
    '&:hover': {
      cursor: 'pointer',
    },
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  typeStyle: {
    'background-color': '#006633',
    color: '#fff',
    padding: '3px 10px 3px 1px',
    'border-bottom': '1px',
    'border-bottom-style': 'solid',
    'border-bottom-color': 'white',
    // width:'50%',
  },
  classStyle: {
    color: '#006633',
    'background-color': '#e9e9e9',
    'border-bottom': '1px',
    'border-bottom-style': 'solid',
    'border-bottom-color': 'grey',
  },
  themeColor: {
    color: '#006633',
  },
  recordStyle: {
    display: 'flex',
    paddingTop: '5px',
    paddingBottom: '5px',
    alignItems: 'center',
    cursor: 'initial',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderColor: '#f9f9f9',
    '& p': {
      height: 'fit-content',
    },
    '& span': {
      height: 'fit-content',
    },
    '& div > div > span': {
      display: 'flex',
      alignItems: 'center',
      height: 'fit-content',
      width: 'fit-content',
      borderRadius: '15px',
      padding: '5px',
      '&:hover': {
        background: '#d9d9d9',
        cursor: 'pointer',
      },
    },
    '& button > p': {
      padding: '3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '15px',
      '&:hover': {
        background: '#d9d9d9',
        cursor: 'pointer',
      },
    },
  },
  labelText: {
    fontWeight: 'inherit',
    width: '50%',
    // flexGrow: 1
  },
  media: {
    width: '25px',
    height: '40px',
    padding: '0px',
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
    'margin-bottom': '4px',
    'margin-left': '8px',
  },
}));
export default useTreeItemStyles;
