import {
  DeleteMarker,
  AddMapMarker,
  ModifyMapLayerChoice
} from 'app/hunting-area/shared/services/hunting-area-map/hunting-area-map';
import {
  createHuntRecord,
  removeHuntReord,
  updateHuntRecord
} from 'app/hunting-area/shared/services/hunt-record/hunt-record';
import { API_CONSTANTS } from 'app/shared/config/api-constants';
export default async function executeOfflineActions() {
  // TODO: REMOVE
  let requestStorage_delete = localStorage.getItem("deleteMarkerRequest");
  let requestStorage_add = localStorage.getItem("addMarkerRequest");
  let requestStorage_addHuntRecord = localStorage.getItem("addHuntRecordRequset");
  let requestStorage_updateHuntRecord = localStorage.getItem("updateHuntRecordRequset");
  let requestStorage_deleteHuntRecord = localStorage.getItem("deleteHuntRecordRequest");
  let requestStorage_updateUserSettings = localStorage.getItem("updateUserSettingRequest");

  let requestList = [];
  if (requestStorage_delete) {
    requestList = JSON.parse(requestStorage_delete);
    if (requestList && requestList.length > 0) {
      var promiseList = [];
      requestList.forEach(function (element) {
        if (element.url)
          promiseList.push(DeleteMarker(element.url));
      });
      Promise.all(promiseList).then(function (values) {
        localStorage.setItem("deleteMarkerRequest", JSON.stringify([]));

      });
    }
  }
  requestList = [];

  if (requestStorage_add) {
    ;
    requestList = JSON.parse(requestStorage_add);
    if (requestList && requestList.length > 0) {
      var promiseList = [];
      requestList.forEach(function (element) {
        if (element.url)
          promiseList.push(AddMapMarker(element));
      });
      Promise.all(promiseList).then(function (values) {
        localStorage.setItem("addMarkerRequest", JSON.stringify([]));

      });
    }
  }
  requestList = [];
  if (requestStorage_addHuntRecord) {
    requestList = JSON.parse(requestStorage_addHuntRecord);
    if (requestList && requestList.length > 0) {
      var promiseList = [];
      requestList.forEach(function (element) {
        promiseList.push(createHuntRecord(element));
      });
      Promise.all(promiseList).then(function (values) {
        localStorage.setItem("addHuntRecordRequset", JSON.stringify([]));

      });
    }
  }
  requestList = [];
  if (requestStorage_updateHuntRecord) {
    requestList = JSON.parse(requestStorage_updateHuntRecord);
    if (requestList && requestList.length > 0) {
      var promiseList = [];
      requestList.forEach(function (element) {
        promiseList.push(updateHuntRecord(element));
      });
      Promise.all(promiseList).then(function (values) {
        localStorage.setItem("updateHuntRecordRequset", JSON.stringify([]));

      });
    }
  }
  requestList = [];
  if (requestStorage_deleteHuntRecord) {
    requestList = JSON.parse(requestStorage_deleteHuntRecord);
    if (requestList && requestList.length > 0) {
      var promiseList = [];
      requestList.forEach(function (element) {
        promiseList.push(removeHuntReord(element));

      });
      Promise.all(promiseList).then(function (values) {
        localStorage.setItem("deleteHuntRecordRequest", JSON.stringify([]));

      });
    }
  }

  requestList = [];
  if (requestStorage_updateUserSettings) {
    requestList = JSON.parse(requestStorage_updateUserSettings);
    if (requestList && requestList.length > 0) {
      ModifyMapLayerChoice({ url: API_CONSTANTS.updateUserSettings, requestData: requestList })
        .then(function (values) {
          localStorage.setItem("updateUserSettingRequest", JSON.stringify([]));
        });
    }
  }
}