import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, FormControl, Checkbox, Select, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { AppPage, ImageGallery } from 'app/shared/components';
import PublicIcon from '@material-ui/icons/Public';
import HunterCard from './hunter-card';
import useStyles from './area-hunt-record-style';
import { useTranslation } from 'react-i18next';

import AntSwitch from 'app/shared/components/ant-switch/ant-switch';
import countyConfig from 'app/shared/hunt-config/county-config.json';

import { AppDialog } from 'app/shared/components/';
import { withRouter } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import useHuntingArea from 'app/shared/hooks/useHuntingArea';
import { can, permissions as permissionsType } from 'app/shared/services';
import useHuntingRecord from 'app/shared/hooks/useHuntingRecord';
import { requestAddRecord, requestRemoveRecord, requestUpdateRecord } from '../shared/state/hunting-record';
import useHunters from 'app/shared/hooks/useHunters';
import { requestAddHunter, requestRemoveHunter, requestUpdateHunter } from '../shared/state/hunter';
import { LocationService } from 'app/shared/services/location/location-service';
import useFormState from 'app/shared/hooks/useFormState';
import { fromDateString, toDateString } from 'app/shared/dateMethods';
import useQuery, { additionalQuery, pickQueryOptions } from 'app/shared/hooks/useQuery';
import { recordFilterOptions } from '../area-map/filter-popup';
import { planFilterOptions } from '../hunt-statistics/filter-popup';
import {
  useReselectAnimalTypeAndClass,
  useAllAnimalTypes,
  useHuntingPlanWithDate,
} from './../../shared/hooks/useHuntingPlan';
import { unwrapResult } from '@reduxjs/toolkit';

const HuntRecord = (props) => {
  const { match, history } = props;

  const { huntingAreaId, id } = match.params;
  const { huntingArea } = useHuntingArea(huntingAreaId);

  let { huntingRecord } = useHuntingRecord(id);
  const { hunters } = useHunters();
  const [query] = useQuery();
  const planQueryBack = pickQueryOptions(query, planFilterOptions);
  const mapQueryBack = pickQueryOptions(query, recordFilterOptions);
  const viewRecord = huntingRecord && { ...huntingRecord };
  const county = huntingArea && huntingArea.county;
  const areaName = huntingArea ? huntingArea.name : '';
  const myId = huntingArea && huntingArea.userId;
  const updatePermission = huntingArea && can(permissionsType.huntingArea.updateHuntRecord, huntingArea.permissions);
  const deletePermission = huntingArea && can(permissionsType.huntingArea.deleteHuntRecord, huntingArea.permissions);
  const canModifyAll = huntingArea && can(permissionsType.huntingArea.modifyAllHuntRecords, huntingArea.permissions);

  const onCancelHuntRecord = () => {
    if (query.backTo === 'plan') {
      history.replace(`/hunting-area/${huntingAreaId}/plan${additionalQuery(planQueryBack, true)}`);
    } else if (query.backTo === 'list') {
      history.replace(`/hunting-area/overview`);
    } else if (query.backTo === 'shooting-list') {
      history.replace(`/hunting-area/${huntingAreaId}/shooting-list${additionalQuery(mapQueryBack, true)}`);
    } else {
      history.replace(`/hunting-area/area-map/${huntingAreaId}${additionalQuery(mapQueryBack, true)}`);
    }
  };

  const onChooseLocationClick = () => {
    if (query.backTo && query.backTo !== 'map') {
      history.push(
        `/hunting-area/area-map/${huntingAreaId}?chooseLocation=true&backTo=record&recordBackTo=${query.backTo}`
      );
    } else {
      history.push(`/hunting-area/area-map/${huntingAreaId}?chooseLocation=true${additionalQuery(mapQueryBack)}`);
    }
  };

  const gotoMap = () => {
    history.push(
      `/hunting-area/area-map/${huntingAreaId}?feature=${id}&backTo=record&recordBackTo=${
        query.backTo
      }${additionalQuery(mapQueryBack)}`
    );
  };

  const [myLocation, setMyLocation] = useState(null);
  useEffect(() => {
    const locationService = new LocationService();
    locationService.subscribeToLocation(({ location }) => {
      setMyLocation(location);
    });
    locationService.getPosition();
    return () => locationService.clearPermissions();
  }, []);

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData, saveFormData, clearFormData, storedFormData] = useFormState('huntRecord', {
    value: {
      hunter: null,
      huntingAreaId: null,
      animalCount: 1,
      accessRole: null,
      myLocation: false,
      location: false,
      coordinates: null,
      pictures: [],
      huntingDate: toDateString(new Date()),
      animalTypeId: null,
      animalClassId: null,
      animalAge: null,
      animalWeight: 0,
      animalFallen: '',
      annotation: '',
      userId: 0,
      meatComplaint: null,
      huntingLaw56: null,
      huntingLaw90: null,
      meatUsage: null,
      isMeatComplaint: null,
      examinationDate: toDateString(new Date()),
      personComplaint: '',
      isGreenTemplate: null,
      isDogFound: null,
      isCherishedShooting: null,
      numberOfEnds: '',
      cardId: '',
      hunterName: '',
      transferToAFF: true,
    },
    errors: null,
  });

  const allAnimalTypes = useAllAnimalTypes(huntingArea);
  const selectedHuntingPlan = useHuntingPlanWithDate(huntingArea, fromDateString(formData.value.huntingDate));

  if (!hunters.find((h) => h.cardId == formData.value.cardId)) {
    hunters.push({ cardId: formData.value.cardId, hunterName: formData.value.hunterName });
  }

  const animalTypes = selectedHuntingPlan && selectedHuntingPlan.animalTypes;
  const animalType = animalTypes && animalTypes.find((t) => t.id == formData.value.animalTypeId);
  const animalClasses = animalType && animalType.animalClasses;
  const animalClass = animalClasses && animalClasses.find((a) => a.id == formData.value.animalClassId);

  const onChange = ({ name, value }) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value == null || value == undefined ? '' : value,
      },
    };

    setFormData(updatedFormData);
  };

  const onDateChange = ({ name, value }) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: value == null || value == undefined ? null : toDateString(value),
      },
    };

    setFormData(updatedFormData);
  };

  let examinationDateRange = [];
  if (selectedHuntingPlan && selectedHuntingPlan.validFrom && selectedHuntingPlan.validTo) {
    let validFrom = selectedHuntingPlan.validFrom;
    let validTo = selectedHuntingPlan.validTo;
    validFrom = getFormattedDate(validFrom, '/');
    validTo = getFormattedDate(validTo, '/');
    examinationDateRange = [validFrom, validTo];
  }

  function canPerformActions() {
    if (viewRecord && isViewMode) {
      if (
        (myId === viewRecord.userId && (deletePermission || updatePermission) && !viewRecord.isSynced) ||
        canModifyAll
      ) {
        return true;
      }
    }
    return false;
  }

  const [loading, setLoading] = useState(false);

  function onAnimalChange() {
    let open = false;
    let text = '';
    let value = formData.value.animalAge;
    if (animalClass && (animalClass.minAge || animalClass.maxAge)) {
      if ((animalClass.minAge || animalClass.minAge == 0) && animalClass.minAge > value) {
        open = true;
        text = t('huntRecord.minAgeValidationMsg');
        text = text.replace('$age', animalClass.minAge);
      } else if (animalClass.maxAge && animalClass.maxAge < value) {
        open = true;
        text = t('huntRecord.maxAgeValidationMsg');
        text = text.replace('$age', animalClass.maxAge);
      }
    }
    if (open) {
      setAlert({
        open: open,
        cancel: t('buttonText.close'),
        title: t('huntRecord.alert'),
        text: text,
      });
      value = '';
      const updatedFormData = {
        ...formData,
        value: {
          ...formData.value,
          animalAge: value,
        },
      };
      setFormData(updatedFormData);
    }
  }

  function onHunterChange({ name, value, hunterName }) {
    let card = hunters.find((h) => h.cardId == value);
    if (!card || hunterName) value = { hunterName, cardId: value };
    else value = card;
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: { ...value, hunterName: value.hunterName ? value.hunterName + '/' + value.cardId : '' },
        hunterName: value.hunterName,
        cardId: value.cardId.toString(),
      },
    };

    setFormData(updatedFormData);
  }

  const onAnimalTypeChange = (animalTypeId, animalClassId) => {
    let updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        animalTypeId,
        animalClassId,
      },
    };

    setFormData(updatedFormData);
  };

  const [openHunterCard, setOpenHunterCard] = useState(false);

  function onHunterCardCancel() {
    setEditCard(null);
    setOpenHunterCard(false);
  }

  const reselect = useReselectAnimalTypeAndClass(
    allAnimalTypes,
    selectedHuntingPlan,
    formData.value.animalTypeId,
    formData.value.animalClassId
  );
  useEffect(() => {
    if (!formData.value.examinationDate) return;
    let reselectDate = fromDateString(formData.value.examinationDate);
    while (reselectDate < examinationDateRange[0]) {
      reselectDate.setFullYear(reselectDate.getFullYear() + 1);
    }
    while (reselectDate > examinationDateRange[1]) {
      reselectDate.setFullYear(reselectDate.getFullYear() - 1);
    }
    const reselectDateString = toDateString(reselectDate);

    if (reselect) {
      formData.value.examinationDate = reselectDateString;
      onAnimalTypeChange(reselect[0], reselect[1]);
    } else {
      onDateChange({ name: 'examinationDate', value: reselectDate });
    }
  }, [selectedHuntingPlan]);

  /**
   * Submit the data for the create record
   */

  const submitData = async () => {
    let record = { ...formData.value };
    record.examinationDate = fromDateString(record.examinationDate).toISOString();
    delete record.animalClass;
    delete record.animalType;
    delete record.hunter;
    record.huntingAreaId = parseInt(huntingAreaId);
    const isAddition = record.id ? false : true;
    if (!record.id) {
      record.id = 'generated_' + Math.floor(Math.random() * 10000);
    }
    if (county.indexOf('O') == -1) {
      record.examinationDate = null;
    }

    if (record.dateOfGreenTemplate) {
      record.dateOfGreenTemplate = fromDateString(record.dateOfGreenTemplate).toISOString();
    }

    if (!record.animalAge) {
      record.animalAge = null;
    }
    if (!record.animalWeight) {
      record.animalWeight = null;
    }

    record.userId = myId;
    if (record.location && !record.myLocation && (!isEditMode || formData.value.changeLocation)) {
      saveFormData({ ...formData, value: record });
      onChooseLocationClick(record);
    } else {
      if (record.location) {
        if (record.myLocation) {
          record.coordinates = `[${myLocation[0]}, ${myLocation[1]}]`;
        }
      } else {
        record.coordinates = '';
      }
      delete record.location;
      delete record.changeLocation;
      CreateRecordAPI(record, isAddition);
    }
  };

  function CreateRecordAPI(record, isAddition) {
    if (isAddition) {
      setSnackBar(true);
      dispatch(requestAddRecord(record))
        .then(unwrapResult)
        .then(() => {
          setSnackBarMessage(t('huntRecord.savedMsg'));
          setSnackBar(true);
          setEditMode(false);
          setIsViewMode(true);
          onCancelHuntRecord();
        })
        .catch(() => {
          setSnackBarMessage(t('huntRecord.errorMsg'));
          setSnackBar(true);
        });
    } else {
      dispatch(requestUpdateRecord(record))
        .then(unwrapResult)
        .then(() => {
          setSnackBarMessage(t('huntRecord.updatedMsg'));
          setSnackBar(true);
          setEditMode(false);
          setIsViewMode(true);
          clearFormData();
        })
        .catch(() => {
          setSnackBarMessage(t('huntRecord.errorMsg'));
          setSnackBar(true);
        });
    }
  }

  function resetForm() {
    setFormData({
      value: {
        hunter: null,
        huntingAreaId: parseInt(huntingAreaId),
        animalCount: 1,
        myLocation: false,
        location: false,
        coordinates: '',
        pictures: [],
        huntingDate: toDateString(new Date()),
        animalType: null,
        animalClass: '',
        animalTypeId: 0,
        animalClassId: 0,
        animalAge: 0,
        animalWeight: 0,
        animalFallen: '',
        annotation: '',
        userId: 0,
        meatComplaint: '',
        huntingLaw56: false,
        huntingLaw90: false,
        meatUsage: '',
        isMeatComplaint: false,
        examinationDate: toDateString(new Date()),
        personComplaint: '',
        isGreenTemplate: false,
        isDogFound: false,
        isCherishedShooting: false,
        numberOfEnds: '',
        cardId: '',
        hunterName: '',
        transferToAFF: true,
      },
      errors: null,
    });
  }

  const [snackBarOpen, setSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const setHunterCardList = () => {};

  const isFormValid = () => {
    const { animalClassId, animalTypeId, hunterName, animalAge, animalWeight, animalCount, cardId } = formData.value;
    return (
      animalClassId &&
      animalTypeId &&
      animalClass &&
      hunterName &&
      cardId &&
      animalCount &&
      animalWeight &&
      (!animalClass.minAge || animalAge >= animalClass.minAge) &&
      (!animalClass.maxAge || animalAge <= animalClass.maxAge)
    );
  };

  const [errorMsg, setErrorMsg] = useState(null);
  function onHunterCardSave(card) {
    let fCard;
    if (card.oldHunterCardId) {
      fCard = hunters.filter((c) => c.cardId != card.oldHunterCardId && c.cardId == card.cardId);
    } else {
      fCard = hunters.filter((c) => c.cardId == card.cardId);
    }
    if (fCard && fCard.length !== 0) {
      let msg = t('huntRecord.duplicateCardMsg');
      msg = msg.replace('$number', card.cardId);
      setErrorMsg(msg);
      return 1;
    }
    if (card.oldHunterCardId) {
      dispatch(
        requestUpdateHunter({ hunterName: card.hunterName, cardId: card.cardId, oldCardId: card.oldHunterCardId })
      );
      setEditCard(null);
    } else {
      dispatch(requestAddHunter({ hunterName: card.hunterName, cardId: card.cardId }));
    }
    onHunterChange({ name: 'hunter', value: card.cardId, hunterName: card.hunterName });
    setOpenHunterCard(false);
  }

  function resetHunter() {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        hunterName: null,
        cardId: null,
      },
    };
    setFormData(updatedFormData);
  }

  function onHunterCardDelete(cardId) {
    dispatch(requestRemoveHunter({ cardId }));
    setEditCard(null);
    const sortedHunters = hunters.filter((h) => h.cardId !== cardId);
    sortedHunters.sort((h) => h.hunterName);
    if (sortedHunters.length > 0) {
      onHunterChange({ name: 'hunter', hunterName: sortedHunters[0].hunterName, value: sortedHunters[0].cardId });
    } else {
      resetHunter();
    }
    setOpenHunterCard(false);
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  };

  const [editCard, setEditCard] = useState(null);

  function onEditClick(cardId) {
    let card = hunters.find((item) => item.cardId == cardId);
    setEditCard({ ...card, oldHunterCardId: card.cardId });
    setOpenHunterCard(true);
  }

  const defaultProps = {
    getOptionLabel: (option) => option.name,
  };

  const defaultCountyProps = {
    getOptionLabel: (option) => option,
  };

  const defaultPropsForHunter = {
    getOptionLabel: (option) => option.hunterName,
  };

  const handleLocationChange = (name) => (event) => {
    if (!myLocation && name == 'myLocation') {
      setAlert({
        open: true,
        cancel: t('buttonText.close'),
        title: t('huntRecord.alert'),
        text: t('huntRecord.locationNotShared'),
      });
      return;
    }
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: event.target.checked,
      },
    };
    setFormData(updatedFormData);
  };

  const handleCheckedChange = (name) => (event) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: event.target.checked,
      },
    };
    setFormData(updatedFormData);
  };

  const handleChangeLocationChange = (event) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        changeLocation: event.target.checked,
      },
    };
    setFormData(updatedFormData);
  };
  function setCheckBoxTrue(name) {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        [name]: true,
      },
    };
    setFormData(updatedFormData);
  }

  const inputLabel = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const inputLabel3 = React.useRef(null);
  const inputLabel4 = React.useRef(null);
  const inputLabel5 = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [labelWidth2, setLabelWidth2] = React.useState(0);
  const [labelWidth3, setLabelWidth3] = React.useState(0);
  const [labelWidth4, setLabelWidth4] = React.useState(0);
  const [labelWidth5, setLabelWidth5] = React.useState(0);

  const setPictures = (pictures) => {
    const updatedFormData = {
      ...formData,
      value: {
        ...formData.value,
        pictures,
      },
    };
    setFormData(updatedFormData);
  };

  React.useEffect(() => {
    if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
    if (inputLabel2.current) setLabelWidth2(inputLabel2.current.offsetWidth);
    if (inputLabel3.current) setLabelWidth3(inputLabel3.current.offsetWidth);
    if (inputLabel4.current) setLabelWidth4(inputLabel4.current.offsetWidth);
    if (inputLabel5 && inputLabel5.current) setLabelWidth5(inputLabel5.current.offsetWidth);
  }, [huntingRecord, huntingArea, loading]);

  const [alert, setAlert] = useState(null);
  function handleDialogClose() {
    setAlert(null);
  }
  const [actionTypes, setActionType] = useState(null);
  function handleAlertOk() {
    switch (actionTypes) {
      case 'delete': {
        dispatch(requestRemoveRecord(formData.value));
        handleDialogClose();
        onCancelHuntRecord();
      }
      default: {
      }
    }
  }

  function getFormattedDate(date, by) {
    let dateArr = date.split(by);
    if (dateArr.length > 2) return new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
    else return new Date();
  }

  function getFormattedDateFromUTC(date) {
    if (!date) return date;
    let dateArr = date.split('T')[0].split('-');
    if (dateArr.length > 2) return new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
    else return new Date();
  }

  const [isViewMode, setIsViewMode] = useState(false);

  function fillFormData(huntingRecord) {
    let hunter = {
      hunterName: huntingRecord.hunterName + '/' + huntingRecord.cardId,
      cardId: huntingRecord.cardId,
    };

    if (myId != huntingRecord.userId)
      setHunterCardList([{ hunterName: huntingRecord.hunterName, cardId: huntingRecord.cardId }]); //JIRA 289
    let divi = '-';
    if (huntingRecord.huntingDate && huntingRecord.huntingDate.indexOf('/') > 0) {
      divi = '/';
    }
    let huntingDate = getFormattedDate(huntingRecord.huntingDate, divi);
    if (huntingRecord.coordinates) {
      huntingRecord.location = true;
    }

    const examinationDate = getFormattedDateFromUTC(huntingRecord.examinationDate) || new Date();
    const dateOfGreenTemplate = getFormattedDateFromUTC(huntingRecord.dateOfGreenTemplate);
    let viewForm = {
      value: {
        ...huntingRecord,
        hunter,
        animalType,
        animalClass,
        huntingDate: toDateString(huntingDate),
        examinationDate: toDateString(examinationDate),
        dateOfGreenTemplate: dateOfGreenTemplate ? toDateString(dateOfGreenTemplate) : undefined,
      },
    };
    setFormData(viewForm);
    setLoading(false);
  }

  useEffect(() => {
    if (viewRecord) {
      fillFormData(viewRecord);
    } else if (storedFormData && storedFormData.value) {
      fillFormData(storedFormData.value);
    } else {
      resetForm();
    }
  }, [huntingRecord, storedFormData]);

  function pressBackButton(redirectFn) {
    if (!isViewMode)
      setAlert({
        open: true,
        ok: t('buttonText.yes'),
        cancel: t('buttonText.cancel'),
        title: t('huntRecord.alert'),
        text: t('huntRecord.dataLostWarning'),
        onOkClick: redirectFn,
      });
    else redirectFn && redirectFn();
  }

  function onDeleteHuntRecord() {
    setActionType('delete');
    setAlert({
      open: true,
      ok: t('huntRecord.yes'),
      cancel: t('huntRecord.cancel'),
      title: t('huntRecord.delete'),
      text: t('huntRecord.deleteMessage'),
    });
  }

  const [isEditMode, setEditMode] = useState(false);
  function onEditHuntRecord() {
    setEditMode(true);
    setIsViewMode(false);
  }

  useEffect(() => {
    if (storedFormData && storedFormData.value && !!storedFormData.value.id) {
      setIsViewMode(false);
    } else if (match.params.id) {
      setIsViewMode(true);
      setEditMode(false);
    }
  }, [storedFormData, match]);

  return (
    <AppPage
      headerTitle={
        isViewMode || isEditMode
          ? isEditMode
            ? t('huntRecord.editHuntRecord')
            : t('huntRecord.viewHuntRecord')
          : t('huntRecord.addHuntRecord')
      }
      headerSubTitle={areaName}
      headerLeft={
        <IconButton color='inherit' aria-label='back' onClick={() => pressBackButton(onCancelHuntRecord)} edge='start'>
          <ArrowBackIcon />
        </IconButton>
      }
      loading={loading || !huntingArea || !hunters}
      beforeRedirect={pressBackButton}
    >
      {loading || !huntingArea || !hunters ? (
        <div className='pls-wt'>Please wait ...</div>
      ) : (
        <Box p={1} pt={2}>
          <Grid container direction='row' justify='center' alignItems='center' id='record-form-grid-id'>
            <Grid spacing={1} container style={{ maxWidth: '600px', paddingBottom: '60px' }}>
              {isViewMode && viewRecord && isNaN(viewRecord.id) && (
                <Grid xs={12} style={{ margin: '6px 2px' }} item>
                  <label className='note'>{t('huntRecord.offlineNote')}</label>
                </Grid>
              )}

              {viewRecord == null || myId === viewRecord.userId ? (
                <ImageGallery
                  isViewMode={isViewMode}
                  huntingImages={formData.value.pictures || []}
                  setHuntingImages={setPictures}
                  setAlert={setAlert}
                />
              ) : (
                <ImageGallery
                  isViewMode={isViewMode}
                  huntingImages={formData.value.pictures || []}
                  setHuntingImages={setPictures}
                  setAlert={setAlert}
                />
              )}
              <Grid
                item
                xs={9}
                className={
                  classes.formControl +
                  ' ' +
                  (formData && formData.value && formData.value.hunter
                    ? classes.hunterControlSelected
                    : classes.hunterControl)
                }
              >
                <FormControl style={{ marginTop: '3px' }} variant='outlined' fullWidth>
                  <InputLabel ref={inputLabel4} htmlFor='outlined-hunter-native-simple'></InputLabel>
                  <Select
                    native
                    disabled={isViewMode}
                    value={formData.value.hunter ? formData.value.hunter.cardId : '*****'}
                    onChange={(event, newValue) => {
                      newValue = event.target.value;
                      if (newValue) {
                        onHunterChange({ name: 'hunter', value: newValue });
                      }
                    }}
                    labelWidth={labelWidth4}
                    inputProps={{
                      name: 'hunter',
                      id: 'outlined-hunter-native-simple',
                    }}
                  >
                    <option key='none' value={null}></option>
                    {hunters
                      .filter((o) => o.hunterName && o.cardId)
                      .map((option, i) => (
                        <option key={'cardid' + i + option.cardId} value={option.cardId}>
                          {option.hunterName + '/' + option.cardId}
                        </option>
                      ))}
                  </Select>
                  {formData.value.hunter && !isViewMode && (
                    <div
                      onClick={() => {
                        onEditClick(formData.value.cardId);
                      }}
                      className='hunter-card-edit-icon'
                    >
                      <EditIcon />
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid id='hunter-card-id' item xs={3} className={(classes.formControl, classes.addHunterControl)}>
                {!isViewMode && (
                  <div
                    onClick={() => {
                      setOpenHunterCard(true);
                    }}
                    className='hunter-card'
                  >
                    <PersonAdd />
                    <div>{t('huntRecord.hunter')}</div>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} className={(classes.formControl, classes.margin9)}>
                <FormControl style={{ marginTop: '3px' }} variant='outlined' fullWidth>
                  <InputLabel ref={inputLabel3} htmlFor='outlined-animalType-native-simple'>
                    {t('huntRecord.animalType')}
                  </InputLabel>
                  <Select
                    native
                    disabled={isViewMode}
                    value={formData.value.animalTypeId || ''}
                    onChange={(event, newValue) => {
                      newValue = event.target.value;
                      if (newValue) onAnimalTypeChange(parseInt(newValue), undefined);
                    }}
                    labelWidth={labelWidth3}
                    inputProps={{
                      name: 'animalType',
                      id: 'outlined-animalType-native-simple',
                    }}
                  >
                    <option key='animalTypeid'></option>
                    {animalTypes &&
                      animalTypes.map((data, i) => (
                        <option key={'animalTypeid' + i + data.id} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.formControl}>
                <FormControl style={{ marginTop: '8px' }} variant='outlined' fullWidth>
                  <InputLabel ref={inputLabel2} htmlFor='outlined-class-native-simple'>
                    {t('huntRecord.animalClass')}
                  </InputLabel>
                  <Select
                    native
                    disabled={isViewMode | !animalType}
                    value={formData.value.animalClassId || ''}
                    onChange={(event, newValue) => {
                      newValue = event.target.value;
                      if (newValue) onAnimalTypeChange(animalType.id, parseInt(newValue));
                    }}
                    labelWidth={labelWidth2}
                    inputProps={{
                      name: 'animalClass',
                      id: 'outlined-class-native-simple',
                    }}
                  >
                    <option value=''></option>
                    {animalClasses &&
                      animalClasses.map((data, i) => (
                        <option key={'animalclassid' + i + data.id} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.formControl}>
                <FormControl style={{ marginTop: '8px' }} variant='outlined' fullWidth>
                  <TextField
                    disabled={isViewMode}
                    onChange={(event, newValue) => {
                      newValue = event.target.valueAsNumber;

                      onChange({ name: 'animalCount', value: newValue });
                    }}
                    onBlur={(e) => {
                      let newValue = e.target.valueAsNumber;

                      if (animalClass && !animalClass.collectiveNotification && newValue > 1) {
                        setAlert({
                          open: true,
                          cancel: t('buttonText.close'),
                          title: t('huntRecord.invalidEntry'),
                          text: t('huntRecord.maxCountValidationMsg'),
                        });

                        newValue = 1;
                      } else if (newValue < 1) {
                        newValue = 1;
                      } else if (newValue > 99) {
                        newValue = 99;
                      }

                      onChange({ name: 'animalCount', value: newValue });
                    }}
                    value={formData.value.animalCount}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    label={t('huntRecord.animalCount')}
                    variant='outlined'
                    fullWidth
                    type='number'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.formControl}>
                <FormControl fullWidth>
                  <TextField
                    disabled={isViewMode || !animalClass}
                    onChange={(event, newValue) => {
                      newValue = event.target.value;
                      if (newValue) {
                        newValue = parseInt(newValue);
                      }
                      onChange({ name: 'animalAge', value: newValue });
                    }}
                    onBlur={onAnimalChange}
                    value={formData.value.animalAge ? formData.value.animalAge : ''}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    id='animalAgeId'
                    label={t('huntRecord.animalAge')}
                    variant='outlined'
                    fullWidth
                    type='number'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.formControl}>
                <FormControl fullWidth>
                  <TextField
                    required
                    disabled={isViewMode}
                    onChange={(event, newValue) => {
                      newValue = event.target.value;
                      if (newValue) {
                        newValue = parseInt(newValue);
                      }
                      onChange({ name: 'animalWeight', value: newValue });
                    }}
                    value={formData.value.animalWeight ? formData.value.animalWeight : ''}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    id='animalAgeId'
                    label={t('huntRecord.animalWeight')}
                    variant='outlined'
                    fullWidth
                    type='number'
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} className={classes.formControl}>
              <FormControl fullWidth style = {{'padding-top': '3px'}}>
                <TextField
                  disabled
                  value={t("huntRecord.animalFallen")}
                  label={t("huntRecord.diedNatural")}
                  variant='outlined'
                  fullWidth
                  type='text'
                  required
                />
                <div className="hunt-record-checkbox" style = {{'padding-top': '3px'}}>
                  <Checkbox
                    key={1}
                    disabled={isViewMode}
                    checked={formData.value.animalFallen}
                    onChange={handleCheckedChange('animalFallen')}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    value={formData.value.animalFallen}
                    name="radio-button-demo"
                    id="diedNaturalId"
                  />
                </div>
              </FormControl>
            </Grid> */}
              <Grid item xs={12} className={classes.formControl + ' ' + classes.margin9}>
                <FormControl fullWidth>
                  <KeyboardDatePicker
                    fullWidth
                    disabled={isViewMode}
                    inputVariant='outlined'
                    margin='normal'
                    id='date-picker-dialog'
                    label={t('huntRecord.huntDate')}
                    helperText={!selectedHuntingPlan ? t('huntRecord.noHuntingPlan') : ''}
                    FormHelperTextProps={{ style: { color: 'red' } }}
                    format='dd/MM/yyyy'
                    locale='de-DE'
                    okLabel={t('buttonText.ok')}
                    cancelLabel={t('buttonText.cancel')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    onChange={(value) => onDateChange({ name: 'huntingDate', value })}
                    value={formData.value.huntingDate ? fromDateString(formData.value.huntingDate) : new Date()}
                  />
                </FormControl>
              </Grid>
              {county &&
                countyConfig.countyList.map((item, index) => {
                  if (item.countyName.toLocaleLowerCase() == county.toLocaleLowerCase()) {
                    return item.fields.map((field, index) => (
                      <Grid item xs={12} className={classes.formControl}>
                        <FormControl
                          style={field.type == 'dropdown' ? { marginTop: '3px' } : {}}
                          variant={field.type == 'dropdown' ? 'outlined' : ''}
                          fullWidth
                        >
                          {FieldItem(field)}
                        </FormControl>
                      </Grid>
                    ));
                  }
                })}

              <Grid item xs={12} className={classes.formControl}>
                <FormControl fullWidth>
                  <TextField
                    disabled={isViewMode}
                    onChange={(event, newValue) => {
                      newValue = event.target.value;
                      onChange({ name: 'annotation', value: newValue });
                    }}
                    value={formData.value.annotation}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      if (e.keyCode === 13) {
                        // Cancel the default action, if needed
                        e.preventDefault();
                        // Trigger the button element with a click
                        let item = document.getElementById('locationId');
                        if (item) document.getElementById('locationId').focus();
                      }
                    }}
                    id='annotationId'
                    label={t('huntRecord.animalFallenComment')}
                    variant='outlined'
                    fullWidth
                    type='text'
                    multiline
                    rows='3'
                  />
                </FormControl>
                {/*//=======================linkwithcordinate========================  */}
                {viewRecord && viewRecord.coordinates ? (
                  <div
                    id='maprecordcont'
                    className='maprecordcont'
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: '1px solid rgb(128, 128, 128, .5)',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      textAlign: 'justify',
                    }}
                  >
                    <button
                      onClick={gotoMap}
                      style={{
                        background: 'transparent',
                        border: 'none',
                        textAlign: 'center',
                        textDecoration: 'none',
                        display: 'inline-block',
                      }}
                    >
                      <p
                        color='rgb(128, 128, 128, .5)'
                        style={{
                          marginBottom: '3px',
                          marginTop: '3px',
                          color: 'rgb(128, 128, 128, .8)',
                          fontSize: '15px',
                        }}
                        className='maplink'
                      >
                        <PublicIcon />
                        In Karte anzeigen
                      </p>
                    </button>
                  </div>
                ) : null}
              </Grid>
              {isEditMode && (
                <Grid item xs={12} className={classes.formControl}>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      value={t('huntRecord.changeLocation')}
                      variant='outlined'
                      fullWidth
                      type='text'
                      required
                    />
                    <div className='hunt-record-checkbox'>
                      <Checkbox
                        disabled={isViewMode}
                        key='notchangelocationId'
                        checked={formData.value.changeLocation || false}
                        onChange={handleChangeLocationChange}
                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                        value={formData.value.changeLocation}
                        name='radio-button-location'
                        id='notchangelocationId'
                      />
                    </div>
                  </FormControl>
                </Grid>
              )}
              {(!isEditMode || formData.value.changeLocation) && (
                <Grid item xs={12} className={classes.formControl}>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      value={t('huntRecord.location')}
                      variant='outlined'
                      fullWidth
                      type='text'
                      required
                    />
                    <div className='hunt-record-checkbox'>
                      <Checkbox
                        disabled={isViewMode}
                        key='locationId'
                        checked={formData.value.location}
                        onChange={handleLocationChange('location')}
                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                        value={formData.value.location}
                        name='radio-button-location'
                        id='locationId'
                      />
                    </div>
                  </FormControl>
                </Grid>
              )}
              {(!isEditMode || formData.value.changeLocation) && (
                <Grid item xs={12} style={{ marginTop: '6px' }} className={classes.formControl}>
                  <FormControl fullWidth>
                    <Grid
                      component='label'
                      style={{ padding: '8px', justifyContent: 'center' }}
                      direction='row'
                      container
                      alignItems='end'
                      spacing={1}
                    >
                      <Grid item>
                        <FormControl fullWidth>{t('markerActions.chooseLocation')}</FormControl>{' '}
                      </Grid>
                      <Grid item justify='center'>
                        <FormControl fullWidth>
                          <AntSwitch
                            disabled={!formData.value.location || isViewMode}
                            checked={formData.value.myLocation}
                            onChange={handleLocationChange('myLocation')}
                            value={formData.value.myLocation}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        {' '}
                        <FormControl fullWidth>{t('markerActions.useMyLocation')}</FormControl>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              )}
              {(!isViewMode || isEditMode) && (
                <Grid container spacing={2} className={classes.actionButtons}>
                  <Grid item>
                    <Button
                      data-testid='submitDataBtn'
                      onClick={submitData}
                      size='large'
                      variant='contained'
                      color='primary'
                      disabled={!isFormValid() || loading}
                    >
                      {formData.value.location &&
                      !formData.value.myLocation &&
                      (!isEditMode || formData.value.changeLocation)
                        ? t('markerActions.chooseLocation')
                        : isEditMode
                        ? t('buttonText.update')
                        : t('buttonText.submit')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={onCancelHuntRecord} size='large' variant='outlined'>
                      {t('buttonText.cancel')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {canPerformActions()
                ? isViewMode && (
                    <Grid container spacing={2} className={classes.actionButtons}>
                      {updatePermission && (
                        <Grid item>
                          <Button onClick={onEditHuntRecord} size='large' variant='outlined'>
                            {t('buttonText.edit')}
                          </Button>
                        </Grid>
                      )}
                      {deletePermission && (
                        <Grid item>
                          <Button onClick={onDeleteHuntRecord} color='secondary' size='large' variant='outlined'>
                            {t('markerActions.delete')}
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button onClick={onCancelHuntRecord} size='large' variant='outlined'>
                          {t('buttonText.cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  )
                : isViewMode && (
                    <Grid container spacing={2} className={classes.actionButtons}>
                      {' '}
                      <Grid item>
                        <Button onClick={onCancelHuntRecord} size='large' variant='outlined'>
                          {t('buttonText.return')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
            </Grid>
          </Grid>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{snackBarMessage}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleSnackBarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <HunterCard
        open={openHunterCard}
        onHunterCardSave={onHunterCardSave}
        onHunterCardCancel={onHunterCardCancel}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        setEditCard={setEditCard}
        editCard={editCard}
        onHunterCardDelete={onHunterCardDelete}
      />
      {alert && (
        <AppDialog {...alert} handleClose={handleDialogClose} handleAlertOk={alert.onOkClick || handleAlertOk} />
      )}
    </AppPage>
  );

  /***********County Fields***************** */

  function FieldItem({ type, name, values }) {
    switch (type) {
      case 'dropdown':
        return (
          <>
            <InputLabel ref={inputLabel5} htmlFor='outlined-dy-native-simple'>
              {t(`countyData.${name}`)}
            </InputLabel>
            <Select
              native
              disabled={isViewMode}
              value={formData.value[name] == null || formData.value[name] == undefined ? '' : formData.value[name]}
              onChange={(event, newValue) => {
                newValue = event.target.value;
                //if (newValue)
                onChange({ name: name, value: newValue });
              }}
              labelWidth={labelWidth5}
              inputProps={{
                name: 'animal' + name,
                id: 'outlined-dy-native-simple' + name,
              }}
            >
              <option key={'dyid'}></option>
              {values.map((i) => (
                <option key={'dyid' + name + i} value={i}>
                  {t(`countyData.${i}`)}
                </option>
              ))}
            </Select>
          </>
        );

      case 'text':
        return (
          <TextField
            disabled={isViewMode}
            onChange={(event, newValue) => {
              newValue = event.target.value == null || event.target.value == undefined ? '' : event.target.value;
              onChange({ name, value: newValue });
            }}
            value={formData.value[name] == null || formData.value[name] == undefined ? '' : formData.value[name]}
            onKeyUp={(e) => {
              e.preventDefault();
              if (e.keyCode === 13) {
                // Cancel the default action, if needed
                e.preventDefault();
                // Trigger the button element with a click
                let item = document.getElementById('personComplaint');
                //if (item)
                //document.getElementById("personComplaint").focus();
              }
            }}
            id={`${name}Id`}
            label={t(`countyData.${name}`)}
            variant='outlined'
            fullWidth
            type='text'
          />
        );
      case 'boolean':
        return (
          <>
            <TextField
              disabled
              value={t(`countyData.${name}`)}
              //label={t("huntRecord.diedNatural")}
              variant='outlined'
              fullWidth
              type='text'
              required
            />
            <div className='hunt-record-checkbox'>
              <Checkbox
                disabled={isViewMode}
                key={1}
                checked={formData.value[name] ? formData.value[name] : false}
                onChange={handleCheckedChange(name)}
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                value={formData.value[name] ? formData.value[name] : false}
                name='radio-button-demo'
                id={`${name}Id`}
              />
            </div>
          </>
        );
      case 'date':
        return name == 'examinationDate' ? (
          examinationDateRange && examinationDateRange.length == 2 ? (
            <KeyboardDatePicker
              disabled={isViewMode}
              fullWidth
              inputVariant='outlined'
              margin='normal'
              id={`${name}Id`}
              label={t(`countyData.${name}`)}
              format='dd/MM/yyyy'
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              okLabel={t('buttonText.ok')}
              cancelLabel={t('buttonText.cancel')}
              onChange={(value) => onDateChange({ name, value })}
              value={formData.value[name] ? fromDateString(formData.value[name]) : new Date()}
              minDate={examinationDateRange.length ? examinationDateRange[0] : new Date()}
              maxDate={examinationDateRange.length ? examinationDateRange[1] : new Date()}
            />
          ) : (
            <KeyboardDatePicker
              disabled={isViewMode}
              fullWidth
              inputVariant='outlined'
              margin='normal'
              id={`${name}Id`}
              label={t(`countyData.${name}`)}
              format='dd/MM/yyyy'
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              okLabel={t('buttonText.ok')}
              cancelLabel={t('buttonText.cancel')}
              onChange={(value) => onDateChange({ name, value })}
              value={formData.value[name] ? fromDateString(formData.value[name]) : new Date()}
              maxDate={new Date()}
            />
          )
        ) : (
          <KeyboardDatePicker
            disabled={isViewMode}
            fullWidth
            inputVariant='outlined'
            margin='normal'
            id={`${name}Id`}
            label={t(`countyData.${name}`)}
            format='dd/MM/yyyy'
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            okLabel={t('buttonText.ok')}
            cancelLabel={t('buttonText.cancel')}
            onChange={(value) => onDateChange({ name, value })}
            value={formData.value[name] ? fromDateString(formData.value[name]) : new Date()}
            maxDate={new Date()}
          />
        );
      default:
        return null;
    }
  }
};

export default withRouter(HuntRecord);
