import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(2)}px)`
    },
    avatar: {
      width: 80,
      height: 80,
      fontSize: 32,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(5),
      background: theme.palette.primary.light
    },
    userName: {
      textTransform: 'uppercase'
    },
    translateBtn: {
      'align-self': 'center'
    },
    formControl: {
      margin: theme.spacing(1)
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    cardActionBox: {
      width: '100%'
    },
    actionButtons: {
      marginTop: theme.spacing(1)
    },
    noWrap: {
      'white-space': 'nowrap'
    },
    formError:{
      color: 'red'
    }
  })
);

export default useStyles;
