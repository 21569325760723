import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchHunters } from '../services/hunt-record/hunt-record';
import { fetchAreaMap } from './hunting-area';
import { executeOnlineOrOffline } from '../../../shared/state/offline-requests';

const initialState = { data: {} };

export const fetchAllHunters = createAsyncThunk(
    'hunter/fetchAll',
    async () => {
        return await fetchHunters().then(res => res.response);
    }
)

export const requestAddHunter = createAsyncThunk(
    'hunter/requestAdd',
    async (payload, thunkApi) => executeOnlineOrOffline('hunter.add', payload, thunkApi),
)

export const requestUpdateHunter = createAsyncThunk(
    'hunter/requestUpdate',
    async (payload, thunkApi) => executeOnlineOrOffline('hunter.update', payload, thunkApi),
)

export const requestRemoveHunter = createAsyncThunk(
    'hunter/requestRemove',
    async (payload, thunkApi) => executeOnlineOrOffline('hunter.remove', payload, thunkApi),
)

function updateHunterReducer(state, action) {
    if (!action.payload.cardId) return;
    state.data[action.payload.cardId] = action.payload;
}

function removeHunterReducer(state, action) {
    delete state.data[action.payload.cardId];
}

const hunterSlice = createSlice({
    name: 'hunter',
    initialState,
    reducers: {
        updateHunter: updateHunterReducer,
        removeHunter: removeHunterReducer,
    },
    extraReducers: {
        [fetchAllHunters.fulfilled]: (state, action) => {
            if (!action.payload) return;
            state.data = {};
            action.payload.forEach(h => {
                updateHunterReducer(state, { payload: h });
            });
        },
        [requestAddHunter.fulfilled]: (state, action) => {
            if (!action.payload) return;
            updateHunterReducer(state, action);
        },
        [requestUpdateHunter.fulfilled]: (state, action) => {
            if (!action.payload) return;
            if (action.payload.oldCardId !== action.payload.cardId) removeHunterReducer(state, { payload: { cardId: action.payload.oldCardId } });
            const payload = { ...action.payload };
            delete payload.oldCardId;
            updateHunterReducer(state, { payload });
        },
        [requestRemoveHunter.fulfilled]: (state, action) => {
            removeHunterReducer(state, action);
        },
    }
})

export const { updateHunter, removeHunter } = hunterSlice.actions

export default hunterSlice.reducer