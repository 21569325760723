import { fetchAllHunters } from 'app/hunting-area/shared/state/hunter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useHunters() {
    const hunters = Object.values(useSelector(({ huntingArea }) => huntingArea.hunters.data));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllHunters());
    }, []);
    return { hunters };
}