import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAreaMap } from './hunting-area';
import { executeOnlineOrOffline } from '../../../shared/state/offline-requests';
import { updateMarkerId } from './additionalActions';
import { fetchReservations as apiFetchReservations, makeReservation, cancelReservation, fetchReservableTypes as apiFetchReservableTypes, editReservation } from './../services/reservation';

const initialState = { data: {}, reservableTypes: [] };

export const fetchReservableTypes = createAsyncThunk(
    'marker/fetchReservableTypes',
    async () => await apiFetchReservableTypes(),
)

export const fetchReservations = createAsyncThunk(
    'marker/fetchReservationsForFeature',
    async (payload) => ({
        featureId: payload.featureId,
        reservations: await apiFetchReservations(payload.huntingAreaId, payload.featureId),
    }),
);

export const requestMakeReservation = createAsyncThunk(
    'marker/makeReservation',
    async payload => {
        const id = await makeReservation(payload);
        return { id, ...payload };
    }
)

export const requestEditReservation = createAsyncThunk(
    'marker/editReservation',
    async payload => {
        await editReservation(payload);
        return payload;
    }
)

export const requestCancelReservation = createAsyncThunk(
    'marker/cancelReservation',
    async payload => {
        await cancelReservation(payload.id);
        return payload;
    }
)

export const requestAddMarker = createAsyncThunk(
    'marker/requestAdd',
    async (payload, thunkApi) => executeOnlineOrOffline('marker.add', payload, thunkApi),
)

export const requestRemoveMarker = createAsyncThunk(
    'marker/requestRemove',
    async (payload, thunkApi) => executeOnlineOrOffline('marker.remove', payload, thunkApi),
)

function isReservable(state, item) {
    return state.reservableTypes && state.reservableTypes.some(r => r.type == item.type && r.subType == item.subType);
}

function updateMarkerReducer(state, action) {
    if (!action.payload.id) return;
    state.data[action.payload.id] = Object.assign(state.data[action.payload.id] || {}, { ...action.payload });
    state.data[action.payload.id].reservable = isReservable(state, state.data[action.payload.id]);
}

function removeMarkerReducer(state, action) {
    delete state.data[action.payload.id];
}

function updateNextReservation(state, id) {
    const beforeDay = new Date();
    beforeDay.setHours(0);
    beforeDay.setMinutes(0);
    beforeDay.setSeconds(0);
    beforeDay.setDate(beforeDay.getDate() + 2);
    const before = state.data[id].reservations.find(r => Date.parse(r.timeFrom) < beforeDay);
    state.data[id].nextReservation = before ? before.timeFrom : null;
}

const markerSlice = createSlice({
    name: 'marker',
    initialState,
    reducers: {
        updateMarker: updateMarkerReducer,
        removeMarker: removeMarkerReducer,
    },
    extraReducers: {
        [fetchReservations.fulfilled]: (state, action) => {
            if (!action.payload || !state.data[action.payload.featureId]) return;
            state.data[action.payload.featureId].reservations = action.payload.reservations;
        },
        [fetchReservableTypes.fulfilled]: (state, action) => {
            if (!action.payload) return;
            state.reservableTypes = action.payload;
            Object.keys(state.data).forEach(k => {
                state.data[k].reservable = isReservable(state, state.data[k]);
            });
        },
        [requestMakeReservation.fulfilled]: (state, action) => {
            if (!action.payload) return;
            state.data[action.payload.huntingAreaFeatureId].reservations.push(action.payload);
            updateNextReservation(state, action.payload.huntingAreaFeatureId);

        },
        [requestEditReservation.fulfilled]: (state, action) => {
            if (!action.payload) return;
            const item = state.data[action.payload.huntingAreaFeatureId].reservations.find(r => r.id === action.payload.id);
            if (item) {
                item.timeFrom = action.payload.timeFrom;
                item.timeTo = action.payload.timeTo;
            }
            updateNextReservation(state, action.payload.huntingAreaFeatureId);
        },
        [requestCancelReservation.fulfilled]: (state, action) => {
            if (!action.payload) return;
            state.data[action.payload.featureId].reservations = state.data[action.payload.featureId].reservations.filter(r => r.id !== action.payload.id);
            updateNextReservation(state, action.payload.featureId);
        },
        [fetchAreaMap.fulfilled]: (state, action) => {
            if (!action.payload) return;
            action.payload.features.forEach(r => {
                updateMarkerReducer(state, { payload: r })
            });
        },
        [requestAddMarker.fulfilled]: (state, action) => {
            const payload = { ...action.payload };
            payload.geometry = JSON.parse(payload.geometry);
            delete payload.reservations;
            updateMarkerReducer(state, { payload });
        },
        [requestRemoveMarker.fulfilled]: (state, action) => {
            removeMarkerReducer(state, action);
        },
        [updateMarkerId]: (state, { payload: { oldId, newId } }) => {
            state.data[newId] = { ...state.data[oldId], id: newId };
            delete state.data[oldId];
        }
    }
})

export const { updateMarker, removeMarker } = markerSlice.actions

export default markerSlice.reducer