import React, { useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublicIcon from '@material-ui/icons/Public';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabled from '@material-ui/icons/SyncDisabled';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { baseBlobUrl } from 'environement/environment';
import { withRouter } from 'react-router-dom';
import useTreeItemStyles from './hunt-statistics-style';
import useHuntingArea from 'app/shared/hooks/useHuntingArea';

const HuntStatistics = (props) => {
  const {
    treeItems,
    expandNodes,
    history,
    navigateToHuntRecordForm,
    navigateToHuntRecordOnMap,
    canEdit,
    canModifyAll,
  } = props;

  let isHunterView = localStorage.getItem('isHunterView');
  if (isHunterView) {
    isHunterView = JSON.parse(isHunterView);
  }

  var rMapId = useRef(window.location.href.split('/')[4]);

  const { huntingArea } = useHuntingArea(rMapId.current);
  const isOOE = huntingArea.county === 'OÖ';

  function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {
      labelText,
      type,
      labelIcon,
      animalFallenCountForType,
      count,
      data,
      record,
      color,
      bgColor,
      actual,
      target,
      isOOE,
      ...other
    } = props;

    let actualAnimalCountForCurrentRow = actual;
    const iconPicUrl = baseBlobUrl + '$web/animal-images/';

    if (type === 'animalType') {
      actualAnimalCountForCurrentRow = isOOE ? Math.max(actual - animalFallenCountForType, 0) : actual;
    }

    if (type === 'animalClass' && Array.isArray(data) && data.length > 0) {
      actualAnimalCountForCurrentRow = isOOE
        ? Math.max(
            actual -
              data.reduce(
                (sum, entry) =>
                  ['mähverlust', 'roadRail', 'miscellaneous'].includes(entry.animalFallen) ? sum + entry.count : sum,
                0
              ),
            0
          )
        : actual;
    }

    const getIconUpperCase = (icon) => {
      if (icon && icon.length > 1) {
        icon = icon.charAt(0).toUpperCase() + icon.slice(1);
        let whiteIcon = icon.split('.');
        return whiteIcon[0] + '-white.' + whiteIcon[1];
      }
      return icon;
    };

    return actual > 0 || target > 0 || record ? (
      <TreeItem
        expandIcon={
          !record &&
          !labelIcon && (
            <ArrowDropDownIcon
              className='open-close'
              style={{ position: 'absolute', marginRight: '3px', fill: '#006633', marginTop: '6px' }}
            />
          )
        }
        collapseIcon={
          !record &&
          !labelIcon && (
            <ArrowDropUpIcon
              className='open-close'
              style={{ position: 'absolute', marginRight: '3px', fill: '#006633', marginTop: '6px' }}
            />
          )
        }
        label={
          <div className={`${classes.labelRoot} ${labelIcon ? classes.typeStyle : record ? '' : classes.classStyle}`}>
            {labelIcon && (
              <CardMedia className={classes.media} image={iconPicUrl + getIconUpperCase(labelIcon)} title={labelIcon} />
            )}
            {!record && (
              <>
                {' '}
                <Typography
                  variant='body2'
                  style={
                    labelIcon
                      ? { width: '46%', 'margin-left': '12px' }
                      : { padding: '10px 15px 10px', width: '46%', 'font-weight': 'bold' }
                  }
                  className={classes.labelText}
                >
                  {labelText}
                </Typography>
                <Typography variant='body2' style={labelIcon ? {} : { paddingLeft: '40px' }} color='inherit'>
                  {actualAnimalCountForCurrentRow}/{target}
                </Typography>
              </>
            )}

            {record && (
              <Grid item xs={12} className={classes.recordStyle}>
                <Grid container style={{ alignItems: 'center' }}>
                  <Typography
                    className='record-item-big'
                    variant='body2'
                    color='inherit'
                    style={{ 'padding-left': '15px' }}
                  >
                    {record.huntingDate}
                  </Typography>
                  <Typography className='record-item-big' variant='body2'>
                    {record.hunterName || '******'}
                  </Typography>
                  <Typography className='record-item pd-count' style={{ color: '#006633' }} variant='caption'>
                    {record.count}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '120px',
                      marginRight: '15px',
                      marginLeft: 'auto',
                      gap: '15px',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    {record.hasCoordinates && (
                      <button
                        onClick={() => {
                          navigateToHuntRecordOnMap(record.id);
                        }}
                        style={{
                          background: 'transparent',
                          border: 'none',
                          textAlign: 'center',
                          textDecoration: 'none',
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                      >
                        <p
                          color='rgb(128, 128, 128, .5)'
                          style={{
                            marginBottom: '3px',
                            marginTop: '3px',
                            color: 'rgb(128, 128, 128, .8)',
                            fontSize: '15px',
                          }}
                          className='maplink'
                        >
                          <PublicIcon />
                        </p>
                      </button>
                    )}

                    {canModifyAll && (
                      <Typography variant='caption' className='record-item'>
                        {record.isSynced ? <SyncIcon fontSize='small' /> : <SyncDisabled fontSize='small' />}
                      </Typography>
                    )}

                    <Typography
                      onClick={() => navigateToHuntRecordForm(record.id)}
                      className='record-item'
                      style={{}}
                      variant='caption'
                    >
                      {(canEdit && record.isOwnRecord && !record.isSynced) || canModifyAll ? (
                        <EditIcon color='inherit' fontSize='small' />
                      ) : (
                        <VisibilityIcon disabled color='inherit' fontSize='small' cursor={'pointer'} />
                      )}
                    </Typography>

                    {(isOOE
                      ? ['mähverlust', 'roadRail', 'miscellaneous'].includes(record.animalFallen)
                      : !!record.animalFallen) && (
                      <span
                        style={{
                          fontSize: '16px',
                          height: '100%',
                          display: 'block',
                          paddingRight: '2px',
                          paddingLeft: '2px',
                          paddingTop: '1px',
                          paddingBottom: '1px',
                          border: 'solid',
                          borderWidth: '1px',
                          borderRadius: '6px',
                          borderColor: 'rgb(128,128,128,0.4)',
                          background: 'rgb(230,230,230,0.4)',
                          pointerEvents: 'none',
                        }}
                      >
                        FW
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    ) : null;
  }

  const getTreeItemsFromData = (treeItems) => {
    return treeItems.map((treeItemData) => {
      let data = null;
      let animalFallenCountForType = 0;

      if (treeItemData.type === 'animalType' && treeItemData.data && treeItemData.data.length > 0) {
        treeItemData.data.forEach((animalClassEntry) => {
          if (animalClassEntry.data && animalClassEntry.data.length > 0) {
            animalClassEntry.data.forEach((huntingRecord) => {
              if (huntingRecord.animalFallen) {
                if (isOOE) {
                  if (['mähverlust', 'roadRail', 'miscellaneous'].includes(huntingRecord.animalFallen)) {
                    animalFallenCountForType += huntingRecord.count;
                  }
                } else {
                  animalFallenCountForType += huntingRecord.count;
                }
              }
            });
          }
        });
      }

      if (treeItemData.data && treeItemData.data.length > 0) {
        data = getTreeItemsFromData(treeItemData.data);
      }

      return (
        <StyledTreeItem
          key={treeItemData.id}
          type={treeItemData.type}
          nodeId={treeItemData.id}
          labelText={treeItemData.name}
          actual={treeItemData.count}
          target={treeItemData.shootingPlan}
          labelIcon={treeItemData.icon}
          record={treeItemData.type === 'huntingRecord' && treeItemData}
          data={treeItemData.data}
          children={data}
          animalFallenCountForType={treeItemData.type === 'animalType' && animalFallenCountForType}
          isOOE={isOOE}
        />
      );
    });
  };

  const DataTreeView = ({ treeItems }) => {
    return (
      <TreeView
        className='item-mobile-view pad-left-null mrgin-tp-10'
        style={{ 'padding-left': '12px', 'padding-top': '10px', 'margin-bottom': '50px' }}
        defaultCollapseIcon={
          <ExpandLessIcon style={{ position: 'absolute', right: '14px', fill: 'white', marginTop: '12px' }} />
        }
        defaultExpandIcon={
          <ExpandMoreIcon style={{ position: 'absolute', right: '14px', fill: 'white', marginTop: '12px' }} />
        }
        defaultExpanded={expandNodes.length ? expandNodes : undefined}
      >
        {getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };

  return <DataTreeView treeItems={treeItems} />;
};

export default withRouter(HuntStatistics);
