import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { executeOnlineOrOffline } from './offline-requests';

const initialState = null;

export const requestUpdateUserSettings = createAsyncThunk(
    'authDetails/requestUpdateUserSettings',
    async (payload, thunkApi) => executeOnlineOrOffline('authDetails.updateUserSettings', payload, thunkApi),
)

const authDetailsSlice = createSlice({
    name: 'authDetails',
    initialState,
    reducers: {
        receivedAuthDetailsAction: (state, action) => {
            return action.payload;
        },
        updateUserInfo: (state, action) => {
            state.userInfo = Object.assign(state.userInfo, action.payload);
        },
        updatePendingRequestCounter: (state, action) => {
            state.pendingRequestCount = action.payload;
        }
    },
    extraReducers: {
        [requestUpdateUserSettings.fulfilled]: (state, action) => {
            state.userInfo.userSettings = action.payload;
        },
    }
})

export const { receivedAuthDetailsAction, updateUserInfo, updatePendingRequestCounter } = authDetailsSlice.actions

export default authDetailsSlice.reducer