import { fromDateString } from 'app/shared/dateMethods';
import { httpClient } from 'app/shared/services';
import { API_CONSTANTS } from 'app/shared/config/api-constants';


const createHuntRecord = formData => {
  const requestData = { ...formData };
  requestData.huntingDate = fromDateString(formData.huntingDate);
  const url = API_CONSTANTS.huntRecord;
  return httpClient
    .post(url, requestData)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const saveHunters = (hunterName, cardId) => {

  const url = API_CONSTANTS.addHunters + '?huntername=' + hunterName + '&cardid=' + cardId;
  return httpClient
    .post(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};
const updateHunters = (hunterName, cardId, oldCardId) => {

  const url = API_CONSTANTS.updateHunters + '?huntername=' + hunterName + '&cardid=' + cardId + '&oldCardId=' + oldCardId;
  return httpClient
    .post(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};

const deleteHunters = (cardId) => {
  const url = API_CONSTANTS.deleteHunters + '?cardid=' + cardId;
  return httpClient
    .post(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};


const updateHuntRecord = formData => {
  const requestData = { ...formData };
  const url = API_CONSTANTS.huntRecord + requestData.id;
  requestData.huntingDate = fromDateString(requestData.huntingDate);
  return httpClient
    .put(url, requestData)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
};


const fetchHunters = () => {
  const url = API_CONSTANTS.getHunters;

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

const removeHuntReord = (id) => {
  const url = API_CONSTANTS.huntRecord + id;

  return httpClient
    .delete(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

const getHuntReord = (id, lang) => {
  const url = API_CONSTANTS.huntRecord + id;// + '?locale='+lang;

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

const getHuntReordPromise = (id, lang) => {
  const url = API_CONSTANTS.huntRecord + id;// + '?locale='+lang;

  return httpClient
    .get(url).then(res => res.data)
}

const getAnimalTypes = (planId, animalTypeId) => {
  /*  debugger; */
  animalTypeId = animalTypeId === undefined || animalTypeId === null ? 0 : animalTypeId;
  const url = API_CONSTANTS.getAnimalTypes + planId + '/' + animalTypeId;// + '?locale='+lang;

  return httpClient
    .get(url)
    .then(res => ({ response: res.data }))
    .catch(error => ({ error }));
}

export {
  createHuntRecord,
  saveHunters,
  updateHunters,
  deleteHunters,
  fetchHunters,
  removeHuntReord,
  updateHuntRecord,
  getHuntReord,
  getAnimalTypes,
  getHuntReordPromise
};