import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 48,
    height: 48
  }
}));

export default useStyles;
