import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAreaMap } from './hunting-area';
import { executeOnlineOrOffline } from '../../../shared/state/offline-requests';
import { updateRecordId } from './additionalActions';
import { getHuntReordPromise } from '../services/hunt-record/hunt-record';

const initialState = { data: {} };

const alreadyRemovedId = {};

export const fetchRecord = createAsyncThunk(
    'huntingRecord/fetch',
    async (payload) => getHuntReordPromise(payload.id),
)


export const requestAddRecord = createAsyncThunk(
    'huntingRecord/requestAdd',
    async (payload, thunkApi) => executeOnlineOrOffline('huntingRecord.add', payload, thunkApi),
)

export const requestUpdateRecord = createAsyncThunk(
    'huntingRecord/requestUpdate',
    async (payload, thunkApi) => executeOnlineOrOffline('huntingRecord.update', payload, thunkApi),
)

export const requestRemoveRecord = createAsyncThunk(
    'huntingRecord/requestRemove',
    async (payload, thunkApi) => executeOnlineOrOffline('huntingRecord.remove', payload, thunkApi),
)

function updateRecordReducer(state, action) {
    const payload = { ...action.payload };
    if (!payload.id || alreadyRemovedId[payload.id]) return;
    if (!payload.pictures && state.data[payload.id]) payload.pictures = state.data[payload.id].pictures
    state.data[payload.id] = payload;
}

function removeRecordReducer(state, action) {
    alreadyRemovedId[action.payload.id] = true;
    delete state.data[action.payload.id];
}

const huntingRecordSlice = createSlice({
    name: 'huntingRecord',
    initialState,
    reducers: {
        updateRecord: updateRecordReducer,
        removeRecord: removeRecordReducer
    },
    extraReducers: {
        [fetchAreaMap.fulfilled]: (state, action) => {
            if (!action.payload || !action.payload.records) return;
            const idMap = action.payload.records.reduce((acc, r) => {
                acc[r.id] = true;
                return acc;
            }, {});
            state.data = Object.keys(state.data).reduce((acc, k) => {
                if (state.data[k].huntingAreaId !== action.payload.id || idMap[k]) acc[k] = state.data[k];
                return acc;
            }, {});
            action.payload.records && action.payload.records.forEach(r => {
                const record = { ...r };
                delete record.pictures;
                updateRecordReducer(state, { payload: record });
            });
        },
        [fetchRecord.fulfilled]: (state, action) => {
            if (!action.payload) return;
            const payload = { ...action.payload };
            payload.pictures = payload.pictures || [];
            state.data[payload.id] = payload;
        },
        [requestAddRecord.fulfilled]: (state, action) => {
            if (!action.payload) return;
            updateRecordReducer(state, action);
        },
        [requestUpdateRecord.fulfilled]: (state, action) => {
            if (!action.payload) return;
            updateRecordReducer(state, action);
        },
        [requestRemoveRecord.fulfilled]: (state, action) => {
            if (!action.payload) return;
            removeRecordReducer(state, action);
        },
        [updateRecordId]: (state, { payload: { oldId, newId } }) => {
            state.data[newId] = { ...state.data[oldId], id: newId };
            delete state.data[oldId];
        }
    }
})

export const { updateRecord, removeRecord } = huntingRecordSlice.actions

export default huntingRecordSlice.reducer