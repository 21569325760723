import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';

export default function useQuery() {
    const history = useHistory();
    return [[...new URLSearchParams(useLocation().search)].reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {}), (query) => {
        for (const key in query) {
            if (query[key] === undefined) delete query[key];
        }
        history.replace("?" + new URLSearchParams(query).toString())
    }];
}

export function useQuerySingle(name) {
    const [query, setQuery] = useQuery();
    const [state, setState] = useState(query[name]);

    useEffect(() => {
        setState(query[name]);
    }, [query[name]])

    return [state, value => {
        setQuery({ ...query, [name]: value })
    }];
}

export function pickQueryOptions(query, options) {
    return options.reduce((acc, k) => {
        if (query[k]) acc[k] = query[k];
        return acc;
    }, {});
}

export function additionalQuery(query, questionMark) {
    let additionalQuery = new URLSearchParams(query).toString();
    if (additionalQuery.length > 0) additionalQuery = (questionMark ? '?' : '&') + additionalQuery;
    return additionalQuery;
}