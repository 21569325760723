import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Box, Grid } from '@material-ui/core';
import useStyles from './hunting-plan-style';
import SelectDropdown from 'app/shared/controls/select-dropdown'
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import useQuery from 'app/shared/hooks/useQuery';
import useHuntingArea from 'app/shared/hooks/useHuntingArea';
import { fromDateString, toDateString } from 'app/shared/dateMethods';
import { useHuntingPlanWithDate, useHuntingPlanWithYear, useAllAnimalTypes, useReselectAnimalTypeAndClass } from './../../shared/hooks/useHuntingPlan';

export const planFilterOptions = ['year', 'fromDate', 'toDate', 'animalTypeId', 'animalClassId', 'hunterName'];

const FilterPopup = props => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { isOpen, closeFilterPopup, huntingAreaId } = props

  const { huntingArea, huntingRecords } = useHuntingArea(huntingAreaId, false);
  const [query, setQuery] = useQuery();
  const [formData, setFormData] = useState({ ...query, fromDate: fromDateString(query.fromDate), toDate: fromDateString(query.toDate) });
  let { year, fromDate, toDate, animalTypeId, animalClassId, hunterName } = formData;

  const now = new Date();
  const yearList = huntingArea.huntingPlans.map(p => p.validFrom.split("/")[2]);
  const defaultHuntingPlan = useHuntingPlanWithDate(huntingArea, !year && now);
  const yearHuntingPlan = useHuntingPlanWithYear(huntingArea, year);
  const allAnimalTypes = useAllAnimalTypes(huntingArea);
  const selectedHuntingPlan = yearHuntingPlan || defaultHuntingPlan;
  year = parseInt(year) || (selectedHuntingPlan ? parseInt(selectedHuntingPlan.validFrom.split("/")[2]) : now.getFullYear());
  const huntingPlanDateRange = {
    validFrom: selectedHuntingPlan ? fromDateString(selectedHuntingPlan.validFrom) : `01/01/${year}`,
    validTo: selectedHuntingPlan ? fromDateString(selectedHuntingPlan.validTo) : `31/12/${year}`,
  };
  let filteredRecords = selectedHuntingPlan && huntingRecords.filter(r => parseInt(r.huntingDate.split('/')[2]) === year);

  // Reselect animal type and class based on SAP ID
  
  const reselectResult = useReselectAnimalTypeAndClass(allAnimalTypes, selectedHuntingPlan, animalTypeId, animalClassId);
  if (reselectResult) {
    const [animalTypeId, animalClassId] = reselectResult; 
    setFormData({ ...formData, animalTypeId, animalClassId });
  }
  
  const animalTypeOptions = filteredRecords && Object.values(filteredRecords.filter(r => !!selectedHuntingPlan.animalTypes.find(t => t.id === r.animalTypeId)).map(r => selectedHuntingPlan.animalTypes.find(t => t.id === r.animalTypeId)).reduce((acc, t) => {
    acc[t.id] = { name: t.name, id: t.id };
    return acc;
  }, {}));

  let animalClassOptions = [];
  if (animalTypeId && filteredRecords) {
    const animalType = selectedHuntingPlan && selectedHuntingPlan.animalTypes.find(t => t.id == animalTypeId);
    filteredRecords = filteredRecords.filter(r => r.animalTypeId == animalTypeId);
    animalClassOptions = Object.values(filteredRecords.map(r => animalType.animalClasses.find(c => c.id === r.animalClassId)).reduce((acc, c) => {
      acc[c.id] = { name: c.name, id: c.id };
      return acc;
    }, {}));
    if (animalClassId) {
      filteredRecords = filteredRecords.filter(r => r.animalClassId == animalClassId);
    }
  }
  const hunters = filteredRecords && [...new Set(filteredRecords.map(r => r.hunterName))].filter(h => !!h);
  fromDate = fromDate || huntingPlanDateRange.validFrom;
  toDate = toDate || huntingPlanDateRange.validTo;

  const onAnimalChange = ({ name, value }) => {
    setFormData(value != -1 ? { ...formData, animalTypeId: value, animalClassId: undefined, hunterName: undefined } : { ...query, animalTypeId: undefined, animalClassId: undefined, hunterName: undefined })
  }
  const onYearChange = ({ name, value }) => {
    setFormData({ ...formData, year: value, fromDate: undefined, toDate: undefined, hunterName: undefined })
  }
  const onAnimalClassChange = ({ name, value }) => {
    setFormData(value != -1 ? { ...formData, animalClassId: value, hunterName: undefined } : { ...query, animalClassId: undefined, hunterName: undefined })
  }
  const onHunterChange = ({ name, value }) => {
    setFormData(value != -1 ? { ...formData, hunterName: value } : { ...query, hunterName: undefined })
  }
  const onDateChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
  }
  const applyFilter = () => {
    closeFilterPopup();
    setQuery({
      ...formData,
      fromDate: (formData.fromDate || formData.toDate) ? toDateString(fromDate) : undefined,
      toDate: (formData.fromDate || formData.toDate) ? toDateString(toDate) : undefined,
    });
  }
  const cancel = () => {
    closeFilterPopup();
  }
  return (
    <Dialog open={isOpen} >
      <DialogContent style={{ padding: "0 0" }}>
        <Box p={2} pt={4} style={{ paddingTop: "0px", marginTop: "12px" }}>
          <Grid container>
            <Grid item xs={12} className={styles.formControl}>
              <Grid style={{ marginTop: "10px" }} >
                <SelectDropdown
                  label={t('huntingPlan.year')}
                  value={year}
                  handleChange={onYearChange}
                  fieldName='year'
                  options={yearList}
                  isEmptyValueRequired={false}
                />
              </Grid>
              <Grid style={{ marginTop: "10px" }} >
                <SelectDropdown
                  label={t('huntingPlan.animalType')}
                  value={animalTypeId}
                  handleChange={onAnimalChange}
                  fieldName='animalTypeId'
                  options={animalTypeOptions || -1}
                  isEmptyValueRequired={true} />
              </Grid>
              <Grid style={{ marginTop: "10px" }} >
                <SelectDropdown
                  label={t('huntingPlan.animalClass')}
                  value={animalClassId || - 1}
                  handleChange={onAnimalClassChange}
                  fieldName='animalClassId'
                  options={animalClassOptions}
                  isEmptyValueRequired={true} />
              </Grid>

              <Grid style={{ marginTop: "10px" }} >
                <SelectDropdown
                  label={t('huntingPlan.hunterName')}
                  value={hunterName || -1}
                  handleChange={onHunterChange}
                  options={hunters}
                  isEmptyValueRequired={true} />
              </Grid>
              {/* <FormControl style={{ marginTop: "6px" }} fullWidth> */}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant='outlined'
                    margin='normal'
                    id='date-picker-dialog'
                    label={t('textBoxLabel.from')}
                    format='dd/MM/yyyy'
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    okLabel={t("buttonText.ok")}
                    cancelLabel={t("buttonText.cancel")}
                    onChange={value => onDateChange({ name: 'fromDate', value })}
                    value={fromDate}
                    minDate={huntingPlanDateRange.fromDate}
                    maxDate={toDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant='outlined'
                    margin='normal'
                    id='date-picker-dialog2'
                    label={t('textBoxLabel.to')}
                    format='dd/MM/yyyy'
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    okLabel={t("buttonText.ok")}
                    cancelLabel={t("buttonText.cancel")}
                    onChange={value => onDateChange({ name: 'toDate', value })}
                    value={toDate}
                    minDate={fromDate}
                    maxDate={huntingPlanDateRange.toDate}
                  />
                </Grid>
              </Grid>
              {/* </FormControl> */}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>

        <Button data-testid="cancelbtn" onClick={cancel} size='small' variant='outlined'>
          {t('buttonText.cancel')}
        </Button>
        <Button
          onClick={applyFilter}
          size='small'
          variant='contained'
          color='primary'
          className={styles.noWrap}
        >
          {t('buttonText.apply')}
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default FilterPopup
